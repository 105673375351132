import { WASHMEN_CUSTOMER_API } from 'api/config';
import type { Auth } from 'api/types/auth.types';
import type { AxiosRequestConfig } from 'axios';
import type { LanguageCodes } from 'i18n/constants';

export async function loginAPI(
  tempCode: string,
  phone: string,
  email: string,
  thirdPartyId?: string,
  authTrackId?: string,
  customOriginApp?: string,
  language?: LanguageCodes
) {
  const { data } = await WASHMEN_CUSTOMER_API.post<Auth.LoginRootResponse>('/auth/login', {
    phone,
    tempCode,
    email,
    thirdPartyId,
    authTrackId,
    customOriginApp,
    language,
  });
  return data;
}

export async function logoutAPI(auth: string, refresh: string) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: '/auth/logout',
    headers: {
      auth,
      refresh,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return (await WASHMEN_CUSTOMER_API.request(config)).data;
}

export async function refreshTokenAPI() {
  return (await WASHMEN_CUSTOMER_API.post<Auth.LoginRootResponse>('/auth/refresh')).data;
}

export async function registerUserAPI(data: Auth.RegisterUserInputs) {
  return (await WASHMEN_CUSTOMER_API.post<Auth.LoginRootResponse>('/users', data)).data;
}

export async function verifyAuthTokenAPI(token: string) {
  return (
    await WASHMEN_CUSTOMER_API.post<Auth.LoginRootResponse>('/auth/third-party/verify', {
      token,
    })
  ).data;
}
