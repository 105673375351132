import { BackIcon } from 'assets/icons';
import * as React from 'react';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledBackButton = styled.button`
  background: transparent;
  border: unset;
  padding: 0 5px;
  margin-right: 10px;
`;

const StyledRow = styled(Row)`
  height: 45px;
`;

const StyledBackIcon = styled(BackIcon)`
  width: 20px;
  height: 20px;
  g > g > g > g > path {
    fill: ${({ theme }) => theme.components.headerBackButton.color};
  }
`;

const HeaderBackButton = () => {
  const navigate = useNavigate();

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <StyledRow>
      <Col className="my-6">
        <StyledBackButton onClick={onBack}>
          <StyledBackIcon />
        </StyledBackButton>
      </Col>
    </StyledRow>
  );
};

export default HeaderBackButton;
