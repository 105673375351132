import Button from 'react-bootstrap/Button';
import styled, { type DefaultTheme } from 'styled-components';

// Define a separate function for getting color from variant
const getColorFromVariant = (variant: string, theme: DefaultTheme) => {
  let color = '';

  switch (variant) {
    case 'primary':
      color = theme.components.button.primary.backgroundColor;
      break;
    case 'danger':
      color = theme.components.button.danger.backgroundColor;
      break;
    case 'warning':
      color = theme.components.button.warning.backgroundColor;
      break;
    default:
      color = theme.components.button.primary.backgroundColor;
      break;
  }

  return color;
};

const StyledButton = styled(Button)<{ variant: string; hasfixedheight: 'true' | 'false' }>`
  height: ${({ hasfixedheight }) => (hasfixedheight === 'true' ? '48px' : undefined)};
  background: ${({ theme, variant = 'primary', backgroundColor }) => backgroundColor ?? getColorFromVariant(variant, theme)};
  border-radius: ${({ theme }) => theme.components.button.border.radius}px;
  border-width: ${({ theme }) => theme.components.button.border.width}px;
  border-color: ${({ theme, variant = 'primary', backgroundColor }) =>
    backgroundColor || (variant === 'primary' ? theme.components.button.border.color : '')};
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme, textColor }) => textColor ?? theme.components.button.textColor};
  &:disabled,
  &:disabled:hover {
    background: ${({ theme }) => theme.components.button.disabled?.backgroundColor};
    border-color: ${({ theme }) => theme.components.button.disabled?.borderColor};
    color: ${({ theme }) => theme.components.button.disabled?.color};
    border-width: 0px;
  }
  &:hover,
  &:focus,
  &:active,
  &.btn-primary:not(:disabled):not(.disabled):active {
    background: ${({ theme, variant = 'primary', backgroundColor }) => backgroundColor ?? getColorFromVariant(variant, theme)};
    border-width: ${({ theme }) => theme.components.button.border.width}px;
    border-color: ${({ theme, variant = 'primary', backgroundColor }) =>
      backgroundColor || (variant === 'primary' ? theme.components.button.border.color : '')};
    color: ${({ theme, textColor }) => textColor ?? theme.components.button.textColor};
  }
`;

export default StyledButton;
