import type { AxiosError } from 'axios';
import Axios from 'axios';

export const WASHMEN_CUSTOMER_API = Axios.create({
  baseURL: process.env.REACT_APP_WM_CUSTOMER_ENDPOINT,
});

interface ServerError {
  infoCode?: {
    message: string;
    code: number;
  };
  message?: string;
}

export function isAxiosError(error: any): error is AxiosError<ServerError> {
  return (error as AxiosError).isAxiosError !== undefined;
}
