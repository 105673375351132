import { useQuery } from '@tanstack/react-query';
import { fetchGlobalConfig } from 'api/cms/global';

import { REACT_QUERY_KEYS } from '.';

export function useGlobalConfig() {
  return useQuery([REACT_QUERY_KEYS.globalKeys.FETCH_GLOBAL_CONFIG], fetchGlobalConfig, {
    select: (data) => data,
    refetchOnMount: false,
    refetchOnWindowFocus: true,
    meta: {
      config: true,
    },
  });
}
