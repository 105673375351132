import { useSessionStorageState } from 'ahooks';
import Avo from 'analytics/Avo';
import type { Customer } from 'api/types/user.types';
import { useConfig, useUpdateConfig } from 'context/app-config';
import { motion } from 'framer-motion';
import { useDynamicPageLoader } from 'hooks/useDynamicPageLoader';
import { useAuthentication } from 'pages/queries/auth.queries';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setAuth, verifyToken } from 'redux-stores/slices/authSlice';
import { setTargetTheme } from 'redux-stores/slices/uiSlice';
import type { AppDispatch } from 'redux-stores/store';
import { getBrowserLanguage } from 'utils/browserLanguage';
import { notifyErrorObject } from 'wrappers/reporting';

import { useQuery } from '../../hooks/useQuery';
import ExpiredToken from './expired-token';

interface Props {}

const VerifyPage: React.FC<Props> = () => {
  const { token } = useParams<{ token: string }>();
  const query = useQuery();

  const redirectURI: string | null = query.get('REDIRECT_URI');
  const code: string | null = query.get('code');
  const target: string | null = query.get('target');
  const page: string | null = query.get('page');
  const orderId: string | null = query.get('id');
  const keepPwaNavigation: string | null = query.get('keep_navigation');
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [status, setStatus] = React.useState<'idle' | 'loading' | 'error' | 'success'>('idle');
  const [redirectTo, setRedirectTo] = React.useState<string>();
  const config = useConfig();
  const updateConfig = useUpdateConfig();
  const { loaded: homePageLoaded } = useDynamicPageLoader('home');
  const [, setHideSplash] = useSessionStorageState('hideSplash', { defaultValue: false });
  const [authenticationRequested, setAuthenticationRequested] = useSessionStorageState('isAuthenticationRequested', { defaultValue: false });
  const authenticationRequestedCompleted = useRef<boolean>(false);

  const storageCleared = useRef<boolean>(false);

  /**
   * UPDATE LANGUAGE PREFERENCES BASED ON USER LANGUAGE UPON AUTHENTICATE
   * */
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  const updateUserLanguage = useCallback((user: Customer.Customer) => {
    // i18n.changeLanguage(user.language ?? 'en', (err, t) => {
    //   if (err) return console.log('something went wrong loading', err);
    // });
  }, []);

  const reportUserToAvo = useCallback((user: Customer.Customer) => {
    Avo.visit({
      userId_: user.id,
      email: user.email,
      phone: user.phone,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  }, []);

  const clearStorage = () => {
    if (!storageCleared.current) {
      storageCleared.current = true;
      localStorage.clear();
    }
  };

  const { mutate: authenticate, status: authenticateStatus } = useAuthentication({
    onSuccess: (data: Customer.LoginRootResponse & { redirectRoute: string }) => {
      setStatus('success');
      dispatch(setAuth(data));
      updateUserLanguage(data.user);
      reportUserToAvo(data.user);
      setRedirectTo(data.redirectRoute ?? '/home');
    },
    onError: notifyErrorObject,
  });

  useEffect(() => {
    setStatus(authenticateStatus);
  }, [authenticateStatus]);

  useEffect(() => {
    if (status === 'idle' || status === 'loading') {
      return;
    }

    if (!homePageLoaded) {
      return;
    }

    // hide splash
    const splash = document.getElementById('splash-screen');
    if (splash) {
      splash.style.display = 'none';
      setHideSplash(true);
    }

    if (redirectTo) {
      navigate(redirectTo, {
        state: {
          disableAnimation: true,
        },
        replace: true,
      });
    }
  }, [homePageLoaded, redirectTo, setHideSplash, status]);

  const toggleBackButtonVisibility = React.useCallback(() => {
    if (!config || !Object.keys(config).length) {
      return;
    }
    const isHideBackButton = keepPwaNavigation !== 'true';
    if (isHideBackButton !== config?.hideBackButton) {
      updateConfig?.({ ...config, hideBackButton: isHideBackButton });
    }
  }, [keepPwaNavigation, config, updateConfig]);

  useEffect(() => {
    toggleBackButtonVisibility();
  }, [toggleBackButtonVisibility]);

  useEffect(() => {
    if (!token) {
      return;
    }

    async function verifyAuthTokenAction() {
      if (!authenticationRequestedCompleted.current) {
        authenticationRequestedCompleted.current = true;
        setStatus('loading');
        clearStorage();
        try {
          const resultAction = await dispatch(verifyToken({ token: token!! }));
          if (verifyToken.fulfilled.match(resultAction)) {
            setStatus('success');
            setRedirectTo(redirectURI ?? '/home');
            updateUserLanguage(resultAction.payload.user);
            reportUserToAvo(resultAction.payload.user);
          }
        } catch (error: any) {
          if (error.name === 'AbortError') {
            notifyErrorObject(error);
          } else {
            setStatus('error');
          }
        }
      }
    }

    verifyAuthTokenAction();
  }, [dispatch, token, redirectURI, navigate, updateUserLanguage, reportUserToAvo]);

  // reset the authentication request flag when the component unmounts
  useEffect(
    () => () => {
      setAuthenticationRequested(false);
    },
    []
  );

  useEffect(() => {
    async function verifyAuthTokenAction() {
      if (!config?.api) {
        return;
      }
      const accessToken = window.location.hash?.replace('#access_token=', '').split('&')[0];
      const language = getBrowserLanguage();
      const pageName = page !== null ? page : undefined;
      const currentOrderId = orderId !== null ? orderId : undefined;

      if (!authenticationRequested) {
        clearStorage();
        authenticate({
          code: code ?? '',
          language,
          accessToken: accessToken ?? '',
          page: pageName,
          orderId: currentOrderId,
        });
        setAuthenticationRequested(true);
      }
    }

    verifyAuthTokenAction();
  }, [dispatch, code, config?.api, page, orderId, authenticate]);

  useEffect(() => {
    if (target) {
      dispatch(setTargetTheme(target));
    }
  }, [dispatch, query, target]);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { duration: 2 } }}>
      {status === 'error' && <ExpiredToken />}
    </motion.div>
  );
};

export default VerifyPage;
