import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPartner } from 'redux-stores/slices/authSlice';
import { selectTargetTheme } from 'redux-stores/slices/uiSlice';
import type { DefaultTheme } from 'styled-components';

import defaultTheme from './app/app.theme';

export function useTheme() {
  const targetTheme = useSelector(selectTargetTheme);
  const partnerId = process.env.REACT_APP_PARTNER_ID ?? useSelector(selectPartner);
  const [theme, setTheme] = useState<DefaultTheme>(defaultTheme);

  useEffect(() => {
    const fetchThemeFile = async () => {
      try {
        const currentTheme = targetTheme || partnerId?.toLowerCase() || 'app';
        const file = (await import(`./${currentTheme}/${currentTheme}.theme`)).default;
        setTheme({ ...defaultTheme, ...file });
      } catch (e) {
        setTheme(defaultTheme);
      }
    };
    fetchThemeFile();
  }, [partnerId, targetTheme]);

  return theme;
}
