import cx from 'classnames';
import { useConfig } from 'context/app-config';
import { motion } from 'framer-motion';
import React from 'react';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';

import styles from './styles.module.scss';

export interface FooterProps {
  className?: string;
  showBackgroundColor?: boolean;
  isVisible?: boolean;
  children?: React.ReactNode;
}

const StyledFooter = styled(motion.footer)<{ showbackgroundcolor: 'true' | 'false' }>`
  background-color: ${({ showbackgroundcolor = false, theme }) =>
    showbackgroundcolor === 'true' ? theme.components.footer.background : theme.colors.transparent} !important;
  box-shadow: ${({ theme }) => theme.components.footer.boxShadow};
  padding-bottom: ${({ theme }) => theme.components.footer.paddingBottom ?? '12'}px;
  ${({ theme }) =>
    theme.components.footer.borderRadius &&
    `
    border-top-left-radius: ${theme.components.footer.borderRadius.topLeft}px;
    border-top-right-radius: ${theme.components.footer.borderRadius.topRight}px;
  `}
  padding-top: ${({ theme }) => theme.components.footer.paddingTop ?? '12'}px;
`;

const variants = {
  visible: { y: 0 },
  hidden: { y: 150 },
};

const Footer: React.FC<FooterProps> = ({ className, showBackgroundColor, children, isVisible = true }) => {
  const config = useConfig();

  return (
    <StyledFooter
      showbackgroundcolor={`${Boolean(showBackgroundColor !== undefined ? showBackgroundColor : config?.showFooterBackgroundColor)}`}
      className={cx('bg-white fixed-bottom d-flex align-items-center justify-content-center', styles.footer, className)}
      initial={!isVisible ? 'hidden' : 'visible'}
      animate={!isVisible ? 'hidden' : 'visible'}
      variants={variants}
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
    >
      <Container>{children}</Container>
    </StyledFooter>
  );
};

export default Footer;
