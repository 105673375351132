import * as Inspector from 'avo-inspector';
import { INTEGRATION_PARTNERS } from 'config/types';
import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';

import Avo from '../../analytics/Avo';
import { sendEventToFacebook } from './facebook-pixel';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ?? '',
};

export const initialize = () => {
  let subdomain = window.location.host.split('.')[0];
  if (['localhost:3000', 'app', 'dev-app'].includes(subdomain)) {
    subdomain = 'APP';
  } else {
    subdomain = subdomain.toUpperCase().replace(/DEV-/g, '');
  }

  if (subdomain === INTEGRATION_PARTNERS.APP) {
    // initializing Google Tag Manager
    TagManager.initialize(tagManagerArgs);
  }

  const inspector = new Inspector.AvoInspector({
    apiKey: Avo.avoInspectorApiKey || '',
    env: process.env.NODE_ENV === 'development' ? Inspector.AvoInspectorEnv.Dev : Inspector.AvoInspectorEnv.Prod,
    version: '1.0.0',
    appName: 'PWA',
  });

  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '');
  const customDestinationInterface = {
    logEvent(eventName: string, eventProperties: {}) {
      mixpanel.track(eventName, eventProperties);
      if (subdomain === INTEGRATION_PARTNERS.APP) {
        TagManager.dataLayer({
          dataLayer: {
            event: eventName,
            ...eventProperties,
          },
        });
        sendEventToFacebook(eventName, eventProperties);
      }
    },
    setUserProperties: (userId: string, userProperties: any) => {
      mixpanel.people.set({
        id: userId,
        Email: userProperties.email,
        Name: `${userProperties.first_name} ${userProperties.last_name}`,
        FirstName: userProperties.first_name,
        LastName: userProperties.last_name,
        Phone: userProperties.phone,
        userId,
        originApp: subdomain,
      });
      if (subdomain === INTEGRATION_PARTNERS.APP) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'setUserProperties',
            userId,
            ...userProperties,
          },
        });
      }
    },
    identify(userId: string) {
      mixpanel.identify(userId);
      if (subdomain === INTEGRATION_PARTNERS.APP) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'setUserId',
            userId,
          },
        });
      }
    },
    unidentify() {
      mixpanel.reset();
    },
  };

  Avo.initAvo(
    { env: Avo.AvoEnv.Dev, inspector, webDebugger: false },
    { platform: 'Browser', appVersion: '0.1.0', device: subdomain, origin: subdomain },
    {},
    customDestinationInterface
  );
};
