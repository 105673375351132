import { notifyErrorObject } from 'wrappers/reporting';

declare const Careem: any;

export const trackCareemEvent = (eventSerializer: string, eventDescription: string) => {
  if (typeof Careem !== 'undefined') {
    if (typeof Careem.analyticsModule !== 'undefined') {
      Careem.analyticsModule
        .trackEvent(eventSerializer)
        .then(() => {})
        .catch((error: any) => {
          notifyErrorObject({ error, message: `Failed to track ${eventDescription}` });
        });
    }
  }
};
