import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPartner } from 'redux-stores/slices/authSlice';
import { selectTargetTheme } from 'redux-stores/slices/uiSlice';

export function useDynamicAnimations() {
  const targetTheme = useSelector(selectTargetTheme);
  const partnerId = process.env.REACT_APP_PARTNER_ID ?? useSelector(selectPartner);

  const [animationFiles, setAnimationFiles] = useState({
    createOrderAnimation: import('./app/creating-order-loader.json'),
    spinnerAnimation: import('./app/spinner-loader.json'),
  });

  useEffect(() => {
    const fetchAnimations = async () => {
      const defaultPath = 'app';

      let createOrderAnimation;
      let spinnerAnimation;

      const animations = targetTheme || partnerId?.toLowerCase() || defaultPath;

      try {
        createOrderAnimation = await import(`./${animations}/creating-order-loader.json`);
        spinnerAnimation = await import(`./${animations}/spinner-loader.json`);
      } catch (error) {
        createOrderAnimation = await import(`./${defaultPath}/creating-order-loader.json`);
        spinnerAnimation = await import(`./${defaultPath}/spinner-loader.json`);
      } finally {
        setAnimationFiles({ createOrderAnimation, spinnerAnimation });
      }
    };
    fetchAnimations();
  }, [partnerId, targetTheme]);

  return animationFiles;
}
