import Avo from 'analytics/Avo';
import { ServiceLine } from 'api/types/service.types';
import { EmailIcon, PhoneIconWashmen, SupportIcon } from 'assets/icons/app';
import { ReactComponent as CPIcon } from 'assets/images/service-selection/cp-home-new-icon.svg';
import { ReactComponent as SCIcon } from 'assets/images/service-selection/sc-home-new-icon.svg';
import Button from 'components/atoms/Button';
import useMessaging from 'hooks/useMessaging';
import React, { memo, useMemo, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

interface Props {
  serviceLine: ServiceLine;
  onCloseSheet?: () => any;
}

const StyledCard = styled.div`
  justify-content: center;
  min-width: 100%;
  margin-bottom: 0.988rem;
  object-fit: contain;
  border-radius: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghostWhite};
  padding: 5px;
`;

const StyledRow = styled.div`
  display: flex;
  gap: 13px;
`;
const LogoContainer = styled.div`
  width: 40px;
  height: 40px;
`;
const StyledTitle = styled.h2`
  color: ${({ theme }) => theme.colors.deepPurple};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin: 0px;
  margin-bottom: 2px;
`;

const StyledDescription = styled.h2`
  color: ${({ theme }) => theme.colors.darkStaleGray};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px; /* 150% */
  letter-spacing: 0.1px;
  margin: 0px;
`;

const StyledIconContainer = styled.div`
  svg {
    width: 41px;
    height: 41px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 1000px;
  gap: 8px;
  display: flex;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.ghostWhite};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.deepPurple};
  font-weight: 500;
  flex: 1;
  font-size: 12px;
  height: unset;

  &:hover,
  &:focus,
  &:active,
  &.btn-primary:not(:disabled):not(.disabled):active {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.deepPurple};
    border: 1px solid ${({ theme }) => theme.colors.ghostWhite};
  }
`;

const StyledPhoneIconWashmen = styled(PhoneIconWashmen)`
  width: 18.355px;
  height: 18.153px;
  margin-right: 2px;
  path {
    stroke: ${({ theme }) => theme.colors.deepPurple};
    stroke-width: 1.5px;
  }
`;

const StyledSupportIcon = styled(SupportIcon)`
  width: 18.355px;
  height: 18.153px;
  margin-right: 2px;
`;

const StyledEmailIcon = styled(EmailIcon)`
  width: 18.355px;
  height: 18.153px;
  margin-right: 2px;
  margin-top: -1px;
`;

const SupportSectionMapper = new Map<
  ServiceLine,
  {
    icon: React.ReactElement;
    title?: string;
    description?: string;
    intents: {
      email: string;
      phone: string;
    };
  }
>([
  [
    ServiceLine.LAUNDRY_SERVICE,
    {
      icon: <CPIcon />,
      title: 'Dry Cleaning & Laundry Support',
      description: 'Available from 09:00 am - 11:30 pm',
      intents: {
        email: 'support@washmen.com',
        phone: '+97148132999',
      },
    },
  ],
  [
    ServiceLine.SHOE_SERVICE,
    {
      icon: <SCIcon />,
      title: 'Shoe & Bag Care Support',
      description: 'Available from 09:00 am - 06:00 pm',
      intents: {
        email: 'support@washmen.com',
        phone: '+97148132999',
      },
    },
  ],
]);

const SupportSection: React.FC<Props> = ({ serviceLine, onCloseSheet }) => {
  const { t } = useTranslation();
  const { show: handleIntercomRedirect, update: updateIntercomTheme } = useMessaging();
  const isThemeUpdated = useRef<boolean>(false);
  const sectionInfo = useMemo(() => SupportSectionMapper.get(serviceLine), [serviceLine]);
  const theme = useTheme();
  const ServiceInfo = useMemo(
    () => (
      <StyledRow>
        <LogoContainer>
          <StyledIconContainer className="icon">{sectionInfo?.icon}</StyledIconContainer>
        </LogoContainer>
        <Col xs={-1}>
          <StyledTitle>{t(`supportPage.supportSheet.${serviceLine}.title`)}</StyledTitle>
          <StyledDescription>{t(`supportPage.supportSheet.${serviceLine}.description`)}</StyledDescription>
        </Col>
      </StyledRow>
    ),
    [sectionInfo?.icon, serviceLine, t]
  );

  const onClickPhone = () => {
    window.open(`tel:${sectionInfo?.intents.phone}`);
  };

  const onClickEmail = () => {
    window.open(`mailto:${sectionInfo?.intents.email}`);
  };

  const onClickSupport = () => {
    if (!isThemeUpdated.current) {
      updateIntercomTheme({
        background_color: theme.intercom?.backgroundColor,
        action_color: theme.intercom?.actionColor,
      });
      isThemeUpdated.current = true;
    }
    handleIntercomRedirect();
    Avo.supportChat();
    onCloseSheet?.();
  };

  const Actions = useMemo(
    () => (
      <StyledRow className="mt-4 mb-4">
        <StyledButton onClick={onClickPhone} block={false} backgroundColor="white" textColor="black" variant="primary">
          <StyledPhoneIconWashmen /> {t(`supportPage.supportSheet.actionTexts.call`)}
        </StyledButton>
        <StyledButton onClick={onClickSupport} block={false} backgroundColor="white" textColor="black" variant="primary">
          <StyledSupportIcon /> {t(`supportPage.supportSheet.actionTexts.chat`)}
        </StyledButton>
        <StyledButton onClick={onClickEmail} block={false} backgroundColor="white" textColor="black" variant="primary">
          <StyledEmailIcon /> {t(`supportPage.supportSheet.actionTexts.email`)}
        </StyledButton>
      </StyledRow>
    ),
    []
  );

  return (
    <StyledCard>
      {ServiceInfo}
      {Actions}
    </StyledCard>
  );
};

export default memo(SupportSection);
