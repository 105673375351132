// Generated by Avo VERSION 142.6.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true;
        } catch (e) {
          return false;
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self,
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj);
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]',
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1;
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name');
    }
    return name.toLowerCase();
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value;
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value };
      },
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator;
      };
    }

    return iterator;
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null;
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name));
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items);
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items);
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items);
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    });
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise;
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise;
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('');
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0);
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer;
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode);
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse);
    };

    // @ts-ignore
    return this;
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method;
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read');
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests');
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit });
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form;
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers;
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url,
    });
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response;
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code');
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } });
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'));
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    });
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = 'prod',
  Dev = 'dev',
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (groupType: string, groupId: string, groupProperties: Record<string, any>) => void;
  addCurrentUserToGroup?: (groupType: string, groupId: string, groupProperties: Record<string, any>) => void;
  logEventWithGroups?: (eventName: string, eventProperties: any, groupTypesToGroupIds: Record<string, string>) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[];
  shape?: any;
  shapeUserProps?: any;
  actualType?: string;
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = 'skk2gmHDhF8YREP0YLM7';
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;

// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign(target: any, _varArgs: any) {
      // .length of function is 2
      if (target == null) {
        // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) {
          // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true,
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = 'BottomRight',
  BottomLeft = 'BottomLeft',
  TopRight = 'TopRight',
  TopLeft = 'TopLeft',
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
};

let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = 'Event Sent:' + eventName + 'Event Props:' + JSON.stringify(eventProperties) + 'User Props:' + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return;
    }
    typeof console !== 'undefined' && console.log('[avo] Event Sent:', eventName, 'Event Props:', eventProperties, 'User Props:', userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return;
    }
    typeof console !== 'undefined' && console.log('[avo] ' + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return;
    }
    typeof console !== 'undefined' && console.warn('[avo] ' + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return;
    }
    typeof console !== 'undefined' && console.error('[avo] ' + message, error);
  },
};

function convertPropertiesArrayToMap(propertiesArray: [{ id: string; name: string; value: string }]): { string: string | null | undefined } {
  let result: { string: string } = {};

  propertiesArray.forEach((value) => {
    result[value.name] = value.value;
  });

  return result;
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
};

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName + ' should be of type object but you provided type ' + typeof obj + ' with value ' + JSON.stringify(obj);
      return [{ tag: 'expectedObjectType', propertyId, message, actualType: typeof obj }];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName + ' should be of type string but you provided type ' + typeof str + ' with value ' + JSON.stringify(str);
      return [{ tag: 'expectedStringType', propertyId, message, actualType: typeof str }];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName + ' should be of type int but you provided type float with value ' + JSON.stringify(int);
      return [{ tag: 'expectedIntType', propertyId, message, actualType: 'float' }];
    } else if (typeof int !== 'number') {
      let message = propName + ' should be of type int but you provided type ' + typeof int + ' with value ' + JSON.stringify(int);
      return [{ tag: 'expectedIntType', propertyId, message, actualType: typeof int }];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName + ' should be of type long but you provided type float with value ' + JSON.stringify(long);
      return [{ tag: 'expectedLongType', propertyId, message, actualType: 'float' }];
    } else if (typeof long !== 'number') {
      let message = propName + ' should be of type long but you provided type ' + typeof long + ' with value ' + JSON.stringify(long);
      return [{ tag: 'expectedLongType', propertyId, message, actualType: typeof long }];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName + ' should be of type float but you provided type ' + typeof float + ' with value ' + JSON.stringify(float);
      return [{ tag: 'expectedFloatType', propertyId, message, actualType: typeof float }];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName + ' should be of type boolean but you provided type ' + typeof bool + ' with value ' + JSON.stringify(bool);
      return [{ tag: 'expectedBoolType', propertyId, message, actualType: typeof bool }];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(propertyId: string, propName: string, max: number, value: number) {
    if (value > max) {
      let message = propName + ' has a maximum value of ' + max + ' but you provided the value ' + JSON.stringify(value);
      return [{ tag: 'expectedMax', propertyId, message }];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(propertyId: string, propName: string, min: number, value: number) {
    if (value < min) {
      let message = propName + ' has a minimum value of ' + min + ' but you provided the value ' + JSON.stringify(value);
      return [{ tag: 'expectedMin', propertyId, message }];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{ tag: 'expectedList', propertyId, message }];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = 'Additional properties when sending event ' + eventName + ': ' + JSON.stringify(additionalKeys);
      return [{ tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message }];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = 'Additional user properties when sending event ' + eventName + ': ' + JSON.stringify(additionalKeys);
      return [{ tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message }];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: { tag: string; propertyId: string }[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') {
    return;
  }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch('https://api.avo.app/i', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ac: 'CNeSf9s7YSVzdUAcjEGY',
          br: 'mxbaWh9La',
          en: env,
          ev: eventId,
          ha: hash,
          sc: 'qgnGqJ4tQtjQ1YP3TgXA',
          se: new Date().toISOString(),
          so: 'BjItkmjLp',
          va: messages.length === 0,
          me: messages,
          or: origin,
        }),
      })
        .then(function (res: any) {
          return res.json();
        })
        .then(function (data: any) {
          _avo_sampling_rate = data.sa;
        })
        .catch(function () {});
    }
  }
};

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: { tag: string; propertyId: string }[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') {
    return;
  }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch('https://api.avo.app/i', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ac: 'CNeSf9s7YSVzdUAcjEGY',
          br: 'mxbaWh9La',
          en: env,
          ty: type,
          sc: 'qgnGqJ4tQtjQ1YP3TgXA',
          se: new Date().toISOString(),
          so: 'BjItkmjLp',
          va: messages.length === 0,
          me: messages,
          or: origin,
        }),
      })
        .then(function (res: any) {
          return res.json();
        })
        .then(function (data: any) {
          _avo_sampling_rate = data.sa;
        })
        .catch(function () {});
    }
  }
};

let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener('message', function (event) {
    if (event.origin !== 'https://www.avo.app') {
      return;
    }
    let iframe: any = document.getElementById('avo-debugger');
    if (iframe && event && event.data && event.data.type_ === 'avo-debugger-update-style') {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === 'avo-debugger-ready') {
      let message = {
        type_: 'avo-debugger-boot-events',
        schemaId: 'qgnGqJ4tQtjQ1YP3TgXA',
        href: window.location.href,
        events: _avo_debugger_events_during_boot,
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, 'https://www.avo.app/_debugger');
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(
  eventId: string,
  eventName: string,
  messages: any[],
  eventProperties: any[],
  userProperties: any[],
  groupProperties: any[]
) {
  if (typeof (window as any) === 'undefined') {
    return;
  }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties,
  };

  if (_avo_debugger_ready) {
    let message = { type_: 'avo-debugger-events', events: [event] };
    (document.getElementById('avo-debugger') as any).contentWindow.postMessage(message, 'https://www.avo.app/_debugger');
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
};

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') {
    return;
  }
  window.addEventListener('message', function (event) {
    if (event.origin !== 'https://www.avo.app') {
      return;
    }
    if (event.data.type_ === 'avo-debugger-ready' && position !== null) {
      var message = { type_: 'avo-debugger-position', position: position };
      document.getElementById('avo-debugger').contentWindow.postMessage(message, 'https://www.avo.app/_debugger');
    }
  });
}

let sysPlatform: any;
let sysDevice: any;
let sysAppVersion: any;
let sysOrigin: any;

export function setSystemProperties(properties: { platform: string; device: string; appVersion: string; origin: string | null | undefined }) {
  if (properties.platform !== null && properties.platform !== undefined) {
    sysPlatform = properties.platform;
  }
  if (properties.device !== null && properties.device !== undefined) {
    sysDevice = properties.device;
  }
  if (properties.appVersion !== null && properties.appVersion !== undefined) {
    sysAppVersion = properties.appVersion;
  }
  if (properties.origin !== null && properties.origin !== undefined) {
    sysOrigin = properties.origin;
  }
}

let Mixpanel: any;

export function initAvo(
  options: {
    env: AvoEnv;
    webDebugger?: boolean;
    webDebuggerOptions?: webDebuggerOptions;
    strict?: boolean;
    noop?: boolean;
    reportFailureAs?: 'error' | 'warn' | 'log';
    inspector?: AvoInspector;
    avoLogger?: AvoLogger;
  },
  systemProperties: { platform: string; device: string; appVersion: string; origin: string | null | undefined },
  destinationOptions: any,
  MixpanelDestination: CustomDestination
) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn('[avo] ****************************************************');
    InternalAvoLogger.warn('[avo] WARNING Avo cannot be initialized in noop mode in production:');
    InternalAvoLogger.warn('[avo] - Overwriting configuration with noop=false.');
    InternalAvoLogger.warn('[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true');
    InternalAvoLogger.warn('[avo] ****************************************************');
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log('[avo] ****************************************************');
    InternalAvoLogger.log('[avo] Avo is now initialized in noop mode. This means:');
    InternalAvoLogger.log('[avo] - No events will be sent');
    InternalAvoLogger.log('[avo] - No network requests are made');
    InternalAvoLogger.log('[avo] ****************************************************');
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ =
    !__AVO_NOOP__ &&
    ((typeof window !== 'undefined' && (window as any).location.search.indexOf('avo_debug=1') > -1) ||
      (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn('[avo] Avo Inspector not provided in initAvo() call');
  }

  setSystemProperties(systemProperties);

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position);
    }

    (function () {
      if (typeof (window as any) === 'undefined') {
        return;
      }
      let init = function () {
        let iframe: any = document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.id = 'avo-debugger';
        iframe.src = 'https://www.avo.app/_debugger';
        iframe.style = 'display: none;';
      };

      if (document.body) {
        init();
      } else {
        document.addEventListener('DOMContentLoaded', init);
      }
    })();
  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }

    Mixpanel = MixpanelDestination;
    if (__AVO_ENV__ === 'prod') {
      Mixpanel && Mixpanel.make && Mixpanel.make(__AVO_ENV__, null);
    } else if (__AVO_ENV__ === 'dev') {
      Mixpanel && Mixpanel.make && Mixpanel.make(__AVO_ENV__, null);
    } else {
      console[__REPORT_FAILURE_AS__ || 'error'](
        '[avo] No staging key is set for Mixpanel. Head to destination settings in Avo to set a staging key.'
      );
      Mixpanel && Mixpanel.make && Mixpanel.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface SignupProperties {
  userId_: string;
  signupMethod: string | null | undefined;
  email: string;
  phone: string;
  signedUpDate: string;
  source: string | null | undefined;
  firstName: string;
  lastName: string;
  otpCommunicationMethod: string | null | undefined;
  countryCode: string | null | undefined;
}
/**
 * signup: When a new user finishes registration
 *
 * @param properties the properties associatied with this event
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.signupMethod: Apple, Phone Number
 * @param properties.email: no description
 * @param properties.phone: no description
 * @param properties.signedUpDate: no description
 * @param properties.source: Which channel did the user come from: Facebook, Google Search, Rizek, Organic.. etc
We can get this data from Adjust
 * @param properties.firstName: no description
 * @param properties.lastName: no description
 * @param properties.otpCommunicationMethod: Method to send OTP sms / whatsapp / telegram
 * @param properties.countryCode: Country of phone number used UAE / US etc.
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/rSfGsBfBTq}
 */
export function signup(properties: SignupProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.signupMethod !== undefined && properties.signupMethod !== null
    ? eventPropertiesArray.push({ id: 'rY75cwDei_', name: 'signup_method', value: properties.signupMethod })
    : eventPropertiesArray.push({ id: 'rY75cwDei_', name: 'signup_method', value: null });
  properties.otpCommunicationMethod !== undefined && properties.otpCommunicationMethod !== null
    ? eventPropertiesArray.push({ id: 'LZvE8ixWp', name: 'otp_communication_method', value: properties.otpCommunicationMethod })
    : eventPropertiesArray.push({ id: 'LZvE8ixWp', name: 'otp_communication_method', value: null });
  properties.countryCode !== undefined && properties.countryCode !== null
    ? eventPropertiesArray.push({ id: 'Hbln_EmWE', name: 'country_code', value: properties.countryCode })
    : eventPropertiesArray.push({ id: 'Hbln_EmWE', name: 'country_code', value: null });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  userPropertiesArray.push({ id: 'X6ahuo0hQu', name: 'email', value: properties.email });
  userPropertiesArray.push({ id: 'AWYebLT32f', name: 'phone', value: properties.phone });
  userPropertiesArray.push({ id: 'JZj1LRJNhw', name: 'signed_up_date', value: properties.signedUpDate });
  properties.source !== undefined && properties.source !== null
    ? userPropertiesArray.push({ id: 'xivF8TDZ7L', name: 'source', value: properties.source })
    : userPropertiesArray.push({ id: 'xivF8TDZ7L', name: 'source', value: null });
  userPropertiesArray.push({ id: 'rX-B3iASXu', name: 'first_name', value: properties.firstName });
  userPropertiesArray.push({ id: '2vR2R3Hnz4', name: 'last_name', value: properties.lastName });
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'rSfGsBfBTq',
        'b051f31b064cb0e60dd21c22ebc80ea22a0fd173c84923d34c6c177e516d0977',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('signup', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('rSfGsBfBTq', 'signup', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'signup',
        eventProperties,
        'rSfGsBfBTq',
        'b051f31b064cb0e60dd21c22ebc80ea22a0fd173c84923d34c6c177e516d0977'
      );
    }
    // destination Mixpanel
    Mixpanel.identify(properties.userId_);
    Mixpanel.setUserProperties(properties.userId_, (Object as any).assign({}, userProperties));
    Mixpanel.logEvent('signup', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface VisitProperties {
  userId_: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
}
/**
 * visit: When a user opens mobile app or webapp
 *
 * @param properties the properties associatied with this event
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.email: no description
 * @param properties.phone: no description
 * @param properties.firstName: no description
 * @param properties.lastName: no description
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/TcUC044dbl}
 */
export function visit(properties: VisitProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  userPropertiesArray.push({ id: 'X6ahuo0hQu', name: 'email', value: properties.email });
  userPropertiesArray.push({ id: 'AWYebLT32f', name: 'phone', value: properties.phone });
  userPropertiesArray.push({ id: 'rX-B3iASXu', name: 'first_name', value: properties.firstName });
  userPropertiesArray.push({ id: '2vR2R3Hnz4', name: 'last_name', value: properties.lastName });
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'TcUC044dbl',
        '403579d51c654dc58214da889c629e8180909691570204a9dbfdc1ef359e3897',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('visit', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('TcUC044dbl', 'visit', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'visit',
        eventProperties,
        'TcUC044dbl',
        '403579d51c654dc58214da889c629e8180909691570204a9dbfdc1ef359e3897'
      );
    }
    // destination Mixpanel
    Mixpanel.identify(properties.userId_);
    Mixpanel.setUserProperties(properties.userId_, (Object as any).assign({}, userProperties));
    Mixpanel.logEvent('visit', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LoginProperties {
  userId_: string;
  email: string;
  signedUpDate: string;
  phone: string;
  firstName: string;
  lastName: string;
}
/**
 * login: When an existing user logs in to their account
 *
 * @param properties the properties associatied with this event
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.email: no description
 * @param properties.signedUpDate: no description
 * @param properties.phone: no description
 * @param properties.firstName: no description
 * @param properties.lastName: no description
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/scVuQJEyo7}
 */
export function login(properties: LoginProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  userPropertiesArray.push({ id: 'X6ahuo0hQu', name: 'email', value: properties.email });
  userPropertiesArray.push({ id: 'JZj1LRJNhw', name: 'signed_up_date', value: properties.signedUpDate });
  userPropertiesArray.push({ id: 'AWYebLT32f', name: 'phone', value: properties.phone });
  userPropertiesArray.push({ id: 'rX-B3iASXu', name: 'first_name', value: properties.firstName });
  userPropertiesArray.push({ id: '2vR2R3Hnz4', name: 'last_name', value: properties.lastName });
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'scVuQJEyo7',
        '8ae10a1ff2e4681770daaa2ab134cab9e6f3b52b1b9e95004e7afee2852fbadf',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('login', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('scVuQJEyo7', 'login', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'login',
        eventProperties,
        'scVuQJEyo7',
        '8ae10a1ff2e4681770daaa2ab134cab9e6f3b52b1b9e95004e7afee2852fbadf'
      );
    }
    // destination Mixpanel
    Mixpanel.identify(properties.userId_);
    Mixpanel.setUserProperties(properties.userId_, (Object as any).assign({}, userProperties));
    Mixpanel.logEvent('login', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewPricingProperties {
  screen: string;
}
/**
 * view_pricing: When user clicks on "Price list"
 *
 * @param properties the properties associatied with this event
 * @param properties.screen: name of page/screen on which the user did the action
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/dOox_6ENvH}
 */
export function viewPricing(properties: ViewPricingProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'dOox_6ENvH',
        '4685a48c660639fdfecc876f7029b0d2e4eb6851a0bfbeeba5fd6ef90a70be70',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('view_pricing', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('dOox_6ENvH', 'view_pricing', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'view_pricing',
        eventProperties,
        'dOox_6ENvH',
        '4685a48c660639fdfecc876f7029b0d2e4eb6851a0bfbeeba5fd6ef90a70be70'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('view_pricing', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewProductProperties {
  screen: string;
  productName: string;
}
/**
 * view_product: When user views details of a specific bag or a shop item
 *
 * @param properties the properties associatied with this event
 * @param properties.screen: name of page/screen on which the user did the action
 * @param properties.productName: Clean & Press, Press Only, HomeCare, Wash & Fold, Door Hook.. etc
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/BM-iYvbd9C}
 */
export function viewProduct(properties: ViewProductProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'XiRvin0Ifa', name: 'product_name', value: properties.productName });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'BM-iYvbd9C',
        '733e4895dad5b109db5fb551e553698a98d5d7f7e8a5ca6034e2bac094cc9381',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('view_product', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('BM-iYvbd9C', 'view_product', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'view_product',
        eventProperties,
        'BM-iYvbd9C',
        '733e4895dad5b109db5fb551e553698a98d5d7f7e8a5ca6034e2bac094cc9381'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('view_product', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewProductTermsProperties {
  productName: string;
}
/**
 * view_product_terms: When a user views product terms & other charges
 *
 * @param properties the properties associatied with this event
 * @param properties.productName: Clean & Press, Press Only, HomeCare, Wash & Fold, Door Hook.. etc
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/KaHAXVROwJ}
 */
export function viewProductTerms(properties: ViewProductTermsProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'XiRvin0Ifa', name: 'product_name', value: properties.productName });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'KaHAXVROwJ',
        '3bdcbe1811622e146039b4c24c02d1aabd0f84f9a95589d20863be68da76d2a1',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('view_product_terms', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('KaHAXVROwJ', 'view_product_terms', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'view_product_terms',
        eventProperties,
        'KaHAXVROwJ',
        '3bdcbe1811622e146039b4c24c02d1aabd0f84f9a95589d20863be68da76d2a1'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('view_product_terms', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewProductProcessProperties {
  productName: string;
  processName: string;
}
/**
 * view_product_process: When a user views process details
 *
 * @param properties the properties associatied with this event
 * @param properties.productName: Clean & Press, Press Only, HomeCare, Wash & Fold, Door Hook.. etc
 * @param properties.processName: Opticlean/ Air Dry/ Steam Press/ Disinfection/ Special Requests / No Mixing/ ColorSplit..etc
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/w8akthyROg}
 */
export function viewProductProcess(properties: ViewProductProcessProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'XiRvin0Ifa', name: 'product_name', value: properties.productName });
  eventPropertiesArray.push({ id: '7njO8NFGZL', name: 'process_name', value: properties.processName });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'w8akthyROg',
        'e3854d786228f19496c6119872efea89f56536c9d32b1f4d738c506aa4e54473',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('view_product_process', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('w8akthyROg', 'view_product_process', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'view_product_process',
        eventProperties,
        'w8akthyROg',
        'e3854d786228f19496c6119872efea89f56536c9d32b1f4d738c506aa4e54473'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('view_product_process', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewSupportProperties {
  screen: string;
}
/**
 * view_support: When a user views support screen
 *
 * @param properties the properties associatied with this event
 * @param properties.screen: name of page/screen on which the user did the action
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/uto-QgOb6g}
 */
export function viewSupport(properties: ViewSupportProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'uto-QgOb6g',
        'ca7dbf57bbd3a1f6f83dafa52ca0ef312ab27f9889c58e5167e21909768952d3',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('view_support', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('uto-QgOb6g', 'view_support', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'view_support',
        eventProperties,
        'uto-QgOb6g',
        'ca7dbf57bbd3a1f6f83dafa52ca0ef312ab27f9889c58e5167e21909768952d3'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('view_support', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * support_call: User called support
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/J8nb5L07lP}
 */
export function supportCall() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'J8nb5L07lP',
        'bfe2f3cfa5fbbf387dab9e11b344084bb295193c0ee6b50e347d750d4dc4eeb9',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('support_call', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('J8nb5L07lP', 'support_call', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'support_call',
        eventProperties,
        'J8nb5L07lP',
        'bfe2f3cfa5fbbf387dab9e11b344084bb295193c0ee6b50e347d750d4dc4eeb9'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('support_call', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * support_message: User emailed support
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/QStx_BOe0_}
 */
export function supportMessage() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'QStx_BOe0_',
        'b47c0907bfcedd0a2f0b3180dc28327bf19d9cc7c78a0696336290d15773e377',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('support_message', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('QStx_BOe0_', 'support_message', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'support_message',
        eventProperties,
        'QStx_BOe0_',
        'b47c0907bfcedd0a2f0b3180dc28327bf19d9cc7c78a0696336290d15773e377'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('support_message', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * support_chat: User contacted support via live chat (intercom)
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/v2HEW9XKbd}
 */
export function supportChat() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'v2HEW9XKbd',
        '2ab28755184b6c247bf07ae0d305b672810c1feb04e671cd827f66461cfe6e16',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('support_chat', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('v2HEW9XKbd', 'support_chat', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'support_chat',
        eventProperties,
        'v2HEW9XKbd',
        '2ab28755184b6c247bf07ae0d305b672810c1feb04e671cd827f66461cfe6e16'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('support_chat', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * viewed_orders: User checked their order history
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/OQCYIi0DY9}
 */
export function viewedOrders() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'OQCYIi0DY9',
        '2ec581ee8c227c7803876b098054a45f0b81bc620e447c2b3f368fc17a477ca2',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('viewed_orders', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('OQCYIi0DY9', 'viewed_orders', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'viewed_orders',
        eventProperties,
        'OQCYIi0DY9',
        '2ec581ee8c227c7803876b098054a45f0b81bc620e447c2b3f368fc17a477ca2'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('viewed_orders', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OrderStartedProperties {
  screen: string;
  serviceLinesSelected: string[] | null | undefined;
}
/**
 * order_started: User clicked on "Place New Order"
 *
 * @param properties the properties associatied with this event
 * @param properties.screen: name of page/screen on which the user did the action
 * @param properties.serviceLinesSelected: All service Lines selected for order on PWA
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/N-dVmZA4sS}
 */
export function orderStarted(properties: OrderStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  properties.serviceLinesSelected !== undefined && properties.serviceLinesSelected !== null
    ? eventPropertiesArray.push({ id: 'HaAFtI44q', name: 'service_lines_selected', value: properties.serviceLinesSelected })
    : eventPropertiesArray.push({ id: 'HaAFtI44q', name: 'service_lines_selected', value: null });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'N-dVmZA4sS',
        '3f5dbcef4a74873302278453e942f0f16d1f94bba63f884fac374e2603270e52',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('order_started', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('N-dVmZA4sS', 'order_started', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'order_started',
        eventProperties,
        'N-dVmZA4sS',
        '3f5dbcef4a74873302278453e942f0f16d1f94bba63f884fac374e2603270e52'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('order_started', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OrderTypeSelectedProperties {
  orderType: string;
}
/**
 * order_type_selected: User selected order type: Flex / Priority
 *
 * @param properties the properties associatied with this event
 * @param properties.orderType: Flex/Priority
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/Pe7SH_yr30}
 */
export function orderTypeSelected(properties: OrderTypeSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'O-csW1oVBx', name: 'order_type', value: properties.orderType });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'Pe7SH_yr30',
        '0293aa28e60b9cc6866bf3b5d0e5bd46adf130aa553cb7fc495b32ed53d9bb87',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('order_type_selected', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('Pe7SH_yr30', 'order_type_selected', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'order_type_selected',
        eventProperties,
        'Pe7SH_yr30',
        '0293aa28e60b9cc6866bf3b5d0e5bd46adf130aa553cb7fc495b32ed53d9bb87'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('order_type_selected', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SchedulePickupProperties {
  pickupTime: string;
  pickupDay: string;
}
/**
 * schedule_pickup: User selected pickup time
 *
 * @param properties the properties associatied with this event
 * @param properties.pickupTime: Pickup time chosen by user
 * @param properties.pickupDay: Day selected for pickup (monday, tuesday, wednesday..etc)



 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/wo12CfZAKt}
 */
export function schedulePickup(properties: SchedulePickupProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '8EyVodmm6', name: 'pickup_time', value: properties.pickupTime });
  eventPropertiesArray.push({ id: 'IIbRZbtf1l', name: 'pickup_day', value: properties.pickupDay });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'wo12CfZAKt',
        '2e94cf10a4e02022832f5589113b3440276c05f1295b1eaf9bbdc81d31d5cc9b',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('schedule_pickup', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('wo12CfZAKt', 'schedule_pickup', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'schedule_pickup',
        eventProperties,
        'wo12CfZAKt',
        '2e94cf10a4e02022832f5589113b3440276c05f1295b1eaf9bbdc81d31d5cc9b'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('schedule_pickup', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ScheduleDropoffProperties {
  dropoffDay: string;
  dropoffTime: string;
}
/**
 * schedule_dropoff: User selected dropoff time
 *
 * @param properties the properties associatied with this event
 * @param properties.dropoffDay: Day selected for dropoff (monday, tuesday, wednesday..etc)
 * @param properties.dropoffTime: Time chosen for dropoff
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/xdPC5bph31}
 */
export function scheduleDropoff(properties: ScheduleDropoffProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '9_FB-Dd0N6', name: 'dropoff_day', value: properties.dropoffDay });
  eventPropertiesArray.push({ id: 'v1Dvm37zM2', name: 'dropoff_time', value: properties.dropoffTime });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'xdPC5bph31',
        '9f7773d2da0121c17f08c5321428d9918316a2656cbf946f1fe4b2c8152f9066',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('schedule_dropoff', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('xdPC5bph31', 'schedule_dropoff', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'schedule_dropoff',
        eventProperties,
        'xdPC5bph31',
        '9f7773d2da0121c17f08c5321428d9918316a2656cbf946f1fe4b2c8152f9066'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('schedule_dropoff', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OrderInstructionsAddedProperties {
  instructionType: string;
}
/**
 * order_instructions_added: User added laundry order instructions
 *
 * @param properties the properties associatied with this event
 * @param properties.instructionType: Folding, Crease, Starch, Stain & Damage Approval
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/ggFJx0jt9t}
 */
export function orderInstructionsAdded(properties: OrderInstructionsAddedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'gUvRo5lFfH', name: 'instruction_type', value: properties.instructionType });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'ggFJx0jt9t',
        '9fcb0a2a9e9d40205d3ae41eec463cb700c6365db98575ba21107f8859b778d0',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('order_instructions_added', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('ggFJx0jt9t', 'order_instructions_added', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'order_instructions_added',
        eventProperties,
        'ggFJx0jt9t',
        '9fcb0a2a9e9d40205d3ae41eec463cb700c6365db98575ba21107f8859b778d0'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('order_instructions_added', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface UsedPromocodeProperties {
  promocode: string;
}
/**
 * used_promocode: User added promocode
 *
 * @param properties the properties associatied with this event
 * @param properties.promocode: the promocode name used by user
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/CRC4Kh3tR9}
 */
export function usedPromocode(properties: UsedPromocodeProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '1f-PtbAymi', name: 'promocode', value: properties.promocode });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'CRC4Kh3tR9',
        '5e51be4e09cd43602cf6f4928d0e45f48ca0411fab56fb8075a251d4baaf2125',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('used_promocode', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('CRC4Kh3tR9', 'used_promocode', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'used_promocode',
        eventProperties,
        'CRC4Kh3tR9',
        '5e51be4e09cd43602cf6f4928d0e45f48ca0411fab56fb8075a251d4baaf2125'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('used_promocode', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OrderPlacedProperties {
  orderType: string;
  addressGeofence: string;
  pickupTime: string;
  dropoffTime: string;
  pickupDay: string;
  dropoffDay: string;
  orderId: string;
  customerId: string | null | undefined;
  origin: string | null | undefined;
  paymentMethod: string;
  serviceLinesSelected: string[];
}
/**
 * order_placed: User confirms placing the order
 *
 * @param properties the properties associatied with this event
 * @param properties.orderType: Flex/Priority
 * @param properties.addressGeofence: The user's address area
 * @param properties.pickupTime: Pickup time chosen by user
 * @param properties.dropoffTime: Time chosen for dropoff
 * @param properties.pickupDay: Day selected for pickup (monday, tuesday, wednesday..etc)



 * @param properties.dropoffDay: Day selected for dropoff (monday, tuesday, wednesday..etc)
 * @param properties.orderId: ID associated with a customer order
 * @param properties.customerId: no description
 * @param properties.origin: no description
 * @param properties.paymentMethod: Apple Pay, Debit/Credit Card.. etc
 * @param properties.serviceLinesSelected: All service Lines selected for order on PWA
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/ytMOcGYPab}
 */
export function orderPlaced(properties: OrderPlacedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'O-csW1oVBx', name: 'order_type', value: properties.orderType });
  eventPropertiesArray.push({ id: 'QOsRbxrxbB', name: 'address_geofence', value: properties.addressGeofence });
  eventPropertiesArray.push({ id: '8EyVodmm6', name: 'pickup_time', value: properties.pickupTime });
  eventPropertiesArray.push({ id: 'v1Dvm37zM2', name: 'dropoff_time', value: properties.dropoffTime });
  eventPropertiesArray.push({ id: 'IIbRZbtf1l', name: 'pickup_day', value: properties.pickupDay });
  eventPropertiesArray.push({ id: '9_FB-Dd0N6', name: 'dropoff_day', value: properties.dropoffDay });
  eventPropertiesArray.push({ id: 'A47trmLVRF', name: 'order_id', value: properties.orderId });
  properties.customerId !== undefined && properties.customerId !== null
    ? eventPropertiesArray.push({ id: 'w1v6Ib6YKC', name: 'customer_id', value: properties.customerId })
    : eventPropertiesArray.push({ id: 'w1v6Ib6YKC', name: 'customer_id', value: null });
  properties.origin !== undefined && properties.origin !== null
    ? eventPropertiesArray.push({ id: 'wrY7FLKwz', name: 'origin', value: properties.origin })
    : eventPropertiesArray.push({ id: 'wrY7FLKwz', name: 'origin', value: null });
  eventPropertiesArray.push({ id: '4lorbPwsS_', name: 'payment_method', value: properties.paymentMethod });
  eventPropertiesArray.push({ id: 'HaAFtI44q', name: 'service_lines_selected', value: properties.serviceLinesSelected });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'ytMOcGYPab',
        'a5e8f80835719720c187ebbcf5bb0eaa34226368ce81f0f4f9d75c7d2c2b3219',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('order_placed', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('ytMOcGYPab', 'order_placed', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'order_placed',
        eventProperties,
        'ytMOcGYPab',
        'a5e8f80835719720c187ebbcf5bb0eaa34226368ce81f0f4f9d75c7d2c2b3219'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('order_placed', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OrderCancelledProperties {
  cancellationReason: string;
}
/**
 * order_cancelled: Order was cancelled
 *
 * @param properties the properties associatied with this event
 * @param properties.cancellationReason: Reason for cancellation (driver late, ordered by acciden... etc)
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/VIONK19myI}
 */
export function orderCancelled(properties: OrderCancelledProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'nGQXa-8fsA', name: 'cancellation_reason', value: properties.cancellationReason });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'VIONK19myI',
        '50c5c7f1b8873c29e501763f29866a3f844190c96c1945ea3e6f14128240a15c',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('order_cancelled', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('VIONK19myI', 'order_cancelled', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'order_cancelled',
        eventProperties,
        'VIONK19myI',
        '50c5c7f1b8873c29e501763f29866a3f844190c96c1945ea3e6f14128240a15c'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('order_cancelled', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PageviewProperties {
  screen: string;
  previousScreen: string | null | undefined;
}
/**
 * pageview: User viewing a screen
 *
 * @param properties the properties associatied with this event
 * @param properties.screen: name of page/screen on which the user did the action
 * @param properties.previousScreen: The screen in which the new screen was navigated from. In case if there is no previous screen,
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/YfQu_kjSSQ}
 */
export function pageview(properties: PageviewProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  properties.previousScreen !== undefined && properties.previousScreen !== null
    ? eventPropertiesArray.push({ id: 'EYdeJO72fd', name: 'previous_screen', value: properties.previousScreen })
    : eventPropertiesArray.push({ id: 'EYdeJO72fd', name: 'previous_screen', value: null });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'YfQu_kjSSQ',
        'f96d52d5bf96af0b4ac8a2dac40cf7fe45bb54a563f566cb85bfd9efadd9d19a',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('pageview', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('YfQu_kjSSQ', 'pageview', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'pageview',
        eventProperties,
        'YfQu_kjSSQ',
        'f96d52d5bf96af0b4ac8a2dac40cf7fe45bb54a563f566cb85bfd9efadd9d19a'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('pageview', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * logout: User logs out of his washmen account
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/jBu_Lv7ikg}
 */
export function logout() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'jBu_Lv7ikg',
        'cf8ba0bb9d1590a968356d95f59bf877c02e0a656680a478459919b370c8fb13',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('logout', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('jBu_Lv7ikg', 'logout', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'logout',
        eventProperties,
        'jBu_Lv7ikg',
        'cf8ba0bb9d1590a968356d95f59bf877c02e0a656680a478459919b370c8fb13'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('logout', (Object as any).assign({}, eventProperties));
    Mixpanel.unidentify();
  } else {
    // do nothing
  }
}

/**
 * first_order_placed: This event is triggered when a user places an order for the first time
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/CIXu7ZUiHr}
 */
export function firstOrderPlaced() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'CIXu7ZUiHr',
        'a97b06dcc71e3429cb839329a7d719c3db566a796efe726bbd3610d3986bff33',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('first_order_placed', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('CIXu7ZUiHr', 'first_order_placed', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'first_order_placed',
        eventProperties,
        'CIXu7ZUiHr',
        'a97b06dcc71e3429cb839329a7d719c3db566a796efe726bbd3610d3986bff33'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('first_order_placed', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CarouselVideosProperties {
  screen: string;
}
/**
 * Carousel_videos: When a user clicks on the carousel to watch a discovery video
 *
 * @param properties the properties associatied with this event
 * @param properties.screen: name of page/screen on which the user did the action
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/Ehhg10rHES}
 */
export function carouselVideos(properties: CarouselVideosProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'Ehhg10rHES',
        'a8ceebc46694014a9c98b471027d934e9b2aff00569e722728e40f9f97dbdb02',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Carousel_videos', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('Ehhg10rHES', 'Carousel_videos', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Carousel_videos',
        eventProperties,
        'Ehhg10rHES',
        'a8ceebc46694014a9c98b471027d934e9b2aff00569e722728e40f9f97dbdb02'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('Carousel_videos', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewOnboardingPageProperties {
  screen: string;
}
/**
 * View_onboarding_page: Customers that viewed onboarding pages
 *
 * @param properties the properties associatied with this event
 * @param properties.screen: name of page/screen on which the user did the action
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/-JPUVS_XBB}
 */
export function viewOnboardingPage(properties: ViewOnboardingPageProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        '-JPUVS_XBB',
        'a5b1bcd5783bb5da9e4e34a212d3cb601a8da6fbc0a04ff53ed59ca533abcff5',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('View_onboarding_page', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('-JPUVS_XBB', 'View_onboarding_page', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'View_onboarding_page',
        eventProperties,
        '-JPUVS_XBB',
        'a5b1bcd5783bb5da9e4e34a212d3cb601a8da6fbc0a04ff53ed59ca533abcff5'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('View_onboarding_page', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface VisitOrdersProperties {
  screen: string;
}
/**
 * Visit_orders: Active or past orders
 *
 * @param properties the properties associatied with this event
 * @param properties.screen: name of page/screen on which the user did the action
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/E8810eAgB1}
 */
export function visitOrders(properties: VisitOrdersProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'E8810eAgB1',
        '6e166c87bd8c270ef3d5c258e621c30fe141d3c73d09fcdec3a44352323806f9',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Visit_orders', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('E8810eAgB1', 'Visit_orders', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Visit_orders',
        eventProperties,
        'E8810eAgB1',
        '6e166c87bd8c270ef3d5c258e621c30fe141d3c73d09fcdec3a44352323806f9'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('Visit_orders', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AddPromocodeProperties {
  promocode: string;
}
/**
 * add_promocode: If the user adds a promocode
 *
 * @param properties the properties associatied with this event
 * @param properties.promocode: the promocode name used by user
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/Z5pD_tYuZ9}
 */
export function addPromocode(properties: AddPromocodeProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '1f-PtbAymi', name: 'promocode', value: properties.promocode });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'Z5pD_tYuZ9',
        'c75202bfbd477650e84d5a7e78a30d588983069c9bbd36f99fa7d5b6d0e5dd00',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('add_promocode', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('Z5pD_tYuZ9', 'add_promocode', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'add_promocode',
        eventProperties,
        'Z5pD_tYuZ9',
        'c75202bfbd477650e84d5a7e78a30d588983069c9bbd36f99fa7d5b6d0e5dd00'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('add_promocode', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface EstimatorBagSelectedProperties {
  bagType: string;
}
/**
 * estimator_bag_selected: estimator what bag type was selected
 *
 * @param properties the properties associatied with this event
 * @param properties.bagType: no description
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/teEmWOyRlQF}
 */
export function estimatorBagSelected(properties: EstimatorBagSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '-PevD5qa9', name: 'bag_type', value: properties.bagType });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'teEmWOyRlQF',
        'befc6fb1e4fb42ff3150265ba64cf0bc26588fc338d62076157b5bd70f166fcd',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('estimator_bag_selected', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('teEmWOyRlQF', 'estimator_bag_selected', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'estimator_bag_selected',
        eventProperties,
        'teEmWOyRlQF',
        'befc6fb1e4fb42ff3150265ba64cf0bc26588fc338d62076157b5bd70f166fcd'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('estimator_bag_selected', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface EstimatorCarouselVideosProperties {
  screen: string;
}
/**
 * estimator_carousel_videos: price estimator carousel
 *
 * @param properties the properties associatied with this event
 * @param properties.screen: name of page/screen on which the user did the action
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/yxGwc7gJG4}
 */
export function estimatorCarouselVideos(properties: EstimatorCarouselVideosProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'yxGwc7gJG4',
        'aa22f22d746c9ebe4a671d5e9237693ece73911cf141214cba9dd71aa312b827',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('estimator_carousel_videos', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('yxGwc7gJG4', 'estimator_carousel_videos', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'estimator_carousel_videos',
        eventProperties,
        'yxGwc7gJG4',
        'aa22f22d746c9ebe4a671d5e9237693ece73911cf141214cba9dd71aa312b827'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('estimator_carousel_videos', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface EstimatorBagAddedProperties {
  bagType: string;
}
/**
 * estimator_bag_added: bag added type to bill estimator
 *
 * @param properties the properties associatied with this event
 * @param properties.bagType: no description
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/bDqQ8jOJor}
 */
export function estimatorBagAdded(properties: EstimatorBagAddedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '-PevD5qa9', name: 'bag_type', value: properties.bagType });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'bDqQ8jOJor',
        '9bb6e81cd71facc625285e32f156df3429476a90ffe1e4d45468aa2a5fbc117e',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('estimator_bag_added', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('bDqQ8jOJor', 'estimator_bag_added', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'estimator_bag_added',
        eventProperties,
        'bDqQ8jOJor',
        '9bb6e81cd71facc625285e32f156df3429476a90ffe1e4d45468aa2a5fbc117e'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('estimator_bag_added', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface EstimatorBagRemovedProperties {
  bagType: string;
}
/**
 * estimator_bag_removed: when a bag is removed for the estimator
 *
 * @param properties the properties associatied with this event
 * @param properties.bagType: no description
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/YHkatPbYjg}
 */
export function estimatorBagRemoved(properties: EstimatorBagRemovedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '-PevD5qa9', name: 'bag_type', value: properties.bagType });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'YHkatPbYjg',
        '3d5bd366165b1592041a553f75dc32fd11bd33325330034024f5f91de47d1948',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('estimator_bag_removed', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('YHkatPbYjg', 'estimator_bag_removed', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'estimator_bag_removed',
        eventProperties,
        'YHkatPbYjg',
        '3d5bd366165b1592041a553f75dc32fd11bd33325330034024f5f91de47d1948'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('estimator_bag_removed', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface EstimatorItemAddedProperties {
  itemName: string;
}
/**
 * estimator_item_added: when items are added on the estimator
 *
 * @param properties the properties associatied with this event
 * @param properties.itemName: the name of the shop item added by user
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/tJGb6mv92x}
 */
export function estimatorItemAdded(properties: EstimatorItemAddedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '164ZkC0JkR', name: 'item_name', value: properties.itemName });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'tJGb6mv92x',
        'c444680a97c4cd5bf5476d330fc65d9f5c85afa6f24d6e0ceea21e002891887a',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('estimator_item_added', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('tJGb6mv92x', 'estimator_item_added', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'estimator_item_added',
        eventProperties,
        'tJGb6mv92x',
        'c444680a97c4cd5bf5476d330fc65d9f5c85afa6f24d6e0ceea21e002891887a'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('estimator_item_added', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface EstimatorItemRemovedProperties {
  itemName: string;
}
/**
 * estimator_item_removed: when the item is removed from the estimator
 *
 * @param properties the properties associatied with this event
 * @param properties.itemName: the name of the shop item added by user
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/p32a3AYfT5}
 */
export function estimatorItemRemoved(properties: EstimatorItemRemovedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '164ZkC0JkR', name: 'item_name', value: properties.itemName });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'p32a3AYfT5',
        'dad26092530dcaf3ac31d83dc3c80b64b04fccf1eeacb33bb152129964a34c64',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('estimator_item_removed', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('p32a3AYfT5', 'estimator_item_removed', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'estimator_item_removed',
        eventProperties,
        'p32a3AYfT5',
        'dad26092530dcaf3ac31d83dc3c80b64b04fccf1eeacb33bb152129964a34c64'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('estimator_item_removed', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface EstimatorMoreDetailsProperties {
  screen: string;
}
/**
 * estimator_more_details: When the user clicks on more details in the price estimator
 *
 * @param properties the properties associatied with this event
 * @param properties.screen: name of page/screen on which the user did the action
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/UCO69MTnQ1}
 */
export function estimatorMoreDetails(properties: EstimatorMoreDetailsProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'UCO69MTnQ1',
        'dbe7fcf9568a6d7aabefa598dbd25229963428fa1f03c1c931da43d98bd09ea8',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('estimator_more_details', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('UCO69MTnQ1', 'estimator_more_details', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'estimator_more_details',
        eventProperties,
        'UCO69MTnQ1',
        'dbe7fcf9568a6d7aabefa598dbd25229963428fa1f03c1c931da43d98bd09ea8'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('estimator_more_details', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * order_submit: when the user clicks on place a new order.
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/JLQWiX3X-Q}
 */
export function orderSubmit() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'JLQWiX3X-Q',
        '0d61fea1955a378e0fff9301820164cda23549e0d558ec1189ed309bd8385163',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('order_submit', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('JLQWiX3X-Q', 'order_submit', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'order_submit',
        eventProperties,
        'JLQWiX3X-Q',
        '0d61fea1955a378e0fff9301820164cda23549e0d558ec1189ed309bd8385163'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('order_submit', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * view_select_service: It is triggered when the user views select service screen

 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/ysA0D_HeHg}
 */
export function viewSelectService() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'ysA0D_HeHg',
        '11ccdda6f855c94202105694379e710bf174cf9fb3e3dcfd71a473143aed296e',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('view_select_service', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('ysA0D_HeHg', 'view_select_service', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'view_select_service',
        eventProperties,
        'ysA0D_HeHg',
        '11ccdda6f855c94202105694379e710bf174cf9fb3e3dcfd71a473143aed296e'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('view_select_service', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectedServiceProperties {
  bagType: string;
}
/**
 * selected_service: To know the bag selected by the user
 *
 * @param properties the properties associatied with this event
 * @param properties.bagType: no description
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/vc0UCv_6hW}
 */
export function selectedService(properties: SelectedServiceProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '-PevD5qa9', name: 'bag_type', value: properties.bagType });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'vc0UCv_6hW',
        '20b1a870213ed83be6f8fa7c9e3ff6db1649bd36094b42bcf4be6fe55f1570d7',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('selected_service', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('vc0UCv_6hW', 'selected_service', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'selected_service',
        eventProperties,
        'vc0UCv_6hW',
        '20b1a870213ed83be6f8fa7c9e3ff6db1649bd36094b42bcf4be6fe55f1570d7'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('selected_service', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * continue_select_service: triggered when the user clicks on continue
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/HP0VegT_4B}
 */
export function continueSelectService() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'HP0VegT_4B',
        'a57592b21a8dc85e797a618c5cfce9f3d623333e4e911ef955e61345d5f20620',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('continue_select_service', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('HP0VegT_4B', 'continue_select_service', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'continue_select_service',
        eventProperties,
        'HP0VegT_4B',
        'a57592b21a8dc85e797a618c5cfce9f3d623333e4e911ef955e61345d5f20620'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('continue_select_service', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewOrderPageProperties {
  step: string;
  experiment: string;
}
/**
 * view_order_page: Whenever the stepper value changes in the order flow
 *
 * @param properties the properties associatied with this event
 * @param properties.step: no description
 * @param properties.experiment: no description
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/4szhbKzQfN}
 */
export function viewOrderPage(properties: ViewOrderPageProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'zKsqRkwg6', name: 'step', value: properties.step });
  eventPropertiesArray.push({ id: 'VOnLmqqGJ', name: 'experiment', value: properties.experiment });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        '4szhbKzQfN',
        'dd72cf51aef3f08855ef7424fb99341af90ba826ddd14a3f3a99a8b036ead8ae',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('view_order_page', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('4szhbKzQfN', 'view_order_page', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'view_order_page',
        eventProperties,
        '4szhbKzQfN',
        'dd72cf51aef3f08855ef7424fb99341af90ba826ddd14a3f3a99a8b036ead8ae'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('view_order_page', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Guided_Introduction: When The users click on how do I start button
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/dgCda_l_Oj}
 */
export function guidedIntroduction() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'dgCda_l_Oj',
        '2c6d82742fe59303afa982e5330fe332e96d3a634ded68e22086afb8903efef2',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Guided_Introduction', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('dgCda_l_Oj', 'Guided_Introduction', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Guided_Introduction',
        eventProperties,
        'dgCda_l_Oj',
        '2c6d82742fe59303afa982e5330fe332e96d3a634ded68e22086afb8903efef2'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('Guided_Introduction', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OnboardingBottomsheetViewProperties {
  screen: string;
}
/**
 * onboarding_bottomsheet_view: When the users view the onboarding bottom sheets
 *
 * @param properties the properties associatied with this event
 * @param properties.screen: name of page/screen on which the user did the action
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/B7XKjRlPOO}
 */
export function onboardingBottomsheetView(properties: OnboardingBottomsheetViewProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'B7XKjRlPOO',
        '3e4e296c6560a83dcb5bb81f41be966f47bbe249f973a9d210e42c1c401a2267',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('onboarding_bottomsheet_view', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('B7XKjRlPOO', 'onboarding_bottomsheet_view', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'onboarding_bottomsheet_view',
        eventProperties,
        'B7XKjRlPOO',
        '3e4e296c6560a83dcb5bb81f41be966f47bbe249f973a9d210e42c1c401a2267'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('onboarding_bottomsheet_view', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * FAQ_bags: When the user clicked on How do I get the bags? in the select service screen
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/lFB5OmwQ1o}
 */
export function faqBags() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'lFB5OmwQ1o',
        '3b96cd928a6083f35dd62dae6deb1c060a4b918dacc114c175ba95b282b28930',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('FAQ_bags', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('lFB5OmwQ1o', 'FAQ_bags', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'FAQ_bags',
        eventProperties,
        'lFB5OmwQ1o',
        '3b96cd928a6083f35dd62dae6deb1c060a4b918dacc114c175ba95b282b28930'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('FAQ_bags', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * FAQ_price: When the user clicks on What’s the price for each service? in the select service screen FAQ
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/qNka58rNXr}
 */
export function faqPrice() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'qNka58rNXr',
        '275478a2bc8f84f3ba7239cec377d5744e3e6b9d0c6f54f62df79044ca12fda2',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('FAQ_price', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('qNka58rNXr', 'FAQ_price', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'FAQ_price',
        eventProperties,
        'qNka58rNXr',
        '275478a2bc8f84f3ba7239cec377d5744e3e6b9d0c6f54f62df79044ca12fda2'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('FAQ_price', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Learn_more_onboarding: When the user clicks on Don’t have the bags? Learn More from the pricing screen
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/Y-3ODzsH_f}
 */
export function learnMoreOnboarding() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'Y-3ODzsH_f',
        '1bfaf3b2f0065b8195298ad27ddf8af4843d80ccb416fe1225b6a197075f35ec',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Learn_more_onboarding', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('Y-3ODzsH_f', 'Learn_more_onboarding', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Learn_more_onboarding',
        eventProperties,
        'Y-3ODzsH_f',
        '1bfaf3b2f0065b8195298ad27ddf8af4843d80ccb416fe1225b6a197075f35ec'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('Learn_more_onboarding', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PaymentMethodAddedProperties {
  paymentMethod: string;
  screen: string;
}
/**
 * payment_method_added: Users added payment method
 *
 * @param properties the properties associatied with this event
 * @param properties.paymentMethod: Apple Pay, Debit/Credit Card.. etc
 * @param properties.screen: name of page/screen on which the user did the action
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/u1o412a1CG}
 */
export function paymentMethodAdded(properties: PaymentMethodAddedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '4lorbPwsS_', name: 'payment_method', value: properties.paymentMethod });
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'u1o412a1CG',
        '0ceb6fdf45c9c51b00d2835adb7fb21970dbe2d408d50de78ef4aed3b8b657d7',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('payment_method_added', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('u1o412a1CG', 'payment_method_added', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'payment_method_added',
        eventProperties,
        'u1o412a1CG',
        '0ceb6fdf45c9c51b00d2835adb7fb21970dbe2d408d50de78ef4aed3b8b657d7'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('payment_method_added', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AddressAddedProperties {
  addressGeofence: string;
  cityName: string;
  screen: string;
}
/**
 * address_added: Users added new address
 *
 * @param properties the properties associatied with this event
 * @param properties.addressGeofence: The user's address area
 * @param properties.cityName: User's address City/Emirate
 * @param properties.screen: name of page/screen on which the user did the action
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/g_G8b9BayH}
 */
export function addressAdded(properties: AddressAddedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'QOsRbxrxbB', name: 'address_geofence', value: properties.addressGeofence });
  eventPropertiesArray.push({ id: 'NDEya8IIzy', name: 'city_name', value: properties.cityName });
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'g_G8b9BayH',
        '220f0ca9b6202f5269527f93b1e4273a2af781dbddbaed7d42e9b890017c3edf',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('address_added', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('g_G8b9BayH', 'address_added', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'address_added',
        eventProperties,
        'g_G8b9BayH',
        '220f0ca9b6202f5269527f93b1e4273a2af781dbddbaed7d42e9b890017c3edf'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('address_added', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PaymentMethodSelectedProperties {
  paymentMethod: string;
}
/**
 * payment_method_selected: Payment method selected by users
 *
 * @param properties the properties associatied with this event
 * @param properties.paymentMethod: Apple Pay, Debit/Credit Card.. etc
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/0p-u48hCKN}
 */
export function paymentMethodSelected(properties: PaymentMethodSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '4lorbPwsS_', name: 'payment_method', value: properties.paymentMethod });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        '0p-u48hCKN',
        '9965366b5c4f6299896bedf2881e9ad0e34e4ed69540361f34f810889882ab6d',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('payment_method_selected', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('0p-u48hCKN', 'payment_method_selected', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'payment_method_selected',
        eventProperties,
        '0p-u48hCKN',
        '9965366b5c4f6299896bedf2881e9ad0e34e4ed69540361f34f810889882ab6d'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('payment_method_selected', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * discovery_card_scroll: When discovery cards scroll on home page
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/kWCxz2tzWu}
 */
export function discoveryCardScroll() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'kWCxz2tzWu',
        '56b2b423a811b05254dec74da3e84bd0570a0544dcd7ae0ab9f525f5b4a53e6a',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('discovery_card_scroll', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('kWCxz2tzWu', 'discovery_card_scroll', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'discovery_card_scroll',
        eventProperties,
        'kWCxz2tzWu',
        '56b2b423a811b05254dec74da3e84bd0570a0544dcd7ae0ab9f525f5b4a53e6a'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('discovery_card_scroll', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * discovery_card_clicked: When discovery card click on home page
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/OHbqtY12OO}
 */
export function discoveryCardClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'OHbqtY12OO',
        '7c7cb33105e27cad7b766c34fe552d5003c8be7cb3a697300e200a2b9f9f7d11',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('discovery_card_clicked', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('OHbqtY12OO', 'discovery_card_clicked', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'discovery_card_clicked',
        eventProperties,
        'OHbqtY12OO',
        '7c7cb33105e27cad7b766c34fe552d5003c8be7cb3a697300e200a2b9f9f7d11'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('discovery_card_clicked', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * open_onboarding_sheet: Customers that opened onboarding sheet
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/jvKLBNQBS}
 */
export function openOnboardingSheet() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'jvKLBNQBS',
        '067e93154f17093f03c748137c5c3469c7a7214ff5ceb182147e7898fc7ec0fb',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('open_onboarding_sheet', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('jvKLBNQBS', 'open_onboarding_sheet', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'open_onboarding_sheet',
        eventProperties,
        'jvKLBNQBS',
        '067e93154f17093f03c748137c5c3469c7a7214ff5ceb182147e7898fc7ec0fb'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('open_onboarding_sheet', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CloseOnboardingSheetProperties {
  viewedAllPages: string | null | undefined;
  pageNo: string;
}
/**
 * close_onboarding_sheet: Customers closed onboarding sheet
 *
 * @param properties the properties associatied with this event
 * @param properties.viewedAllPages: if all pages are viewed by user True or False
 * @param properties.pageNo: Page No opened when bottomsheet closed
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/XyMKKAgDz}
 */
export function closeOnboardingSheet(properties: CloseOnboardingSheetProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.viewedAllPages !== undefined && properties.viewedAllPages !== null
    ? eventPropertiesArray.push({ id: '55hLmk_AG', name: 'viewed_all_pages', value: properties.viewedAllPages })
    : eventPropertiesArray.push({ id: '55hLmk_AG', name: 'viewed_all_pages', value: null });
  eventPropertiesArray.push({ id: 'aNh17yx4x', name: 'page_no', value: properties.pageNo });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'XyMKKAgDz',
        'f09646e3f4d039e6d115ada5f64ac6bcdc1fd4a99f263bd160854bb90cf2a43a',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('close_onboarding_sheet', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('XyMKKAgDz', 'close_onboarding_sheet', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'close_onboarding_sheet',
        eventProperties,
        'XyMKKAgDz',
        'f09646e3f4d039e6d115ada5f64ac6bcdc1fd4a99f263bd160854bb90cf2a43a'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('close_onboarding_sheet', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AddressSelectedProperties {
  addressGeofence: string;
  cityName: string;
  screen: string;
}
/**
 * address_selected: No description
 *
 * @param properties the properties associatied with this event
 * @param properties.addressGeofence: The user's address area
 * @param properties.cityName: User's address City/Emirate
 * @param properties.screen: name of page/screen on which the user did the action
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/blIIeL3WQPh}
 */
export function addressSelected(properties: AddressSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'QOsRbxrxbB', name: 'address_geofence', value: properties.addressGeofence });
  eventPropertiesArray.push({ id: 'NDEya8IIzy', name: 'city_name', value: properties.cityName });
  eventPropertiesArray.push({ id: 'dE8iX-3OA1', name: 'screen', value: properties.screen });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'blIIeL3WQPh',
        'a53b21c5741e69fce5b50ab0aa82bfb9d4a057b2a863fa4e936b889158e25351',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('address_selected', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('blIIeL3WQPh', 'address_selected', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'address_selected',
        eventProperties,
        'blIIeL3WQPh',
        'a53b21c5741e69fce5b50ab0aa82bfb9d4a057b2a863fa4e936b889158e25351'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('address_selected', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewedPricingPageProperties {
  service: string;
  isBottomSheet: boolean;
}
/**
 * viewed_pricing_page: When the user view pricing page
 *
 * @param properties the properties associatied with this event
 * @param properties.service: no description
 * @param properties.isBottomSheet: Full Page or opened with in a bottom sheet.
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/MaB8obTYyr}
 */
export function viewedPricingPage(properties: ViewedPricingPageProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'LPL3FUB0y', name: 'service', value: properties.service });
  eventPropertiesArray.push({ id: '3s6hxzk7u', name: 'is_bottom_sheet', value: properties.isBottomSheet });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'MaB8obTYyr',
        '0010e6860ddc01e95cf4a62345f1c0bcf22ff4bb1c6763ea711cf5ff2870cc4c',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('viewed_pricing_page', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('MaB8obTYyr', 'viewed_pricing_page', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'viewed_pricing_page',
        eventProperties,
        'MaB8obTYyr',
        '0010e6860ddc01e95cf4a62345f1c0bcf22ff4bb1c6763ea711cf5ff2870cc4c'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('viewed_pricing_page', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectServiceLineProperties {
  service: string;
}
/**
 * select_service_line: Selected ServiceLines before proceeding order. currently: (Dry Cleaning, Shoe Care)
 *
 * @param properties the properties associatied with this event
 * @param properties.service: no description
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/IhV3Z7VN57}
 */
export function selectServiceLine(properties: SelectServiceLineProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'LPL3FUB0y', name: 'service', value: properties.service });
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'IhV3Z7VN57',
        '7fb7f021645abe1952c3ab14169016697f694a614442daa847134e17635d4104',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('select_service_line', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('IhV3Z7VN57', 'select_service_line', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'select_service_line',
        eventProperties,
        'IhV3Z7VN57',
        '7fb7f021645abe1952c3ab14169016697f694a614442daa847134e17635d4104'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('select_service_line', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * download_app_banner_clicked: When user click on banner to download Washmen mobile app.
 *
 * @see {@link https://www.avo.app/schemas/qgnGqJ4tQtjQ1YP3TgXA/branches/mxbaWh9La/events/U3KdoQZ9Po}
 */
export function downloadAppBannerClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'ablOZ8x_pY', name: 'platform', value: sysPlatform });
  eventPropertiesArray.push({ id: 'kK3OWMws99', name: 'device', value: sysDevice });
  eventPropertiesArray.push({ id: 'Pb123_u5t3', name: 'app_version', value: sysAppVersion });
  eventPropertiesArray.push({ id: 'Y9UHmaYFCz', name: 'origin', value: sysOrigin });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'U3KdoQZ9Po',
        '4faf180ceb80c1b1851b255d4a89504ccf80cf0e0f87824eac6227dc35a3b23e',
        messages.map((m) =>
          Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType })
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('download_app_banner_clicked', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('U3KdoQZ9Po', 'download_app_banner_clicked', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'download_app_banner_clicked',
        eventProperties,
        'U3KdoQZ9Po',
        '4faf180ceb80c1b1851b255d4a89504ccf80cf0e0f87824eac6227dc35a3b23e'
      );
    }
    // destination Mixpanel
    Mixpanel.logEvent('download_app_banner_clicked', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  setSystemProperties,
  avoInspectorApiKey,
  signup,
  visit,
  login,
  viewPricing,
  viewProduct,
  viewProductTerms,
  viewProductProcess,
  viewSupport,
  supportCall,
  supportMessage,
  supportChat,
  viewedOrders,
  orderStarted,
  orderTypeSelected,
  schedulePickup,
  scheduleDropoff,
  orderInstructionsAdded,
  usedPromocode,
  orderPlaced,
  orderCancelled,
  pageview,
  logout,
  firstOrderPlaced,
  carouselVideos,
  viewOnboardingPage,
  visitOrders,
  addPromocode,
  estimatorBagSelected,
  estimatorCarouselVideos,
  estimatorBagAdded,
  estimatorBagRemoved,
  estimatorItemAdded,
  estimatorItemRemoved,
  estimatorMoreDetails,
  orderSubmit,
  viewSelectService,
  selectedService,
  continueSelectService,
  viewOrderPage,
  guidedIntroduction,
  onboardingBottomsheetView,
  faqBags,
  faqPrice,
  learnMoreOnboarding,
  paymentMethodAdded,
  addressAdded,
  paymentMethodSelected,
  discoveryCardScroll,
  discoveryCardClicked,
  openOnboardingSheet,
  closeOnboardingSheet,
  addressSelected,
  viewedPricingPage,
  selectServiceLine,
  downloadAppBannerClicked,
};

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["signup","visit","login","viewPricing","viewProduct","viewProductTerms","viewProductProcess","viewSupport","supportCall","supportMessage","supportChat","viewedOrders","orderStarted","orderTypeSelected","schedulePickup","scheduleDropoff","orderInstructionsAdded","usedPromocode","orderPlaced","orderCancelled","pageview","logout","firstOrderPlaced","carouselVideos","viewOnboardingPage","visitOrders","addPromocode","estimatorBagSelected","estimatorCarouselVideos","estimatorBagAdded","estimatorBagRemoved","estimatorItemAdded","estimatorItemRemoved","estimatorMoreDetails","orderSubmit","viewSelectService","selectedService","continueSelectService","viewOrderPage","guidedIntroduction","onboardingBottomsheetView","faqBags","faqPrice","learnMoreOnboarding","paymentMethodAdded","addressAdded","paymentMethodSelected","discoveryCardScroll","discoveryCardClicked","openOnboardingSheet","closeOnboardingSheet","addressSelected","viewedPricingPage","selectServiceLine","downloadAppBannerClicked"]
