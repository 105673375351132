import { ServiceLine } from 'api/types/service.types';
import { QuestionMarkIcon, RightArrowIcon } from 'assets/icons/app';
import Button from 'components/atoms/Button';
import BottomSheet from 'components/molecules/BottomSheet';
import React from 'react';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SupportSection from './SupportSection';

const StyledButton = styled(Button)`
  border-radius: 1000px;
  border: 1px solid ${({ theme }) => theme.colors.ghostWhite};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.deepPurple};
  font-weight: 500;
  flex: 1;
  font-size: 14px;
  height: unset;
  display: flex;
  gap: 10px;
  align-items: center;

  &:hover,
  &:focus,
  &:active,
  &.btn-primary:not(:disabled):not(.disabled):active {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.deepPurple};
    border: 1px solid ${({ theme }) => theme.colors.ghostWhite};
  }
`;

const StyledTitleBox = styled.div`
  flex: 1;
  text-align: left;
`;

interface SupportSheetProps {
  show: boolean;
  onClose?: () => any;
}

const StyledCol = styled(Col)`
  display: grid;
  gap: 5px;
  margin-top: 1px;
`;

const FAQ_REDIRECT_URL = 'https://www.washmen.com/app/faq';

export const SupportSheet: React.FC<SupportSheetProps> = ({ show, onClose }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const onCloseSheet = () => {
    onClose?.();
  };

  const onFaqRedirect = () => {
    window.open(FAQ_REDIRECT_URL, '_blank');
  };

  return (
    <BottomSheet hideCloseButton show={show} onClose={onClose} title={t('homePage.supportSheetTitle')}>
      <StyledCol className="padding-start-0 padding-end-0 pb-10">
        <SupportSection onCloseSheet={onCloseSheet} serviceLine={ServiceLine.LAUNDRY_SERVICE} />
        <SupportSection onCloseSheet={onCloseSheet} serviceLine={ServiceLine.SHOE_SERVICE} />

        <StyledButton onClick={onFaqRedirect}>
          <QuestionMarkIcon />
          <StyledTitleBox>FAQs</StyledTitleBox>
          <RightArrowIcon />
        </StyledButton>
      </StyledCol>
    </BottomSheet>
  );
};

export default SupportSheet;
