import { WASHMEN_CUSTOMER_API } from 'api/config';
import type { PricingList } from 'api/types/pricing.types';

export async function getPricingAPI(customerId?: string) {
  const { data } = await WASHMEN_CUSTOMER_API.get<PricingList.RootObject>('/pricings', {
    params: { isFetchShoeCare: true, customerId },
  });

  return data?.pricing;
}
