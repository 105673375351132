import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Addresses } from 'api/types/addresses.types';
import type { Cards } from 'api/types/cards.types';
import type { Preferences } from 'api/types/preferences.types';
import { ServiceLine } from 'api/types/service.types';
import type { TimeSlots } from 'api/types/timeSlot.types';
import { checkApplyPayCompatibility } from 'wrappers/apple_pay';

import { confirmApplePayMethod } from './newOrder.thunks';

interface NewOrder {
  addressId?: string;
  pickupTimeslot?: TimeSlots.Timeslot;
  isPickupAtDoor?: boolean;
  dropoffTimeslot?: TimeSlots.Timeslot;
  isDropoffAtDoor?: boolean;
  creditCardId?: string;
  applePayPayload?: any;
  preferences?: Preferences.CustomerPreferences;
  driverTip?: number;
}

interface PickupDropoffTimeSlots {
  pickupTimeslotsData?: TimeSlots.TimeslotsData;
  dropoffTimeslotsData?: TimeSlots.TimeslotsData;
}

type ServiceLineDropoffs = {
  [x in ServiceLine]?: TimeSlots.Timeslot;
};

type ServiceLineTimeSlotsData = {
  [x in ServiceLine]?: TimeSlots.TimeslotsData;
};

type ServiceLinePreferences = {
  [x in ServiceLine]?: Preferences.CustomerPreferences;
};

type ServiceLineCollectionMethods = {
  [x in ServiceLine]?: { isDropoffAtDoor?: boolean };
};

interface State {
  currentOrder: NewOrder;
  timeSlots: PickupDropoffTimeSlots;
  isUrgent: boolean;
  statuses: {
    addresses: 'idle' | 'pending';
    pickupTimeslots: 'idle' | 'pending';
    dropoffTimeslots: 'idle' | 'pending';
  };
  services: string[];
  serviceLines: ServiceLine[];
  serviceLineDropoffs: ServiceLineDropoffs;
  serviceLineCollectionMethods: ServiceLineCollectionMethods;
  serviceLineTimeSlotData: ServiceLineTimeSlotsData;
  serviceLinePreferences: ServiceLinePreferences;
}

const initialState: State = {
  currentOrder: {
    pickupTimeslot: undefined,
    dropoffTimeslot: undefined,
    isDropoffAtDoor: true,
    driverTip: 0,
  },
  isUrgent: false,
  timeSlots: {},
  services: [],
  statuses: {
    addresses: 'idle',
    pickupTimeslots: 'idle',
    dropoffTimeslots: 'idle',
  },
  serviceLines: [],
  serviceLineDropoffs: {},
  serviceLineCollectionMethods: {},
  serviceLineTimeSlotData: {},
  serviceLinePreferences: {},
};

// Define a custom comparison arrow function
const priorityServiceLine = ServiceLine.LAUNDRY_SERVICE;
const sortLaundryToFirstService = (a: ServiceLine, b: ServiceLine) => {
  if (a === priorityServiceLine && b !== priorityServiceLine) {
    return -1; // a comes before b
  }
  if (a !== priorityServiceLine && b === priorityServiceLine) {
    return 1; // b comes before a
  }
  // Otherwise, sort alphabetically
  return a.localeCompare(b);
};

const slice = createSlice({
  name: 'newOrder',
  initialState,
  reducers: {
    updateNewOrder(state, action: PayloadAction<NewOrder>) {
      const order = action.payload;
      state.currentOrder = {
        ...state.currentOrder,
        ...order,
      };
    },
    updateDropoffTimeForServiceLine(state, action: PayloadAction<TimeSlots.Timeslot & { serviceLine: ServiceLine }>) {
      const { serviceLine, ...timeslot } = action.payload;
      state.serviceLineDropoffs[serviceLine] = timeslot;
    },
    resetServiceLinesDropoffs(state) {
      state.serviceLineDropoffs = {};
    },
    updateIsDropoffAtDoor(state, action: PayloadAction<boolean>) {
      const isDropoffAtDoor = action.payload;
      state.currentOrder.isDropoffAtDoor = isDropoffAtDoor;
    },
    updateIsDropoffAtDoorForServiceLine(state, action: PayloadAction<{ isDropoffAtDoor: boolean; serviceLine: ServiceLine }>) {
      const { isDropoffAtDoor, serviceLine } = action.payload;
      state.serviceLineCollectionMethods[serviceLine] = { isDropoffAtDoor };
    },
    toggleSelectedServiceLines(state, action: PayloadAction<ServiceLine>) {
      const service = action.payload;
      if (state.serviceLines.includes(service)) {
        state.serviceLines = state.serviceLines.filter((s) => s !== service);
      } else {
        state.serviceLines.push(service);
      }

      state.serviceLines.sort(sortLaundryToFirstService);
      state.currentOrder.addressId = undefined;
      state.serviceLineCollectionMethods = {};
      state.serviceLineDropoffs = {};
    },
    setDefaultServiceLine(state) {
      if (state.serviceLines.length === 0) {
        state.serviceLines = [ServiceLine.LAUNDRY_SERVICE];
      }
    },
    updatePreferences(state, action: PayloadAction<Preferences.CustomerPreferences & { serviceLine?: ServiceLine }>) {
      const { serviceLine, ...preferences } = action.payload;
      if (serviceLine) {
        state.serviceLinePreferences[serviceLine] = preferences;
      } else {
        state.currentOrder.preferences = preferences;
      }
    },
    setDriverTip(state, action: PayloadAction<number>) {
      state.currentOrder.driverTip = action.payload;
    },
    updateOrderApplePayPayload(state, action: PayloadAction<object>) {
      state.currentOrder.applePayPayload = action.payload;
    },
    setSelectedPaymentCard(state, action: PayloadAction<string>) {
      state.currentOrder.creditCardId = action.payload;
    },
    selectAddress(state, action: PayloadAction<string>) {
      const addressId = action.payload;
      state.currentOrder.addressId = addressId;
      state.currentOrder.pickupTimeslot = undefined;
      state.currentOrder.dropoffTimeslot = undefined;
      state.serviceLineDropoffs = {};
      state.serviceLineCollectionMethods = {};
    },

    addressAdded(state, action: PayloadAction<{ address: Addresses.Address }>) {
      state.currentOrder.addressId = action.payload.address.id;
      state.currentOrder.pickupTimeslot = undefined;
      state.currentOrder.dropoffTimeslot = undefined;
      state.serviceLineDropoffs = {};
      state.serviceLineCollectionMethods = {};
    },
    clearTimeslots(state) {
      state.currentOrder.pickupTimeslot = undefined;
      state.currentOrder.dropoffTimeslot = undefined;
      state.serviceLineDropoffs = {};
      state.serviceLineCollectionMethods = {};
    },
    clearState(state) {
      state.currentOrder = initialState.currentOrder;
      state.timeSlots = initialState.timeSlots;
      state.statuses = initialState.statuses;
      state.serviceLineCollectionMethods = {};
      state.serviceLineDropoffs = {};
      state.services = [];
    },
    setPickupTime(state, action: PayloadAction<TimeSlots.PickupRootObject>) {
      const { pickupTimeslotsList: pickupTimeslotsData } = action.payload;

      state.timeSlots.pickupTimeslotsData = pickupTimeslotsData;
      state.currentOrder.pickupTimeslot = undefined;
      state.currentOrder.isPickupAtDoor = pickupTimeslotsData.collectionMethod.isAtDoorByDefault;

      // For non-first time customers, set inital pickup timeslots
      if (pickupTimeslotsData.presetSlotObject) {
        const slot = pickupTimeslotsData.presetSlotObject;
        if (slot) {
          state.currentOrder.pickupTimeslot = {
            date: pickupTimeslotsData.presetDate,
            ...slot,
          };
        }
      }
    },
    setDropOffTime(state, action: PayloadAction<TimeSlots.DropoffRootObject>) {
      const { dropoffTimeslotsList: dropoffTimeslotsData, isFirstOrder } = action.payload;

      state.timeSlots.dropoffTimeslotsData = dropoffTimeslotsData;
      state.currentOrder.isDropoffAtDoor = dropoffTimeslotsData.collectionMethod.isAtDoorByDefault;

      const selectedDropoffTimeslotDate = state.currentOrder.dropoffTimeslot?.date || '';

      // Keep selectedDropoffTimesplot as is, if still in range and non urgent, else reset selectedDropoffTimesplot
      if (selectedDropoffTimeslotDate && dropoffTimeslotsData.dates) {
        const previouslySelectedDay = dropoffTimeslotsData.dates.find((d) => selectedDropoffTimeslotDate && d.date === selectedDropoffTimeslotDate);
        const isPreviouslySelectedDayStillInRange = !!previouslySelectedDay;
        const isPreviouslySelectedDayNowUrgent = isPreviouslySelectedDayStillInRange && previouslySelectedDay?.isUrgentDay;
        if (!isPreviouslySelectedDayStillInRange || isPreviouslySelectedDayNowUrgent) {
          state.currentOrder.dropoffTimeslot = undefined;
        }
      }

      // For non-first time customers, set inital dropoff timeslots
      if (dropoffTimeslotsData.presetSlotObject && !selectedDropoffTimeslotDate) {
        const slot = dropoffTimeslotsData.presetSlotObject;
        if (slot) {
          state.currentOrder.dropoffTimeslot = {
            date: dropoffTimeslotsData.presetDate,
            ...slot,
          };
        }
        return;
      }

      // For first time customers, reset selected dropoff timeslot
      if (isFirstOrder) {
        state.currentOrder.dropoffTimeslot = undefined;
      }
    },
    setDropOffTimeForServiceLine(state, action: PayloadAction<TimeSlots.DropoffRootObject & { serviceLine: ServiceLine }>) {
      const { dropoffTimeslotsList: dropoffTimeslotsData, isFirstOrder, serviceLine } = action.payload;

      state.serviceLineTimeSlotData[serviceLine] = dropoffTimeslotsData;
      state.serviceLineCollectionMethods[serviceLine] = {
        isDropoffAtDoor: dropoffTimeslotsData.collectionMethod.isAtDoorByDefault,
      };

      const selectedDropoffTimeslotDate = state.serviceLineDropoffs[serviceLine]?.date || '';

      // Keep selectedDropoffTimesplot as is, if still in range and non urgent, else reset selectedDropoffTimesplot
      if (selectedDropoffTimeslotDate && dropoffTimeslotsData.dates) {
        const previouslySelectedDay = dropoffTimeslotsData.dates.find((d) => selectedDropoffTimeslotDate && d.date === selectedDropoffTimeslotDate);
        const isPreviouslySelectedDayStillInRange = !!previouslySelectedDay;
        const isPreviouslySelectedDayNowUrgent = isPreviouslySelectedDayStillInRange && previouslySelectedDay?.isUrgentDay;
        if (!isPreviouslySelectedDayStillInRange || isPreviouslySelectedDayNowUrgent) {
          state.currentOrder.dropoffTimeslot = undefined;
        }
      }

      // For non-first time customers, set inital dropoff timeslots
      if (dropoffTimeslotsData.presetSlotObject && !selectedDropoffTimeslotDate) {
        const slot = dropoffTimeslotsData.presetSlotObject;
        if (slot) {
          state.serviceLineDropoffs[serviceLine] = {
            date: dropoffTimeslotsData.presetDate,
            ...slot,
          };
        }
        return;
      }

      // For first time customers, reset selected dropoff timeslot
      if (isFirstOrder) {
        state.serviceLineDropoffs[serviceLine] = undefined;
      }
    },
    setPreferences(state, action: PayloadAction<Preferences.CustomerPreferences>) {
      state.currentOrder.preferences = action.payload;
    },
    setPreferencesForServiceLines(state, action: PayloadAction<Preferences.CustomerPreferences>) {
      const preferences = action.payload;

      state.serviceLinePreferences = {
        [ServiceLine.LAUNDRY_SERVICE]: preferences,
        [ServiceLine.FINERY]: preferences,
        [ServiceLine.SHOE_SERVICE]: preferences,
        [ServiceLine.WASH_BAG]: preferences,
      };
    },
    setPaymentCardInfo(
      state,
      action: PayloadAction<{
        cards: Cards.Card[];
        lastSelectedPaymentMethod: 'APPLE_PAY' | 'CARD' | undefined;
        lastSelectedCardId: string | undefined;
      }>
    ) {
      const { cards, lastSelectedPaymentMethod, lastSelectedCardId } = action.payload;

      if (lastSelectedPaymentMethod !== 'APPLE_PAY' || checkApplyPayCompatibility() === false) {
        // validate that existing cards contain last selected card
        const currentCard = cards.find((card) => card.id === lastSelectedCardId);
        if (currentCard) {
          state.currentOrder.creditCardId = lastSelectedCardId;
        } else {
          state.currentOrder.creditCardId = cards[0]?.id;
        }
      }
    },
    setServices(state, action: PayloadAction<string[]>) {
      state.services = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(confirmApplePayMethod, (state) => {
      // clear credit card id
      state.currentOrder.creditCardId = undefined;
    });
  },
});

export const {
  updateNewOrder,
  updatePreferences,
  clearState,
  setDriverTip,
  setSelectedPaymentCard,
  clearTimeslots,
  updateOrderApplePayPayload,
  selectAddress,
  addressAdded,
  updateIsDropoffAtDoor,
  updateIsDropoffAtDoorForServiceLine,
  setPickupTime,
  setDropOffTime,
  setDropOffTimeForServiceLine,
  updateDropoffTimeForServiceLine,
  resetServiceLinesDropoffs,
  setPreferences,
  setPreferencesForServiceLines,
  setPaymentCardInfo,
  toggleSelectedServiceLines,
  setDefaultServiceLine,
  setServices,
} = slice.actions;

export default slice.reducer;
