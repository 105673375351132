import type { INTEGRATION_PARTNERS, PartnerComponents, PartnerIcon } from 'config/types';
import type { FC } from 'react';
import store from 'redux-stores/store';
import type { PreloadableComponent } from 'utils/bundle/bundle.types';

// const partnerId = process.env.REACT_APP_PARTNER_ID as INTEGRATION_PARTNERS;

export const getPartnerSpecificComponent = <T>(components: PartnerComponents<T> | PartnerIcon<T>) => {
  // we are setting partner in initial load
  // so we can safely use it here
  const { auth } = store.getState();
  const partnerId = process.env.REACT_APP_PARTNER_ID ?? auth.partnerId;
  const component = components[partnerId as INTEGRATION_PARTNERS] ?? components.Default;
  if ((component as PreloadableComponent<T>).preload) (component as PreloadableComponent<T>).preload();

  return component as FC<T>;
};
