import { combineReducers } from '@reduxjs/toolkit';
import newOrderReducer from 'pages/newOrder/newOrder.slice';

import authReducer from '../slices/authSlice';
// reducers
import uiReducer from '../slices/uiSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  newOrder: newOrderReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
