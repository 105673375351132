/* eslint-disable default-case */
import type { Store } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { logout, refreshTokenAction } from 'redux-stores/slices/authSlice';
import { notifyErrorObject } from 'wrappers/reporting';

import { WASHMEN_CUSTOMER_API } from './config';

// ERROR CODES
const SESSION_EXPIRED = 403.2;
const INVALID_AUTH_TOKEN = 403.1;
const UNAUTHORIZED_ERROR = 403;
const USER_INACTIVE = 403.4;

interface WashmenError {
  response: {
    data: {
      infoCode: {
        code: number;
        message: string;
      };
    };
  };
}

// TODO: properly support ts type of store;

const Interceptor = (Store: Store<any, any>) => {
  WASHMEN_CUSTOMER_API.interceptors.request.use(
    (conf) =>
      // you can add some information before send it.
      // conf.headers['Auth'] = 'some token'
      conf,
    (error) => Promise.reject(error)
  );
  WASHMEN_CUSTOMER_API.interceptors.response.use(
    (next) => Promise.resolve(next),
    (error: AxiosError & WashmenError) => {
      // You can handle error here and trigger warning message without get in the code inside
      if (error.isAxiosError) {
        switch (error.response?.status) {
          case UNAUTHORIZED_ERROR: {
            switch (error.response.data?.infoCode?.code) {
              case SESSION_EXPIRED: {
                Store.dispatch(refreshTokenAction());
                notifyErrorObject({
                  ...error,
                  metadata: {
                    message: 'Session expired',
                    url: error?.config?.url,
                  },
                });
                break;
              }
              case INVALID_AUTH_TOKEN: {
                Store.dispatch(logout());
                notifyErrorObject({
                  ...error,
                  metadata: {
                    message: 'Invalid auth token',
                    url: error?.config?.url,
                  },
                });
                break;
              }
              case USER_INACTIVE: {
                toast.dark('Inactive user. Please login again');
                Store.dispatch(logout());
                notifyErrorObject({
                  ...error,
                  metadata: {
                    message: 'Inactive user',
                    url: error?.config?.url,
                  },
                });
                break;
              }
              default: {
                /* empty */
              }
            }
          }
        }
      }
      return Promise.reject(error);
    }
  );
};
export default Interceptor;
