import { setLanguageTranslationsBasedOnPartnerId } from 'i18n/config';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPartner } from 'redux-stores/slices/authSlice';

/**
 *
 * @returns ok
 */
export function useDynamicLanguage() {
  const partner = process.env.REACT_APP_PARTNER_ID ?? useSelector(selectPartner); // get partner from store;

  useEffect(() => {
    if (!partner) {
      return;
    }

    // update language json files based on partner
    setLanguageTranslationsBasedOnPartnerId(partner.toLowerCase());
  }, [partner]);
}
