import type { AppTheme } from './types';

const colors: AppTheme.Colors = {
  transparent: 'transparent',
  lineGreen: '#00E784',
  darkGreen: '#43AE3E',
  paleJade: '#D2EED7',
  staleBlue: '#889DB4',
  lightMint: '#D2FFD5',
  vermilion: '#F7430A',
  goldenRod: '#ffc042',
  whiteSmoke: '#fcfcfc',
  charcoal: '#2D2E2E',
  steelBlue: '#355273',
  ghostWhite: '#f2f3f8',
  lightGray: '#D9D9D9',
  cadetBlue: '#b6bcd1',
  darkMidnightBlue: '#111135',
  lightBlueGray: '#f4f8fb',
  darkForestGreen: '#001411',
  brightGreen: '#00A368',
  paleLightGray: '#eaedf1',
  crimson: '#C83C3E',
  crimsonDark: '#C83C3E',
  white: '#FFFFFF',
  royalBlue: '#3e48a6',
  periwinkle: '#e8eaff',
  turquoise: '#00b8ab',
  darkStaleGray: '#585871',
  midnightBlue: '#141929',
  deepPurple: '#291482',
  deepRoyalBlue: '#414C9A',
  sapphireBlue: '#414B9F',
  vividGreen: '#00AC0F',
  mintGreen: '#ebfbf9',
  palePeach: '#fef2df',
  lightCoral: '#fdd9ce',
  gray500: '#f2f4f7',
  black: '#000000',
  paleBlue: '#ebecf2',
  veryLightGray: '#f3f3f3',
  paleGrey: '#ecebeb',
  blueGray: '#e9edf1',
  slateGray: '#F2F2F7',
  paleMintDim: '#E5FDF3',
  lightCoralDim: '#FDD9CE',
  palePeachDim: '#FDE8D0',
  palePeachDark: '#ff7400',
  lightBlueGrayDim: '#F4F8FB',
  pink: '#DC2B5B',
  lightPink: '#FFF0F4',
  lightBlueshGray: '#9BA5C0',
  slateGray500: '#808080',
  lightGreen500: '#E9FBE1',
  lightGrey: '#C3C8DB',
  warningYellow: '#F78C16',
  grey14: '#242424',
  darkSlateGray: '#E5E5EA',
  shadeGray: '#606060',
  pink500: '#DD2A5B',
  grey500: '#C4C4C4',
  shadeBlueGray: '#939CB4',
  shadeLightGray: '#EDEDED',
  pastelGreen: '#e2ffe7',
  mutedGrayShade: '#d1d1d6',
  dimLightGrayColor: '#d9d9d94d',
  mediumGray: '#979797',
  mediumGray100: '#c7c7c7',
  pink100: '#FBEEF2',
  grey100: '#5D758F',
  mediumGreen: '#00AC6A',
  mediumGreen100: '#008A5B',
  darkGreen100: '#00654A',
  darkerGreen100: '#00493E',
  shadedGrayInactive: '#CFD8E1',
  white100: '#F4F6F9',
  periwinkleBlue: '#E1DEFB',
  pastelLime: '#F3FFDD',
  lightDarkGray: '#EFEFF4',
  paleYellow: '#FEF2DF',
  paleSpringGreen: '#E8F6EB',
  borderLightGray: '#dee2e6',
  lightShadeBeige: '#fae9d3',
  lightApricot: '#e9923a',
  extraLightShadeGray: '#fafbfc',
  aquaLight: '#E5FFFE',
  aquaDark: '#02FFF7',
  limeGreen: '#A4FF00',
  darkOrange: '#FF7D19',
  veryLightGrayShade: '#FBFBFB',
  verylightGray: '#F5F5F5',
  borderVeryLightGray: '#F3F4F8',
};

export default colors;
