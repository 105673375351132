import React, { type ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  title: string;
  message: string;
  onConfirm(): any;
  onDismiss?(): any;
}

const ConfirmationDialog: React.FC<Props> = ({ open, title, message, onConfirm, onDismiss }) => {
  const { t } = useTranslation();
  return (
    <Modal show={open} onHide={onDismiss} centered>
      <Modal.Body>
        <div className="d-flex justify-content-center flex-column text-center">
          <h3>{title}</h3>
          {message && <p className="h4 font-weight-light">{message}</p>}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0 pt-0 justify-content-center">
        <Button variant="secondary" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {t('common.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

interface Options {
  title: string;
  message: string;
  actionCallback(): any;
}
interface ConfirmationContext {
  openDialog(options: Options): any;
}

const ConfirmationDialogContext = React.createContext<ConfirmationContext>({
  openDialog: () => {},
});

const ConfirmationDialogProvider: React.FC<{ children?: ReactNode }> = ({ children, ...restProps }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState<any>({});

  const openDialog = ({ title, message, actionCallback }: Options) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const contextValue = React.useMemo(() => ({ openDialog }), [openDialog]);

  return (
    <ConfirmationDialogContext.Provider value={contextValue}>
      <ConfirmationDialog {...restProps} open={dialogOpen} title={dialogConfig?.title} message={dialogConfig?.message} onConfirm={onConfirm} />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  const { openDialog } = React.useContext(ConfirmationDialogContext);

  const getConfirmation: (options: any) => Promise<unknown> = ({ ...options }) =>
    new Promise((res) => {
      openDialog({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};

export default ConfirmationDialog;
export { ConfirmationDialogProvider, useConfirmationDialog };
