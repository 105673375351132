import { useMutation } from '@tanstack/react-query';
import { updateIntercomIdAPI } from 'api/intercom';
import { useDispatch } from 'react-redux';
import { setUpdateIntercomIdentified } from 'redux-stores/slices/authSlice';

export function useUpdateIntercomID() {
  const dispatch = useDispatch();
  return useMutation(updateIntercomIdAPI, {
    onSuccess: () => {
      dispatch(setUpdateIntercomIdentified());
    },
  });
}
