type FacebookEventMapperObject = {
  event: string;
  type: 'track' | 'trackCustom';
  isSendArguments: boolean;
};

export const FacebookEventMapper = new Map<string, FacebookEventMapperObject>([
  // this event is when order is succesfully sent to backend.
  [
    'order_placed',
    {
      event: 'Purchase',
      type: 'track',
      isSendArguments: true,
    },
  ],
  [
    'first_order_placed',
    {
      event: 'FirstTimePurchase',
      type: 'trackCustom',
      isSendArguments: true,
    },
  ],
  // this event is when order is submitted to API.
  [
    'order_submit',
    {
      event: 'OrderSubmitted',
      type: 'trackCustom',
      isSendArguments: true,
    },
  ],
  [
    'order_started',
    {
      event: 'InitiateCheckout',
      type: 'track',
      isSendArguments: true,
    },
  ],
  [
    'schedule_pickup',
    {
      event: 'SchedulePickup',
      type: 'trackCustom',
      isSendArguments: true,
    },
  ],
  [
    'schedule_dropoff',
    {
      event: 'ScheduleDropoff',
      type: 'trackCustom',
      isSendArguments: true,
    },
  ],
  [
    'address_selected',
    {
      event: 'AddressSelected',
      type: 'trackCustom',
      isSendArguments: false,
    },
  ],
  [
    'address_added',
    {
      event: 'AddressAdded',
      type: 'trackCustom',
      isSendArguments: false,
    },
  ],
]);

export const sendEventToFacebook = (eventName: string, eventProperties: {}) => {
  const eventMapper = FacebookEventMapper.get(eventName);
  if (!eventMapper) {
    return;
  }

  try {
    if (eventMapper.isSendArguments) {
      (window as any)?.fbq?.(eventMapper.type, eventMapper.event, eventProperties);
    } else {
      (window as any)?.fbq?.(eventMapper.type, eventMapper.event);
    }
  } catch (error) {
    reportError(error);
  }
};
