import type { Order } from 'api/types/order.types';
import OrdersList from 'components/molecules/OrdersList';
import React from 'react';
import { useTranslation } from 'react-i18next';

import OrderCard from './order-card';

interface OrdersProps {
  orders: Order.Order[];
  onOrderSelected?: (order: Order.Order) => void;
  isLoading?: boolean;
}

const Orders = ({ orders, onOrderSelected: handleOrderSelection, isLoading }: OrdersProps) => {
  const { t } = useTranslation();

  return (
    <OrdersList orders={orders} isInitialLoading={isLoading} emptyMessage={t('ordersPage.noActiveOrders')}>
      {(order, index) => <OrderCard key={order.id} order={order} onPressItem={handleOrderSelection} isLastItem={index === orders.length - 1} />}
    </OrdersList>
  );
};

export default Orders;
