import { useSelector } from 'react-redux';
import { selectIsFirstTimeCustomer } from 'redux-stores/slices/authSlice';

// TODO: we can investigate this logic

// const isDev = process.env.NODE_ENV === 'development';

export default function useFlags() {
  const isFirstTime = useSelector(selectIsFirstTimeCustomer);

  return {
    enableSelectServicesFlow: isFirstTime,
    // enableSelectServicesFlow: isDev ? true : isFirstTime,
  };
}
