import type { PricingList } from 'api/types/pricing.types';
import BottomSheet from 'components/molecules/BottomSheet';
import { ServiceLoaderVertical } from 'components/molecules/Loaders';
import { useBagServices } from 'hooks/useBagServices';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ServiceBox from './serviceBox';

interface PricingSheetProps {
  show: boolean;
  onClose?: () => any;
  onSelectService?: (service: PricingList.ServiceTypes) => any;
}

const StyledCol = styled(Col)`
  margin-top: 1px;
`;

const PricingSheet: React.FC<PricingSheetProps> = ({ show, onClose, onSelectService }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { services, isLoading } = useBagServices();

  return (
    <BottomSheet hideCloseButton show={show} onClose={onClose} title={t('homePage.pricingSheetTitle')}>
      <StyledCol className="padding-start-0 padding-end-0">
        {isLoading ? (
          <ServiceLoaderVertical />
        ) : (
          services.map(([service, serviceDetails]) => (
            <Row key={service} noGutters xs={6} className="d-flex justify-content-start">
              <ServiceBox
                onSelectService={onSelectService}
                onClose={onClose}
                type={service as PricingList.ServiceTypes}
                serviceDetails={serviceDetails}
              />
            </Row>
          ))
        )}
      </StyledCol>
    </BottomSheet>
  );
};

export default PricingSheet;
