import { useQueryClient } from '@tanstack/react-query';
import type { Order } from 'api/types/order.types';
import type { TimeSlots } from 'api/types/timeSlot.types';
import type { SelectedSlots } from 'packages/shared/newOrder/hooks/useTimeSlotSelector';
import { REACT_QUERY_KEYS } from 'pages/queries';
import { useUpdateCurrentOrder, useUpdateDropoff, useUpdatePickup } from 'pages/queries/order.queries';
import { useDropOffTimeSlots, usePickupTimeSlots } from 'pages/queries/timeslot.queries';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export function useOrderDetailsPickupAndDropoff(order: Order.Order, action: 'PICKUP' | 'DROPOFF', orderTaskId?: string) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id, pickupTimeslot, isPickupAtDoor, isDropoffAtDoor, dropoffTimeslot, address } = order;
  const { mutateAsync: updateCurrentOrderPickup, status: pickupStatus } = useUpdatePickup({
    orderId: id,
  });
  const { mutateAsync: updateCurrentOrder, status: updateOrderStatus } = useUpdateCurrentOrder(id);
  const { date: pickupDate, slotId: pickupSlotId } = pickupTimeslot || {};
  const { date: dropoffDate, slotId: dropoffSlotId } = dropoffTimeslot || {};
  const { data: pickupTimeslotsData } = usePickupTimeSlots({
    addressId: address.id,
  });

  const { data: dropoffTimeslotsData } = useDropOffTimeSlots({
    addressId: address.id,
    orderId: order.id,
    pickupDate,
  });

  const { mutateAsync: updateActiveOrderDropOff, status: updateDropoffStatus } = useUpdateDropoff({
    orderId: id,
  });

  const timeslotsData = action === 'PICKUP' ? pickupTimeslotsData?.pickupTimeslotsList : dropoffTimeslotsData?.dropoffTimeslotsList;
  const selectedDateTimeSlot = action === 'PICKUP' ? pickupTimeslot : dropoffTimeslot;
  const isCollectionMethodAtDoor = action === 'PICKUP' ? isPickupAtDoor : isDropoffAtDoor;

  const handleTimeslotSelected = async (selectedAction: string, timeslot: TimeSlots.Timeslot) => {
    if (selectedAction === 'PICKUP') {
      updateCurrentOrderPickup({
        pickupDate: timeslot.date || '',
        pickupTime: timeslot.slotId,
        pickupTimeslotMode: timeslot.timeslotMode,
      }).then((result) => {
        if (result?.order) {
          const {
            pickupTimeslot: { durationText: assignedPickupTime },
            isPickupAutoSelected = false,
          } = result.order;
          if (isPickupAutoSelected) {
            const oldPickupTime =
              // 60 minutes timeslot
              timeslot?.slotTextAppendedBetween ??
              // 90/120 minutes timeslot (toLowercase because message starts with caps)
              timeslot?.pickupConfirmationTime?.toLowerCase();
            toast.dark(t('common.changedPickupTimeMessage', { oldPickupTime, assignedPickupTime }));
          }
        }

        queryClient.invalidateQueries([REACT_QUERY_KEYS.timeslotKeys.DROPOFF_TIMESLOT, pickupDate, address.id, id]);
      });
    } else {
      await updateActiveOrderDropOff({
        dropoffDate: timeslot.date || '',
        dropoffTime: timeslot.slotId,
        dropoffTimeslotMode: timeslot.timeslotMode,
      });
    }
  };

  const handleCollectionMethodSelected = async (selectedAction: string, collectionMethod: string) => {
    await updateCurrentOrder({
      ...(selectedAction === 'PICKUP' && {
        isPickupAtDoor: collectionMethod === 'AT_DOOR',
        orderTaskId,
      }),
      ...(selectedAction === 'DROPOFF' && {
        isDropoffAtDoor: collectionMethod === 'AT_DOOR',
        orderTaskId,
      }),
    });
  };

  const preSelectedSlots: SelectedSlots = {
    dateSlotId: action === 'PICKUP' ? pickupDate || '' : dropoffDate || '',
    timeSlotId: action === 'PICKUP' ? pickupSlotId || '' : dropoffSlotId || '',
  };

  return {
    timeslotsData,
    selectedDateTimeSlot,
    isCollectionMethodAtDoor,
    handleTimeslotSelected,
    handleCollectionMethodSelected,
    preSelectedSlots,
    pickupStatus,
    isLoading: pickupStatus === 'loading' || updateOrderStatus === 'loading' || updateDropoffStatus === 'loading',
  };
}
