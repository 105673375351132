import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.h2<{ color?: string }>`
  color: ${({ theme, color }) => color ?? theme.text.color};
  font-weight: 600;
  font-size: 24px;
`;

interface Props {
  children?: React.ReactNode;
  color?: string;
}

const Title: React.FC<Props> = ({ children, ...props }) => <StyledTitle {...props}>{children}</StyledTitle>;

export default Title;
