import { INTEGRATION_PARTNERS } from 'config/types';
import React, { Children, createElement, useCallback, useRef } from 'react';
import Div100vh from 'react-div-100vh';
import { useSelector } from 'react-redux';
import { selectPartner } from 'redux-stores/slices/authSlice';
import { onBlurScrollToTop } from 'utils/helpers/extras';

export default function useCreateAnimatableRoutes() {
  const timeoutRefs = useRef<NodeJS.Timeout>();
  const partnerId = useSelector(selectPartner);

  const handleCareemFooterFocus = useCallback(
    (target: EventTarget & HTMLElement, isSticky: boolean) => {
      if (partnerId === INTEGRATION_PARTNERS.CAREEM && target.tagName === 'INPUT') {
        const footer = document.querySelector('footer');

        if (footer) {
          if (isSticky) {
            footer.classList.add('fixed-bottom');
          } else {
            footer.classList.remove('fixed-bottom');
          }
        }
      }
    },
    [partnerId]
  );

  // **
  // ** If user clicked on next element right after,
  // ** we don't want to trigger the scroll to top event.
  // **
  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLElement>) => {
      const { target } = e;
      handleCareemFooterFocus(target, false);

      // Give browser time to focus the next element
      requestAnimationFrame(() => {
        // Check if the new focused element is a child of the original container
        if (target.tagName === 'INPUT') {
          if (timeoutRefs.current) {
            clearTimeout(timeoutRefs.current);
          }
        }
      });
    },
    [handleCareemFooterFocus]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLElement>) => {
      const { target } = e;

      if (timeoutRefs.current) {
        clearTimeout(timeoutRefs.current);
      }

      // Give browser time to focus the next element
      requestAnimationFrame(() => {
        handleCareemFooterFocus(target, true);
        // Check if the new focused element is a child of the original container
        if (!target.contains(document.activeElement as Node)) {
          if (target.tagName === 'INPUT') {
            timeoutRefs.current = setTimeout(() => {
              onBlurScrollToTop();
            }, 300);
          }
        }
      });
    },
    [handleCareemFooterFocus]
  );

  const createAnimatableRoutes = useCallback(
    (children: React.ReactNode[]) =>
      Children.map(children, (child) => {
        if (!child) {
          return child;
        }
        // @ts-ignore
        const { render, Component, ...restProps } = child.props;
        if (!render && !Component) {
          return child;
        }

        const element = render ? render() : createElement(Component);
        if (element.props.replace === true) return child;

        const newRender = () => (
          <Div100vh onFocus={handleFocus} onBlur={handleBlur} className="text-start page">
            {element}
          </Div100vh>
        );

        // @ts-ignore
        return { ...child, props: { ...restProps, Component: newRender } };
      }),
    [handleBlur, handleFocus]
  );

  return {
    createAnimatableRoutes,
  };
}
