import { useEffect, useState } from 'react';

export function useDynamicPageLoader(pageName: string) {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    async function loadPage() {
      await import(`pages/${pageName}`);
      setLoaded(true);
    }
    loadPage();
  }, [pageName]);

  return { loaded };
}
