import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getBrowserLanguage } from 'utils/browserLanguage';

import { languageCodes, languages, resources } from './constants';

// Function to load translations dynamically based on language and partnerId
const loadTranslations = async (partnerId: string) => {
  try {
    const loadLanguage = async (language: string) => {
      const resource = await import(`./locales/${partnerId}/${language}.${partnerId}.json`);

      return {
        [language]: resource.default,
      };
    };

    const translations = await Promise.all(languages.map((language) => loadLanguage(language.code)));

    return translations.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  } catch (error) {
    return [{}, {}]; // Return an array of empty objects if translation file not found
  }
};

// Function to set translations based on partnerId
export const setLanguageTranslationsBasedOnPartnerId = async (partnerId: string) => {
  const translations = await loadTranslations(partnerId);

  Object.entries(translations).forEach(([key, value]) => {
    i18n.addResourceBundle(key, 'translation', value ?? {}, true, true);
  });
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getBrowserLanguage(),
    fallbackLng: languageCodes,
    preload: [getBrowserLanguage()],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
