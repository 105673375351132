import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getClientMediaAPI } from 'api/integrationAPI';
import { LogoIconFile } from 'assets/icons/';
import BannerImageFile from 'assets/images/home-background-washmen.png';
// @ts-ignore
import HomeVideoFile from 'assets/videos/washmen-experience.mp4';
import type { LanguageCodes } from 'i18n/constants';
import type { RootState } from 'redux-stores/reducers/rootReducer';
import { getBrowserLanguage } from 'utils/browserLanguage';

export const getClientMedia = createAsyncThunk('core/getClientMedia', async (payload: string) => getClientMediaAPI(payload));

interface UIState {
  activeStep: number;
  sheetsCounter: number;
  logoImage: string;
  loading: boolean;
  appLanguage: LanguageCodes;
  targetTheme: string;
  bannerImage: string;
  homeVideo: string;
}

const initialState: UIState = {
  activeStep: 0,
  sheetsCounter: 0,
  logoImage: '',
  loading: false,
  appLanguage: (localStorage.getItem('language') as LanguageCodes) || getBrowserLanguage(),
  targetTheme: '',
  bannerImage: '',
  homeVideo: '',
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setActiveStep(state, action: PayloadAction<number>) {
      state.activeStep = action.payload;
    },
    sheetOpened(state) {
      state.sheetsCounter += 1;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    sheetClosed(state) {
      if (state.sheetsCounter === 0) return;
      state.sheetsCounter -= 1;
    },
    setDefaultMedia(state) {
      state.logoImage = LogoIconFile;
      state.bannerImage = BannerImageFile;
      state.homeVideo = HomeVideoFile;
    },
    setAppLanguage(state, action: PayloadAction<LanguageCodes>) {
      state.appLanguage = action.payload;
    },
    setTargetTheme(state, action: PayloadAction<string>) {
      state.targetTheme = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientMedia.fulfilled, (state, action) => {
        const { logo = LogoIconFile, header = BannerImageFile, video = HomeVideoFile } = action.payload.clientMedia;
        state.logoImage = logo;
        state.bannerImage = header;
        state.homeVideo = video;
      })
      .addCase(getClientMedia.rejected, (state) => {
        state.logoImage = LogoIconFile;
      });
  },
});

export const { setActiveStep, sheetClosed, setLoading, sheetOpened, setDefaultMedia, setAppLanguage, setTargetTheme } = slice.actions;

export const selectActiveStep = (state: RootState) => state.ui.activeStep;

export const selectLoading = (state: RootState) => state.ui.loading;

export const selectSheetsCounter = (state: RootState) => state.ui.sheetsCounter;

export const selectLogoImage = (state: RootState) => state.ui.logoImage;
export const selectTargetTheme = (state: RootState) => state.ui.targetTheme;

export const selectAppLanguage = (state: RootState) => state.auth.customer?.language || state.ui.appLanguage;
export const selectBannerImage = (state: RootState) => state.ui.bannerImage;
export const selectHomeVideo = (state: RootState) => state.ui.homeVideo;

export const selectAppliedCoupon = (state: RootState) => state.auth.customer?.appliedPromocodes?.length;

export default slice.reducer;
