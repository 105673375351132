import type { PricingList } from 'api/types/pricing.types';
import { useConfig } from 'context/app-config';
import { usePricing } from 'pages/queries/pricing.queries';
import { useMemo } from 'react';

export function useBagServices() {
  const config = useConfig();
  const { data, isLoading } = usePricing();

  const services: [PricingList.ServiceTypes, PricingList.CombinedService][] = useMemo(() => {
    if (!data?.services) return [];

    const servicesMap = new Map<PricingList.ServiceTypes, PricingList.CombinedService | undefined>();
    // sort services custom
    servicesMap.set('CLEAN_PRESS', data?.services.CLEAN_PRESS);
    servicesMap.set('PRESS', data?.services.PRESS);
    servicesMap.set('HOME_CARE', data?.services.HOME_CARE);
    servicesMap.set('WASH_FOLD', data?.services.WASH_FOLD);
    servicesMap.set('SHOE_CLEANING', data?.services.SHOE_CLEANING);
    servicesMap.set('THE_FINERY', data?.services.THE_FINERY);
    return Array.from(servicesMap, ([type, service]) => [type, service]) as [PricingList.ServiceTypes, PricingList.CombinedService][];
  }, [data?.services]);

  if (config && config.excludeBags) {
    const fileredServices = services.filter(([service]) => {
      if (config.excludeBags!.includes(service)) return false;
      return true;
    });

    return {
      services: fileredServices,
      isLoading,
    };
  }

  return {
    services,
    isLoading,
  };
}
