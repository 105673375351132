import { createSelector } from '@reduxjs/toolkit';
import type { Order } from 'api/types/order.types';
import type { Preferences } from 'api/types/preferences.types';
import { ServiceLine } from 'api/types/service.types';
import type { TimeSlots } from 'api/types/timeSlot.types';
import type { RootState } from 'redux-stores/reducers/rootReducer';
import { hasApplePayPayment } from 'redux-stores/slices/authSlice';

// helper functions used to generate new order creation data
const getPreferences = (preference?: Preferences.Preference) => {
  if (!preference) return '';
  const { categories } = preference;
  return categories.reduce((accu, current) => [...accu, ...current.options.filter((i) => i.value).map((i) => i.key)], [] as string[]).join(',');
};

const getStainDamageApprovals = (categories?: Preferences.Category[]) => {
  if (!categories) return false;
  return categories.reduce((accu, current) => [...accu, ...current.options.filter((i) => i.value).map((i) => i.key)], [] as string[]).length > 0;
};

const getDriverInstructions = (categories?: Preferences.Category[]) => {
  if (!categories) return [];
  return categories.reduce((accu, current) => [...accu, ...current.options.filter((i) => i.value).map((i) => i.key)], [] as string[]);
};

export const selectIsUrgentOrder = createSelector(
  (props: RootState & { serviceLine?: ServiceLine }) => {
    const { serviceLine, ...state } = props;
    return { state, serviceLine };
  },
  ({ state, serviceLine }) => {
    let selectedDropoffDate: TimeSlots.Timeslot | undefined;
    let dropOffTimeSlotData: TimeSlots.TimeslotsData | undefined;

    if (serviceLine) {
      selectedDropoffDate = state.newOrder.serviceLineDropoffs[serviceLine] as TimeSlots.Timeslot;
      dropOffTimeSlotData = state.newOrder.serviceLineTimeSlotData[serviceLine] as TimeSlots.TimeslotsData;
    } else {
      selectedDropoffDate = state.newOrder.currentOrder.dropoffTimeslot as TimeSlots.Timeslot;
      dropOffTimeSlotData = state.newOrder.timeSlots.dropoffTimeslotsData as TimeSlots.TimeslotsData;
    }

    const date = dropOffTimeSlotData?.dates?.find((dateSlot) => dateSlot.date === selectedDropoffDate?.date);

    return date?.isUrgentDay;
  }
);

interface UrgentDropoffsOrPremiumTimeSlot {
  [key: string]: boolean | undefined;
}

export const selectIsUrgentOrderInServiceLines = createSelector(
  (props: RootState) => {
    const { ...state } = props;
    return { state };
  },
  ({ state }) => {
    const urgentDropoffs: UrgentDropoffsOrPremiumTimeSlot = {};
    state.newOrder.serviceLines.forEach((serviceLine) => {
      const dropOffTimeSlotData = state.newOrder.serviceLineTimeSlotData[serviceLine];
      const selectedDropoffDate = state.newOrder.serviceLineDropoffs[serviceLine];

      const date = dropOffTimeSlotData?.dates?.find((dateSlot) => dateSlot.date === selectedDropoffDate?.date);

      urgentDropoffs[serviceLine] = date?.isUrgentDay;
    });

    return urgentDropoffs;
  }
);

export const selectisPremiumTimeSlot = createSelector(
  (props: RootState & { serviceLine?: ServiceLine }) => {
    const { serviceLine, ...state } = props;
    return { state, serviceLine };
  },
  ({ state, serviceLine }) =>
    serviceLine ? state.newOrder.serviceLineDropoffs[serviceLine]?.isPremiumTimeSlot : state.newOrder.currentOrder.dropoffTimeslot?.isPremiumTimeSlot
);

export const selectPremiumTimeSlotInServiceLines = createSelector(
  (props: RootState) => {
    const { ...state } = props;
    return { state };
  },
  ({ state }) => {
    const premiumTimeSlots: UrgentDropoffsOrPremiumTimeSlot = {};

    state.newOrder.serviceLines.forEach((serviceLine) => {
      const selectedDropoffDate = state.newOrder.serviceLineDropoffs[serviceLine];
      premiumTimeSlots[serviceLine] = selectedDropoffDate?.isPremiumTimeSlot;
    });

    return premiumTimeSlots;
  }
);

export const selectDriverTip = (state: RootState) => state.newOrder.currentOrder.driverTip;

export const selectSelectedServices = (state: RootState) => state.newOrder.serviceLines;

export const selectIsMultipleServicesSelected = (state: RootState) => state.newOrder.serviceLines?.length > 1;

export const selectSelectedServicesCount = (state: RootState) => state.newOrder.serviceLines?.length ?? 0;

export const selectCurrentOrder = (state: RootState) => state.newOrder.currentOrder;

export const selectServiceLineDropOffs = (state: RootState) => state.newOrder.serviceLineDropoffs;

export const selectServiceLineCollectionMethods = (state: RootState) => state.newOrder.serviceLineCollectionMethods;

export const selectIsShoeCareSelected = (state: RootState) => state.newOrder.serviceLines?.some((service) => service === ServiceLine.SHOE_SERVICE);

export const selectCurrentOrderPreferences = (state: RootState) => state.newOrder.currentOrder.preferences;

export const selectPreference = (state: RootState, _?: keyof Preferences.CustomerPreferences, serviceLine?: ServiceLine) =>
  serviceLine ? state.newOrder.serviceLinePreferences[serviceLine] : state.newOrder.currentOrder.preferences;

export const selectPreferencesWithServiceLine = (state: RootState, serviceLine?: ServiceLine) =>
  serviceLine ? state.newOrder.serviceLinePreferences[serviceLine] : state.newOrder.currentOrder.preferences;

export const selectAllPreferencesOfServiceLine = (state: RootState) => state.newOrder.serviceLinePreferences;

export const selectPreferencesArray = createSelector(
  selectPreference,
  (_: RootState, type: keyof Preferences.CustomerPreferences) => type,
  (_: RootState, type: keyof Preferences.CustomerPreferences) => type,
  (preference, type) => preference?.[type]
);

export const selectPreferenceType = createSelector(
  (state: RootState) => [state.newOrder.currentOrder.isPickupAtDoor, state.newOrder.currentOrder.isDropoffAtDoor],
  selectPreference,
  (_: RootState, type: keyof Preferences.CustomerPreferences) => type,
  (_: RootState, _type: keyof Preferences.CustomerPreferences, serviceLine: ServiceLine) => serviceLine,
  ([isPickupAtDoor, isDropoffAtDoor], preference, type) => {
    const resolveDriverDropoffPickupInstructions = (checkType: 'driverPickupInstructions' | 'driverDropoffInstructions') => {
      const atDoor = checkType === 'driverPickupInstructions' ? !!isPickupAtDoor : !!isDropoffAtDoor;
      const driverType = atDoor ? 'atDoor' : 'inPerson';

      return preference?.[checkType][driverType].categories
        .reduce((accu, current) => [...accu, ...current.options.filter((i) => i.value && !i.isDefault).map((i) => i.text)], [] as string[])
        .join(', ');
    };

    switch (type) {
      case 'stainDamageApprovals':
      case 'starch':
      case 'creases':
      case 'folding':
        return preference?.[type].categories
          .reduce(
            (accu, current) => [
              ...accu,
              ...current.options.filter((i) => i.value && !i.isDefault).map((i) => (type === 'creases' ? i.selectedText || '' : i.text)),
            ],
            [] as string[]
          )
          .join(', ');
      case 'driverPickupInstructions':
      case 'driverDropoffInstructions':
        return resolveDriverDropoffPickupInstructions(type);
      case 'shoeAddOnApprovals':
        return preference?.[type] as Preferences.ShoeAddOnApprovals;
      default:
        return '';
    }
  }
);

export const selectHasSelectedDriverPreferences = (state: RootState) => {
  // Check if any service lines are selected
  if (state.newOrder.serviceLines.length > 0) {
    // If there are selected service lines, check if any of them have selected driver preferences
    const hasSelectedDriverPreferencesInServiceLines = state.newOrder.serviceLines.some((serviceLine: ServiceLine) => {
      const [dropoff, pickup] = [
        selectPreferenceType(state, 'driverDropoffInstructions', serviceLine),
        selectPreferenceType(state, 'driverPickupInstructions', serviceLine),
      ];
      // Return true if either dropoff or pickup preferences are selected
      return dropoff || pickup;
    });

    // Return true if any service line has selected driver preferences
    return hasSelectedDriverPreferencesInServiceLines;
  }
  // If no service lines are selected, check for normal order flow
  const [dropoff, pickup] = [selectPreferenceType(state, 'driverDropoffInstructions'), selectPreferenceType(state, 'driverPickupInstructions')];

  // Return true if either dropoff or pickup preferences are selected in normal order flow
  return Boolean(dropoff || pickup);
};

export const selectHasSelectedPreferences = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.newOrder.serviceLines,
  (state, serviceLines) => {
    const checkIfPreferencesSelected = (serviceLine?: ServiceLine) => {
      const [creases, dropoff, pickup, folding, approvals, starch, shoes] = [
        selectPreferenceType(state, 'creases', serviceLine),
        selectPreferenceType(state, 'driverDropoffInstructions', serviceLine),
        selectPreferenceType(state, 'driverPickupInstructions', serviceLine),
        selectPreferenceType(state, 'folding', serviceLine),
        selectPreferenceType(state, 'stainDamageApprovals', serviceLine),
        selectPreferenceType(state, 'starch', serviceLine),
        selectPreferenceType(state, 'shoeAddOnApprovals', serviceLine) as Preferences.ShoeAddOnApprovals,
      ];

      if (creases || dropoff || pickup || folding || approvals || starch || shoes?.restoration || shoes?.soleIcing || shoes?.stainProtection)
        return true;

      return false;
    };

    // it shows multiple serviceLine flow is selected, else there is a normal order flow
    // it also reflect partner does support multiple service line flow
    if (serviceLines.length > 0) {
      return serviceLines.some((serviceLine) => checkIfPreferencesSelected(serviceLine));
    }
    return checkIfPreferencesSelected();
  }
);

// Selector to compute all the data required to create a new order via API
export const selectNewOrderData = createSelector(
  selectCurrentOrder,
  (state: RootState, disablePayment: boolean, shouldShowPaymentSheetIfMissing: boolean) => ({
    new: state.newOrder,
    disablePayment,
    shouldShowPaymentSheetIfMissing,
  }),
  hasApplePayPayment,
  (
    { addressId, creditCardId, driverTip, dropoffTimeslot, isDropoffAtDoor, isPickupAtDoor, pickupTimeslot, preferences },
    { disablePayment, shouldShowPaymentSheetIfMissing },
    applePaySelected
  ): Order.NewOrder | undefined => {
    if (
      !addressId ||
      (!disablePayment && !shouldShowPaymentSheetIfMissing && !(creditCardId || applePaySelected)) ||
      !dropoffTimeslot ||
      !dropoffTimeslot.date ||
      !pickupTimeslot ||
      !pickupTimeslot.date
    )
      return undefined;

    return {
      addressId: addressId || '',
      creditCardId: creditCardId || '',
      driverTip: driverTip || 0,
      dropoffDate: dropoffTimeslot.date,
      dropoffTime: dropoffTimeslot.slotId,
      dropoffTimeslotMode: dropoffTimeslot.timeslotMode,
      pickupDate: pickupTimeslot.date,
      pickupTime: pickupTimeslot.slotId,
      pickupTimeslotMode: pickupTimeslot.timeslotMode,
      isDropoffAtDoor: isDropoffAtDoor || false,
      isPickupAtDoor: isPickupAtDoor || false,
      preferences: {
        folding: getPreferences(preferences?.folding),
        creases: getPreferences(preferences?.creases),
        starch: getPreferences(preferences?.starch),
        stainDamageApprovals: {
          isAutoApprove: getStainDamageApprovals(preferences?.stainDamageApprovals?.categories),
        },
        driverPickupInstructions: {
          atDoorInstructionsArray: getDriverInstructions(preferences?.driverPickupInstructions?.atDoor?.categories),
          inPersonInstructionsArray: getDriverInstructions(preferences?.driverPickupInstructions?.inPerson?.categories),
        },
        driverDropoffInstructions: {
          atDoorInstructionsArray: getDriverInstructions(preferences?.driverDropoffInstructions?.atDoor?.categories),
          inPersonInstructionsArray: getDriverInstructions(preferences?.driverDropoffInstructions?.inPerson?.categories),
        },
      },
    };
  }
);

export const getSelectedShoeAutoApprovalRestoration = (data?: Preferences.ShoeAddOnApprovals) => data?.restoration ?? false;

export const getSelectedShoeAutoApprovalStainProtection = (data?: Preferences.ShoeAddOnApprovals) => data?.stainProtection ?? false;

export const getSelectedShoeAutoApprovalSoleIcing = (data?: Preferences.ShoeAddOnApprovals) => data?.soleIcing ?? false;

export const selectOrderServices = (state: RootState) => state.newOrder.services;

// Selector to compute all the data required to create a new order via API with multiple dropoffs
export const selectNewOrderDataWithAddon = createSelector(
  (state: RootState) => state,
  selectCurrentOrder,
  (state: RootState, disablePayment: boolean, shouldShowPaymentSheetIfMissing: boolean) => ({
    new: state.newOrder,
    disablePayment,
    shouldShowPaymentSheetIfMissing,
  }),
  hasApplePayPayment,
  (
    state: RootState,
    { addressId, creditCardId, driverTip, isPickupAtDoor, pickupTimeslot },
    { disablePayment, shouldShowPaymentSheetIfMissing },
    applePaySelected
  ): Order.NewOrder | undefined => {
    const { serviceLines, serviceLineDropoffs, serviceLineCollectionMethods, serviceLinePreferences } = state.newOrder;
    const [defaultServiceLine, addonServiceLine] = serviceLines;
    const isDropoffsSelected = Object.values(serviceLineDropoffs).every((dropoff) => dropoff && dropoff.date);

    if (
      !addressId ||
      (!disablePayment && !shouldShowPaymentSheetIfMissing && !(creditCardId || applePaySelected)) ||
      !isDropoffsSelected ||
      !pickupTimeslot ||
      !pickupTimeslot.date
    )
      return undefined;

    const generatePreferences = (preferences: Preferences.CustomerPreferences) => ({
      folding: getPreferences(preferences?.folding) !== '' ? getPreferences(preferences?.folding) : undefined,
      creases: getPreferences(preferences?.creases) ?? undefined,
      starch: getPreferences(preferences?.starch) ?? undefined,
      stainDamageApprovals: {
        isAutoApprove: getStainDamageApprovals(preferences?.stainDamageApprovals?.categories),
      },
      driverPickupInstructions: {
        atDoorInstructionsArray: getDriverInstructions(preferences?.driverPickupInstructions?.atDoor?.categories),
        inPersonInstructionsArray: getDriverInstructions(preferences?.driverPickupInstructions?.inPerson?.categories),
      },
      driverDropoffInstructions: {
        atDoorInstructionsArray: getDriverInstructions(preferences?.driverDropoffInstructions?.atDoor?.categories),
        inPersonInstructionsArray: getDriverInstructions(preferences?.driverDropoffInstructions?.inPerson?.categories),
      },
      shoeAddOnApprovals: {
        restoration: getSelectedShoeAutoApprovalRestoration(preferences?.shoeAddOnApprovals),
        stainProtection: getSelectedShoeAutoApprovalStainProtection(preferences?.shoeAddOnApprovals),
        soleIcing: getSelectedShoeAutoApprovalSoleIcing(preferences?.shoeAddOnApprovals),
      },
    });

    const getOrderPayload = (serviceLine: ServiceLine) => {
      const { date, slotId, timeslotMode } = serviceLineDropoffs[serviceLine] || {};
      const isDropoffAtDoor = serviceLineCollectionMethods[serviceLine]?.isDropoffAtDoor;
      const preferences = generatePreferences(serviceLinePreferences[serviceLine]!!);
      return {
        dropoffDate: date!,
        dropoffTime: slotId!,
        dropoffTimeslotMode: timeslotMode!,
        isDropoffAtDoor: isDropoffAtDoor || false,
        preferences,
        serviceLine,
      };
    };

    const orderPayload: Order.NewOrder = {
      addressId: addressId || '',
      creditCardId: creditCardId || '',
      driverTip: driverTip || 0,
      pickupDate: pickupTimeslot.date,
      pickupTime: pickupTimeslot.slotId,
      pickupTimeslotMode: pickupTimeslot.timeslotMode,
      isPickupAtDoor: isPickupAtDoor || false,
      ...getOrderPayload(defaultServiceLine),
    };

    if (addonServiceLine) {
      orderPayload.extraServiceLineData = {
        ...getOrderPayload(addonServiceLine),
      };
    }

    return orderPayload;
  }
);
