import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useSplashScreenDisplay = () => {
  const location = useLocation();
  const isAtVerifyStep = useMemo(() => location.pathname.includes('verify') && !location.pathname.includes('login'), [location.pathname]);

  useEffect(() => {
    const splash = document.getElementById('splash-screen');
    if (!isAtVerifyStep && splash) {
      splash.style.display = 'none';
    } else if (isAtVerifyStep && splash) {
      splash.style.display = 'block';
    }
  }, [isAtVerifyStep]);
};

export default useSplashScreenDisplay;
