import DropoffAtDoorLandscape from 'assets/images/dropoff/dropoff-at-door-landscape.webp';
import DropoffAtDoorPortraitActive from 'assets/images/dropoff/dropoff-at-door-portrait.webp';
import MeetDriverLandscape from 'assets/images/meet-driver/meet-driver-landscape.webp';
import MeetDriverPortraitActive from 'assets/images/meet-driver/meet-driver-portrait.webp';
import OnboardingBanner1 from 'assets/images/onboarding/onboarding_step1.webp';
import OnboardingBanner2 from 'assets/images/onboarding/onboarding_step2.webp';
import OnboardingBanner3 from 'assets/images/onboarding/onboarding_step3.webp';
import OnboardingBanner4 from 'assets/images/onboarding/onboarding_step4.webp';
import PickupAtDoorLandscape from 'assets/images/pickup/pickup-at-door-landscape.webp';
import PickupAtDoorPortraitActive from 'assets/images/pickup/pickup-at-door-portrait.webp';
import SplashScreenLoader from 'assets/images/splash-screen-careem.webp';
import SplashScreenLoader2x from 'assets/images/splash-screen-careem-2x.webp';
import SplashScreenLoader3x from 'assets/images/splash-screen-careem-3x.webp';
import { useEffect, useState } from 'react';

const imgs = [
  SplashScreenLoader,
  SplashScreenLoader2x,
  SplashScreenLoader3x,
  PickupAtDoorPortraitActive,
  DropoffAtDoorPortraitActive,
  MeetDriverPortraitActive,
  PickupAtDoorLandscape,
  MeetDriverLandscape,
  DropoffAtDoorLandscape,
  OnboardingBanner1,
  OnboardingBanner2,
  OnboardingBanner3,
  OnboardingBanner4,
];

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function onLoad() {
      resolve(img);
    };
    img.onerror = function onError() {
      reject(src);
    };
    img.onabort = function onAbort() {
      reject(src);
    };
    img.src = src;
  });
}

export default function useImagePreloader(imageList: string[] = imgs) {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      const imagesPromiseList: Promise<any>[] = [];
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i));
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreloaded };
}
