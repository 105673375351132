import type { Middleware } from '@reduxjs/toolkit';
import { trackEvent } from 'wrappers/reporting';

import { registerUser } from '../slices/authSlice';

export const reportingMiddleware: Middleware = () => (next) => (action) => {
  switch ((action as any).type) {
    case registerUser.fulfilled.type: {
      trackEvent('RegistrationComplete');
      (window as any)?.fbq?.('track', 'CompleteRegistration');
      next(action);
      break;
    }
    default:
      next(action);
  }
};
