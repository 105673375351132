import i18n from 'i18n/config';
import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

const StyledContentLoader = styled(ContentLoader).attrs(({ theme }) => ({
  backgroundColor: theme.loaders.contentLoaders.backgroundColor,
  foregroundColor: theme.loaders.contentLoaders.foregroundColor,
}))``;

const OrdersListLoader: React.FC = (props) => {
  const isRTL = i18n.dir() === 'rtl';

  return (
    <StyledContentLoader speed={0.5} width="100%" height={83} rtl={isRTL} {...props}>
      <rect x="0" y="5" rx="0" ry="0" width="82" height="16" />
      <rect x="0" y="37" rx="0" ry="0" width="252" height="15" />
    </StyledContentLoader>
  );
};
export default OrdersListLoader;
