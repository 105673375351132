import { useQuery } from '@tanstack/react-query';
import { getPricingAPI } from 'api/pricing';
import { useSelector } from 'react-redux';
import { selectCustomer } from 'redux-stores/slices/authSlice';
import { usePricingStore } from 'store/pricing.store';

import { REACT_QUERY_KEYS } from '.';

export function usePricing() {
  const setBillingConstants = usePricingStore.use.setBillingConstants();

  const customer = useSelector(selectCustomer);

  return useQuery({
    queryKey: [REACT_QUERY_KEYS.pricingKeys.ALL_PRICING, customer?.id],
    queryFn: () => getPricingAPI(customer?.id),
    staleTime: 60000, // Adding staleTime to prevent query from refetching on each re-render
    onSuccess: (data) => {
      setBillingConstants(data.billingConstants);
    },
  });
}
