import AR from './locales/app/ar.app.json';
import EN from './locales/app/en.app.json';

export enum LanguageCodes {
  'EN' = 'en',
  'AR' = 'ar',
  'FR' = 'fr',
  'ZH' = 'zh',
  'RU' = 'ru',
}

export enum LanguageNames {
  'EN' = 'English',
  'AR' = 'عربي',
  'FR' = 'Français',
  'ZH' = '中国人',
  'RU' = 'русский',
}

export type Language = {
  name: LanguageNames;
  code: LanguageCodes;
};

export const languageCodes = [LanguageCodes.EN, LanguageCodes.AR, LanguageCodes.FR, LanguageCodes.RU, LanguageCodes.ZH];

export const languages: Language[] = [
  { name: LanguageNames.EN, code: LanguageCodes.EN },
  { name: LanguageNames.AR, code: LanguageCodes.AR },
  // { name: LanguageNames.FR, code: LanguageCodes.FR },
  // { name: LanguageNames.ZH, code: LanguageCodes.ZH },
  // { name: LanguageNames.RU, code: LanguageCodes.RU },
];

export const resources = {
  en: {
    translation: EN,
  },
  ar: {
    translation: AR,
  },
  zh: {
    translation: EN,
  },
  fr: {
    translation: EN,
  },
  ru: {
    translation: EN,
  },
};
