import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editPhoneNumberAPI, sendVerificationCodeAPI, verifyCodeAPI } from 'api/customer';
import type { Communications } from 'api/types/communication.types';
import type { Customer } from 'api/types/user.types';

import { REACT_QUERY_KEYS } from '.';

export function useSendVerificationCode(onSuccess?: () => void) {
  return useMutation({
    mutationFn: (payload: { phone: string; recaptchaToken: string; communicationMethod: Communications.Methods }) =>
      sendVerificationCodeAPI(payload.phone, payload.recaptchaToken, payload.communicationMethod),
    onSuccess,
  });
}

export function useVerifyCode(onSuccess?: () => void) {
  return useMutation({
    mutationFn: (config: { tempCode: string; phone: string }) => verifyCodeAPI(config.tempCode, config.phone),
    onSuccess,
  });
}

export function useUpdatePhoneNumber({ onSuccess, onError }: { onSuccess?: (data: Customer.Customer) => void; onError?: () => void }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (config: { oldPhone: string; phone: string; tempCode: string }) => editPhoneNumberAPI(config.oldPhone, config.phone, config.tempCode),
    onSuccess: (data) => {
      queryClient.setQueryData([REACT_QUERY_KEYS.userKeys.FETCH_CUSTOMER], data);
      queryClient.setQueryData([REACT_QUERY_KEYS.userKeys.FETCH_CUSTOMER], data);
      onSuccess?.(data);
    },
    onError,
  });
}
