import type { PricingList } from 'api/types/pricing.types';
import { createSelectors } from 'utils/create-selectors';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export interface UserInformation {}

interface PricingStore {
  billingConstants: PricingList.BillingConstants;
}

interface PricingStoreActions {
  setBillingConstants: (billingConstants: PricingList.BillingConstants) => void;
}

const initialState: PricingStore = {
  billingConstants: {
    charges: {
      delivery: 8,
      minimumAfterVat: 75,
      minimumBeforeVat: 71.43,
      vatPercentage: 0.05,
      service: {
        flex: 0,
        priority: 5,
      },
    },
  },
};

const usePricingStoreBase = create<PricingStore & PricingStoreActions>()(
  subscribeWithSelector((set) => ({
    ...initialState,
    setBillingConstants: (billingConstants) => set({ billingConstants }),
  }))
);

export const usePricingStore = createSelectors(usePricingStoreBase);
