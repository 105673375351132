import { WASHMEN_CUSTOMER_API } from 'api/config';
import type { TimeSlots } from 'api/types/timeSlot.types';

export async function fetchPickupTimeSlotsAPI(config: TimeSlots.BaseProps) {
  const params: TimeSlots.BaseProps = {
    addressId: config.addressId,
  };

  return (
    await WASHMEN_CUSTOMER_API.get<TimeSlots.PickupRootObject>(`/v3/timeslots/pickup`, {
      params,
    })
  ).data;
}

export async function fetchDropoffTimeSlotsAPI(config: TimeSlots.DropoffProps) {
  const params: TimeSlots.DropoffProps = {
    addressId: config.addressId,
    pickupDate: config.pickupDate,
    orderId: config.orderId,
    serviceLine: config.serviceLine,
  };

  return (
    await WASHMEN_CUSTOMER_API.get<TimeSlots.DropoffRootObject>(`/v3/timeslots/dropoff`, {
      params,
    })
  ).data;
}
