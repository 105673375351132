import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import type { CSSProperties } from 'react';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectSheetsCounter, sheetClosed, sheetOpened } from 'redux-stores/slices/uiSlice';
import styled, { useTheme } from 'styled-components';

import styles from './styles.module.scss';

export interface BottomSheetProps {
  sheetId?: string;
  show?: boolean;
  onClose?(): any;
  onSubmit?(): any;
  title?: string;
  className?: string;
  showDone?: boolean;
  titleClassName?: string;
  disableBackdrop?: boolean;
  hideCloseButton?: boolean;
  maxHeight?: string;
  titleStyle?: CSSProperties;
  hideHeader?: boolean;
  hasNoSpacing?: boolean;
  isNoWrapTitle?: boolean;
  isCapitalizeHeaderText?: boolean;
  children?: React.ReactNode;
  footerComponent?: React.ReactNode;
}

const sheetVariant = {
  collapsed: { y: '100%' },
  open: { y: '0%' },
};

const ANIMATION_DURATION = 0.25;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: -15px;
  margin-top: 20px;
`;

const StyledBottomSheet = styled(motion.div)<{ maxHeight?: string }>`
  border-top-left-radius: ${({ theme }) => theme.components.bottomSheet.borderRadius}px;
  border-top-right-radius: ${({ theme }) => theme.components.bottomSheet.borderRadius}px;
  background-color: ${({ theme }) => theme.components.bottomSheet.backgroundColor};
  overflow: scroll;
  max-height: ${({ maxHeight }) => maxHeight || 'unset'};
`;

const StyledTitle = styled.h3<{ $isnowraptitle?: 'true' | 'false'; $iscapitalizeheadertext?: 'true' | 'false' }>`
  font-weight: 600;
  font-size: ${({ theme }) => theme.components.bottomSheet.header.textSize ?? '24'}px;

  ${({ $isnowraptitle }) =>
    $isnowraptitle === 'true' &&
    `
    font-size: 6.2cqw;
    text-wrap: nowrap;
  `}

  ${({ $iscapitalizeheadertext }) =>
    $iscapitalizeheadertext === 'true' &&
    `
    text-transform: capitalize;
  `}

  color: ${({ theme }) => theme.components.bottomSheet.header.textColor};
`;

const StyledHeader = styled(Row)`
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: ${({ theme }) => theme.components.bottomSheet.header.backgroundColor};
  align-content: center;
  padding-left: 34px;
  padding-right: 24px;
  display: flex;
  max-width: 100%;
`;

const BottomSheet: React.FC<BottomSheetProps> = ({
  show = false,
  onClose = () => {},
  onSubmit = () => {},
  titleClassName,
  title,
  showDone,
  disableBackdrop = false,
  className,
  children,
  hideCloseButton = true,
  maxHeight = '100%',
  hideHeader = false,
  hasNoSpacing = false,
  titleStyle,
  sheetId = 'bottomsheet-container',
  isCapitalizeHeaderText = false,
  isNoWrapTitle = false,
  footerComponent,
}) => {
  const rootEl = document && document.querySelector('#root');
  const $body = document.querySelector('body');
  const sheetsCounter = useSelector(selectSheetsCounter);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const containerVariant = {
    collapsed: { backgroundColor: theme.components.bottomSheet.backdropCollapsed },
    open: { backgroundColor: theme.components.bottomSheet.backdropOpen },
  };

  // disable scrolling when sheet is open
  React.useLayoutEffect(() => {
    if (!$body) return;
    if (sheetsCounter > 0) {
      $body.style.overflow = 'hidden';
    } else {
      $body.style.removeProperty('overflow');
    }
  }, [$body, sheetsCounter]);

  React.useEffect(() => {
    if (show) {
      dispatch(sheetOpened());
    } else {
      dispatch(sheetClosed());
    }
  }, [show, dispatch]);

  if (!rootEl) {
    return null;
  }

  const handleClose = () => {
    if (!disableBackdrop) {
      onClose();
    }
  };

  const handleSheetClick: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined = (event) => {
    event.stopPropagation();
  };

  return ReactDOM.createPortal(
    <AnimatePresence>
      {show && (
        <motion.div
          id={sheetId}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          className={styles.overlay}
          variants={containerVariant}
          transition={{
            duration: ANIMATION_DURATION,
          }}
          onClick={handleClose}
        >
          <StyledBottomSheet
            onClick={handleSheetClick}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            className={cx(styles.sheet, className)}
            variants={sheetVariant}
            transition={{ ease: 'easeOut', duration: ANIMATION_DURATION }}
            maxHeight={maxHeight}
          >
            <StyledHeader>
              {!hideHeader && (
                <Col className="p-0 pt-1 mb-3">
                  <StyledTitle
                    $iscapitalizeheadertext={`${Boolean(isCapitalizeHeaderText)}`}
                    $isnowraptitle={`${Boolean(isNoWrapTitle)}`}
                    className={cx('mb-0 mt-5', titleClassName)}
                    style={titleStyle}
                  >
                    {title}
                  </StyledTitle>
                </Col>
              )}
              <Col className="pr-1" xs="auto">
                {!hideCloseButton &&
                  (showDone ? (
                    <StyledButton variant="link" className="h3 p-0 mb-0 font-weight-bold" onClick={onSubmit}>
                      {t('common.done')}
                    </StyledButton>
                  ) : (
                    <StyledButton onClick={() => onClose()} variant="link" className="h3 p-0 mb-0 font-weight-bold">
                      {t('common.close')}
                    </StyledButton>
                  ))}
              </Col>
            </StyledHeader>
            {hasNoSpacing ? children : <Container className="pb-6 h-100">{children}</Container>}
          </StyledBottomSheet>
          {footerComponent && footerComponent}
        </motion.div>
      )}
    </AnimatePresence>,
    rootEl
  );
};

export default BottomSheet;
