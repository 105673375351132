import { INTEGRATION_PARTNERS } from 'config/types';
import i18n from 'i18n/config';
import type { DefaultTheme } from 'styled-components';

import { appColors as colors } from './app.colors';

const theme: DefaultTheme = {
  name: INTEGRATION_PARTNERS.APP,
  colors,

  config: {
    searchBarHeight: 0,
  },

  text: {
    fontFamily: i18n.dir() === 'rtl' ? 'Tajawal, sans-serif' : 'Inter, sans-serif',
    webFontLoader: 'Inter:300,400,500,600,700&display=swap',
    color: colors.deepPurple,
    black: colors.black,
    subtitleText: colors.dark,
    slotBoxTextColor: colors.deepPurple,
    success: colors.textSuccess,
    tertiary: colors.textTertiary,
    danger: colors.textDanger,
    warn: colors.textWarning,
  },

  layout: {
    page: {
      heading: {
        fontSize: 24,
        fontWeight: 600,
        textColor: colors.deepPurple,
      },
      subHeading: {
        fontSize: 14,
        fontWeight: 400,
        textColor: colors.darkSlateGray,
      },
    },
  },

  loaders: {
    contentLoaders: {
      backgroundColor: colors.veryLightGray,
      foregroundColor: colors.paleGrey,
    },
  },

  icon: {
    color: colors.deepPurple,
    disabledColor: colors.cadetBlue,
    activeColor: colors.palePeachDark,
    activeColorBg: colors.palePeachDark,
    hasFixedStyle: true,
    stepperIconSize: 24,
  },

  stepper: {
    borderColor: colors.palePeachDark,
    backgroundColor: colors.ghostWhite,
  },

  intercom: {
    backgroundColor: colors.deepPurple,
    actionColor: colors.deepPurple,
  },
  timeline: {
    heading: {
      textColor: colors.deepPurple,
    },
    orderTimelineDefaultColor: {
      bgColor: colors.aquaLight,
      border: `0.6px solid ${colors.aquaDark}`,
    },
    orderTimelineSuccessBackgroundColor: {
      bgColor: colors.paleMintDim,
      border: `0.6px solid ${colors.paleMintDim}`,
    },
    orderTimelineFailedBackgroundColor: {
      bgColor: colors.pink100,
      border: `0.6px solid ${colors.crimsonDark}`,
    },
    orderTimelineCancelledBackgroundColor: {
      bgColor: colors.lightBlueGrayDim,
      border: `0.6px solid ${colors.lightGrey}`,
    },
    orderTimelinePendingBackgroundColor: {
      bgColor: colors.paleYellow,
      border: `0.6px solid ${colors.darkOrange}`,
    },
    orderTimelineCompleteBackgroundColor: {
      bgColor: colors.lightBlueGray,
      border: `0.6px solid ${colors.lightBlueGray}`,
    },
    orderTimelineCompletedBackgroundColor: {
      bgColor: colors.pastelLime,
      border: `0.6px solid ${colors.limeGreen}`,
    },
    orderTimelineColor: colors.lightGrey,
    pastTimeLineColor: colors.lightGrey,
    pastTextColor: colors.deepPurple,
    currentTextColor: colors.deepPurple,
    failedTextColor: colors.crimsonDark,
    warningTextColor: colors.warningYellow,
    pastLastTextColor: colors.brightGreen,
    animationTimeInMs: 410,
    animationSize: 20,
    orderFutureColor: colors.lightGrey,
    completedColor: colors.deepPurple,
    completedCircleColor: colors.deepPurple,
  },

  components: {
    orderHistory: {
      activeColor: colors.primary,
      pendingColor: colors.darkOrange,
      defaultColor: colors.slateGray,
      failedColor: colors.danger,
    },
    downloadAppBanner: {
      bgColor: colors.pastelLime,
      border: `0.6px solid ${colors.limeGreen}`,
      textColor: colors.deepPurple,
      radius: 8,
      button: {
        textColor: colors.deepPurple,
        border: `0.6 solid ${colors.deepPurple}`,
        bgColor: colors.white,
        radius: 100,
      },
    },
    googleMapBottomSheet: {
      backArrow: {
        color: colors.deepPurple,
      },
    },
    paymentMethodSheet: {
      footNote: {
        padding: 0,
        marginBottom: 20,
        textAlign: 'center',
        maxWidth: '77%',
        margin: '0 auto',
      },
      label: {
        textColor: colors.deepPurple,
      },
    },
    selectPickupOrDropOffItem: {
      showUnselectedRightButton: true,
      icon: {
        unselected: 'open',
        selected: 'edit',
      },
      hideDelayDescription: {
        laundry: false,
        shoes: true,
      },
    },
    buttonEstimatorContent: {
      textColor: colors.deepPurple,
      fontWeight: {
        price: 600,
        title: 500,
      },
      iconColor: colors.deepPurple,
    },
    howDoIGetBagsCard: {
      borderRadius: 1000,
      padding: {
        right: 15,
        left: 15,
      },
    },
    headerBackButton: {
      color: colors.deepPurple,
    },
    estimatedBillBottomSheet: {
      textColor: colors.deepPurple,
      isInvertedAccordion: true,
      showGrabber: false,
      maxHeight: 80,
      paymentSummary: {
        textColor: colors.deepPurple,
      },
      title: {
        fontSize: 24,
      },
      informationTip: {
        marginTop: 0,
        backgroundColor: colors.palePeachDim,
        padding: 16,
        color: colors.warningYellow,
        textAlign: 'left',
        fontSize: 12,
        borderRadius: 6,
        fontWeight: 500,
      },
      addItemRow: {
        marginBetweenItems: 8,
        title: {
          fontSize: 14,
          textColor: colors.deepPurple,
        },
        subText: {
          fontSize: 14,
          textColor: {
            normal: colors.darkStaleGray,
            discounted: colors.lightGrey,
          },
        },
      },
      content: {
        titleItemsSortOrder: 1,
        cartItem: {
          paddingLeft: 0,
          title: {
            fontSize: 16,
            fontWeight: 500,
            textColor: colors.deepPurple,
          },
        },
        container: {
          backgroundColor: colors.white,
          border: `1px solid ${colors.ghostWhite}`,
          borderTop: 'unset',
          marginBottomClassname: 'mb-0',
          hasContainerPaddingX: true,
        },
        addMoreItemButton: {
          textColor: colors.deepPurple,
        },
        paddingBottom: '0px !important',
      },
      summaryItem: {
        textColor: colors.steelBlue,
        toolTip: {
          textColor: colors.deepPurple,
        },
        largeTextSize: 14,
      },
    },
    selectService: {
      serviceItem: {
        title: {
          color: colors.deepPurple,
        },
        addButton: {
          borderWidth: 0.6,
          borderRadius: 0,
          width: 73,
          height: 32,
          borderColor: {
            selected: colors.deepPurple,
            unselected: colors.deepPurple,
          },
          textColor: {
            selected: colors.deepPurple,
            unselected: colors.deepPurple,
          },
          backgroundColor: {
            selected: colors.periwinkleBlue,
            unselected: colors.white,
          },
        },
      },
    },
    pricingSheet: {
      itemDescription: {
        textColor: colors.deepPurple,
      },
      serviceBox: {
        badge: {
          backgroundColor: colors.paleYellow,
          textColor: colors.palePeachDark,
          upToTextColor: colors.palePeachDark,
          perItemTextColor: colors.palePeachDark,
        },
        card: {
          shadow: 'unset',
          borderBottom: `0.6px solid ${colors.ghostWhite}`,
        },
      },
    },
    orderInstructions: {
      heading: {
        color: colors.deepPurple,
      },
      bottomSheetHeading: {
        textColor: colors.deepPurple,
      },
      optionTitle: {
        selected: colors.deepPurple,
        unselected: colors.black,
      },
      badge: {
        borderColor: {
          inactive: colors.grey100,
          active: colors.deepPurple,
        },
        backgroundColor: {
          inactive: colors.white,
          active: colors.periwinkleBlue,
        },
        borderWidth: 1,
        textColor: {
          active: colors.grey100,
          inactive: colors.grey100,
        },
      },
      priceView: {
        backgroundColor: colors.periwinkleBlue,
      },
    },
    addressSheet: {
      addressBox: {
        isShowRadioButton: false,
        selected: {
          borderColor: colors.deepPurple,
          backgroundColor: colors.pastelLime,
        },
      },
    },
    switchButton: {
      borderColor: {
        selected: colors.lineGreen,
        unselected: colors.staleBlue,
      },
      backgroundColor: {
        selected: colors.lightMint,
        unselected: colors.blueGray,
      },
      color: {
        selected: colors.brightGreen,
        unselected: colors.brightGreen,
      },
    },
    checkBoxes: {
      borderColor: {
        selected: colors.palePeachDark,
        unselected: colors.staleBlue,
      },
      backgroundColor: {
        selected: colors.palePeach,
        unselected: colors.white,
      },
      color: {
        selected: colors.palePeachDark,
        unselected: colors.brightGreen,
      },
    },
    radioButtons: {
      borderColor: {
        selected: colors.palePeachDark,
        unselected: colors.staleBlue,
      },
      backgroundColor: {
        selected: colors.palePeach,
        unselected: colors.white,
      },
      color: {
        selected: colors.palePeachDark,
        unselected: colors.brightGreen,
      },
    },
    pageHeading: {
      subHeading: {
        color: colors.subtitleDark,
        lineHeight: 20,
      },
      marginTop: 16,
    },
    paymentMethod: {
      card: {
        paddingTop: '16px',
        paddingBottom: '16px',
        paddingRight: '0px',
        paddingLeft: '0px',
      },
    },
    field: {
      backgroundColor: colors.paleGrey,
    },
    optionTitle: {
      fontSize: '16px',
      fontWeight: {
        active: '500',
        inactive: '500',
      },
      titleColor: {
        active: colors.deepPurple,
        inactive: colors.deepPurple,
      },
    },
    icon: {},
    pages: {
      checkout: {},
      pricing: {
        estimatedBill: {
          backgroundColor: colors.periwinkleBlue,
          title: {
            textColor: colors.deepPurple,
          },
          amount: {
            textColor: colors.deepPurple,
          },
          action: {
            textColor: colors.deepPurple,
          },
          description: {
            textColor: colors.darkStaleGray,
          },
        },
        button: {
          backgroundColor: colors.paleMintDim,
          color: {
            active: colors.deepPurple,
            inactive: colors.darkStaleGray,
          },
          arrow: colors.deepPurple,
          hover: {
            active: colors.periwinkleBlue,
            inactive: colors.primary,
          },
          borderColor: {
            active: colors.deepPurple,
            inactive: colors.darkStaleGray,
          },
        },
      },
      pickupDropoff: {
        newAddress: {
          textColor: colors.deepPurple,
          plusIcon: colors.deepPurple,
        },
        button: {
          textColor: colors.white,
          background: colors.deepPurple,
        },
        bottomSheet: {
          button: {
            textColor: colors.white,
            background: colors.deepPurple,
          },
          timeSlot: {
            background: colors.pastelLime,
            borderColor: {
              selected: colors.deepPurple,
              unselected: colors.ghostWhite,
            },
          },
          active: colors.success,
          inactive: '',
          isShowBackButton: false,
        },
      },
      selectServices: {
        borderColor: colors.primary,
        button: {},
        checkBox: {
          active: colors.primary,
          inactive: '',
        },
      },
      home: {
        placeOrderFooter: {
          backgroundColor: colors.deepPurple,
          borderColor: colors.deepPurple,
          textColor: colors.white,
          arrowColor: colors.white,
          fontWeight: 600,
        },
        viewPricingButton: {
          bgColor: colors.white,
          borderColor: colors.textTertiary,
          textColor: colors.subtitleDark,
          width: '165px',
        },
      },
      orderList: {
        label: {
          color: colors.deepPurple,
        },
        activeButton: {
          backgroundColor: colors.periwinkleBlue,
        },
      },
      orderDetails: {
        button: {
          active: {
            backgroundColor: colors.pastelLime,
            borderColor: colors.deepPurple,
          },
          inactive: {
            backgroundColor: colors.white,
          },
        },
      },
    },
    accordion: {
      title: {
        color: colors.deepPurple,
      },
      arrowUp: {
        color: colors.deepPurple,
      },
      arrowDown: {
        color: colors.deepPurple,
      },
      paddingX: 'px-5',
    },
    alert: {
      info: {
        backgroundColor: colors.lightPrimary,
      },
      success: {
        backgroundColor: `${colors.primary}1A`,
      },
      warning: {
        backgroundColor: colors.palePeach,
      },
      danger: {
        backgroundColor: colors.lightCoral,
      },
      grey: {
        backgroundColor: colors.gray500,
      },
    },
    badge: {
      warn: {
        backgroundColor: colors.lightCoral,
      },
      active: {
        backgroundColor: colors.primary,
      },
      success: {
        backgroundColor: colors.success,
        textColor: colors.textSuccess,
      },
    },
    bottomSheet: {
      backgroundColor: colors.white,
      borderRadius: 24,
      mostPopularTextColor: colors.brightGreen,
      backdropCollapsed: colors.transparent,
      backdropOpen: `${colors.black}80`,
      header: {
        backgroundColor: colors.white,
        textColor: colors.deepPurple,
        textSize: 20,
      },
    },
    whatToExpectBottomSheet: {
      button: {
        textColor: colors.white,
        primary: {
          backgroundColor: colors.deepPurple,
        },
      },
    },
    button: {
      border: {
        width: 2,
        radius: 40,
        color: colors.transparent,
      },
      textColor: colors.white,
      hidePlaceOrderArrow: true,
      disabled: {
        backgroundColor: colors.lightDarkGray,
        borderColor: colors.transparent,
        color: colors.lightGrey,
      },
      primary: {
        backgroundColor: colors.deepPurple,
      },
      danger: {
        backgroundColor: colors.danger,
      },
      warning: {
        backgroundColor: colors.warning,
      },
    },
    backButton: {
      color: colors.textSuccess,
      active: {
        color: colors.textSuccess,
      },
      backIcon: {
        color: colors.textSuccess,
      },
    },
    tipBox: {
      border: {
        width: 2,
        radius: 40,
        color: colors.tertiary,
        selectedBorderColor: colors.deepPurple,
      },
      backgroundColor: {
        selected: colors.periwinkleBlue,
        unselected: colors.white,
      },
      text: {
        color: {
          selected: colors.deepPurple,
          unselected: colors.subtitleDark,
        },
      },
    },
    card: {
      header: {
        backgroundColor: {
          selected: colors.success,
          unselected: colors.white,
        },
        border: {
          width: 2,
          radius: 8,
        },
        borderBottom: {
          width: 1,
          color: colors.paleBlue,
        },
        left: {
          color: {
            selected: colors.charcoal,
            unselected: colors.charcoal,
          },
        },
        right: {
          color: {
            selected: colors.charcoal,
            unselected: colors.charcoal,
          },
        },
      },
      backgroundColor: {
        selected: '',
        unselected: colors.white,
      },
      successColor: colors.paleJade,
      border: {
        width: 1.5,
        radius: 8,
        color: {
          selected: colors.primary,
          unselected: colors.gray500,
        },
      },
      text: {
        color: colors.charcoal,
        bulletColorSelected: colors.charcoal,
        bulletColorUnselected: colors.charcoal,
      },
    },
    carousel: {
      indicator: {
        backgroundColor: {
          active: colors.primary,
          inactive: colors.lightGray,
        },
      },
    },
    collectionMethodSelector: {
      title: {
        backgroundColor: {
          selected: colors.pastelLime,
          unselected: '',
        },
      },
      description: {
        color: colors.deepPurple,
      },
      selected: {
        backgroundColor: colors.white,
        color: colors.deepPurple,
        border: {
          radius: 14,
          width: 1,
          color: colors.deepPurple,
        },
      },
      unselected: {
        backgroundColor: colors.white,
        color: colors.darkStaleGray,
        border: {
          radius: 14,
          width: 1,
          color: colors.ghostWhite,
        },
      },
    },
    eidNoticeBottomSheet: {
      note: {
        color: colors.darkMidnightBlue,
      },
    },
    faqSection: {
      button: {
        backgroundColor: colors.transparent,
        borderBottom: {
          width: 1,
          color: colors.ghostWhite,
        },
        text: {
          color: colors.darkForestGreen,
        },
      },
      plusIcon: {
        color: colors.darkForestGreen,
      },
    },
    flexPresetPickupConfirmationSheet: {
      clockIcon: {
        color: colors.primary,
      },
      bagsPickupTime: {
        text: {
          color: colors.primary,
        },
      },
    },
    footer: {
      background: colors.periwinkleBlue,
      boxShadow: `0px 0px 24px 0px ${colors.black}18`,
      paddingBottom: 36,
      borderRadius: {
        topLeft: 32,
        topRight: 32,
      },
      paddingTop: 15,
    },
    header: {
      backgroundColor: colors.white,
    },
    gettingBagBottomSheet: {
      description: {
        color: colors.subtitleDark,
      },
    },
    onboardingBottomSheet: {
      button: {
        backgroundColor: colors.white,
        border: {
          width: 1,
          color: colors.paleLightGray,
        },
      },
      description: {
        color: colors.subtitleDark,
      },
    },
    onboardingModal: {
      backgroundColor: colors.white,
      title: {
        color: '',
      },
    },
    optionItem: {
      color: colors.grey100,
      leftIcon: {
        color: colors.palePeachDark,
        danger: {
          color: colors.danger,
        },
      },
      rightIcon: {
        color: colors.deepPurple,
        danger: {
          color: colors.charcoal,
        },
      },
    },
    orderList: {
      emptyMessage: {
        color: colors.darkMidnightBlue,
      },
    },
    promos: {
      text: {
        color: {
          active: colors.textSuccess,
          inactive: colors.textColor,
        },
        weight: {
          bold: 500,
          normal: 300,
        },
      },
    },
    radioButton: {
      container: {
        checked: {
          borderRadius: 100,
          backgroundColor: colors.success,
        },
      },
      border: {
        width: 1,
        color: colors.tertiary,
      },
      backgroundColor: colors.blueGray,
      borderRadius: 100,
      checked: {
        backgroundColor: colors.success,
        borderColor: colors.primary,
      },
    },
    registerEmailModal: {
      field: {
        valid: {
          backgroundColor: colors.success,
        },
        invalid: {
          backgroundColor: colors.textTertiary,
        },
        focus: {
          valid: {
            backgroundColor: colors.success,
          },
          invalid: {
            backgroundColor: colors.textTertiary,
          },
        },
      },
    },
  },
};

export default theme;
