import type { LanguageCodes } from 'i18n/constants';

export const getBrowserLanguage: () => LanguageCodes = () => {
  const browserLanguageRaw = () =>
    navigator.languages
      ? navigator.languages[0]
      : // @ts-ignore
        navigator.language || navigator.userLanguage;
  return browserLanguageRaw().split('-')[0];
};
