import { BackIcon } from 'assets/icons';
import i18n from 'i18n/config';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ButtonProps {
  onClick?: () => void;
  className?: string;
}

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.components.backButton.backIcon.color};

  &:hover,
  &:focus,
  &:active,
  &.btn-primary:not(:disabled):not(.disabled):active {
    color: ${({ theme }) => theme.components.backButton.active.color};
    text-decoration: none;
  }
`;

const StyledBackIcon = styled(BackIcon)`
  & > g {
    & > g {
      & > g {
        & > g {
          & path {
            fill: ${({ theme }) => theme.components.backButton.backIcon.color};
          }
        }
      }
    }
  }
`;

const BackButton: React.FC<ButtonProps> = ({ onClick, className = '' }) => {
  const isRTL = i18n.dir() === 'rtl';
  const { t } = useTranslation();

  return (
    <StyledButton variant="link" className={className} onClick={onClick}>
      <StyledBackIcon className={isRTL ? 'transform-180' : ''} />
      <h5 className="font-weight-normal">{t('common.goBack', 'Go Back')}</h5>
    </StyledButton>
  );
};

export default BackButton;
