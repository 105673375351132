import type { Middleware } from '@reduxjs/toolkit';
import { getBrowserLanguage } from 'utils/browserLanguage';

import { logout, refreshTokenAction } from '../slices/authSlice';
import { setAppLanguage } from '../slices/uiSlice';

export const uiMiddleware: Middleware = (store) => (next) => (action) => {
  switch ((action as any).type) {
    case refreshTokenAction.rejected.type:
    case logout.fulfilled.type: {
      store.dispatch(setAppLanguage(getBrowserLanguage()));
      localStorage.removeItem('language');
      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default uiMiddleware;
