/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { cloneElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

interface RoutesTransitionProps {
  children: React.ReactNode;
  pathList?: string[];
  duration?: number;
  timing?: 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out' | 'linear';
  animation?: 'slide' | 'vertical-slide' | 'rotate' | 'inverted-slide';
  destroy?: boolean;
  skipIntialRoute?: string;
  enable?: boolean;
}

const getCssWithoutAnimation = () => css`
  .page {
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

const appliedSyleUponAnimate = `
  top: 0;
  width: 100%;
  position: absolute;
  overflow: hidden;
`;

const getCss = (duration: number, timing: string, direction: string) => css`
  .page {
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
    &:not(:only-child) {
      &.${direction}-enter-active, &.${direction}-exit-active {
        transition: transform ${duration}ms ${timing};
      }
    }
  }

  &.inverted-slide {
    // back
    .back-enter {
      transform: translateX(100%);

      ${appliedSyleUponAnimate}
    }
    .back-enter-active {
      transform: translateX(0);

      ${appliedSyleUponAnimate}
    }
    .back-exit {
      transform: translateX(0);

      ${appliedSyleUponAnimate}
    }
    .back-exit-active {
      transform: translateX(-100%);

      ${appliedSyleUponAnimate}
    }

    // forward
    .forward-enter {
      transform: translateX(-100%);

      ${appliedSyleUponAnimate}
    }
    .forward-enter-active {
      transform: translateX(0);

      ${appliedSyleUponAnimate}
    }
    .forward-exit {
      transform: translateX(0);

      ${appliedSyleUponAnimate}
    }
    .forward-exit-active {
      transform: translateX(100%);

      ${appliedSyleUponAnimate}
    }
  }

  &.slide {
    // back
    .back-enter {
      transform: translateX(-100%);

      ${appliedSyleUponAnimate}
    }
    .back-enter-active {
      transform: translateX(0);
      ${appliedSyleUponAnimate}
    }
    .back-exit {
      transform: translateX(0);

      ${appliedSyleUponAnimate}
    }
    .back-exit-active {
      transform: translateX(100%);

      ${appliedSyleUponAnimate}
    }

    // forward
    .forward-enter {
      transform: translateX(100%);

      ${appliedSyleUponAnimate}
    }
    .forward-enter-active {
      transform: translateX(0);

      ${appliedSyleUponAnimate}
    }
    .forward-exit {
      transform: translateX(0);

      ${appliedSyleUponAnimate}
    }
    .forward-exit-active {
      transform: translateX(-100%);

      ${appliedSyleUponAnimate}
    }
  }

  &.vertical-slide {
    overflow: hidden;

    // back
    .back-enter {
      transform: translateY(-100%);

      ${appliedSyleUponAnimate}
    }
    .back-enter-active {
      transform: translateY(0);

      ${appliedSyleUponAnimate}
    }
    .back-exit {
      transform: translateY(0);

      ${appliedSyleUponAnimate}
    }
    .back-exit-active {
      transform: translateY(100%);

      ${appliedSyleUponAnimate}
    }

    // forward
    .forward-enter {
      transform: translateY(100%);

      ${appliedSyleUponAnimate}
    }
    .forward-enter-active {
      transform: translateY(0);

      ${appliedSyleUponAnimate}
    }
    .forward-exit {
      transform: translateY(0);

      ${appliedSyleUponAnimate}
    }
    .forward-exit-active {
      transform: translateY(-100%);

      ${appliedSyleUponAnimate}
    }
  }

  &.rotate {
    perspective: 2000px;

    .page {
      backface-visibility: hidden;
    }

    // back
    .back-enter {
      transform: rotateY(-180deg);
    }
    .back-enter-active {
      transform: rotateY(0);
    }
    .back-exit {
      transform: rotateY(0);
    }
    .back-exit-active {
      transform: rotateY(180deg);
    }

    // forward
    .forward-enter {
      transform: rotateY(180deg);
    }
    .forward-enter-active {
      transform: rotateY(0);
    }
    .forward-exit {
      transform: rotateY(0);
    }
    .forward-exit-active {
      transform: rotateY(-180deg);
    }
  }
`;

const RoutesTransitioner = ({
  children,
  pathList = [],
  duration = 300,
  timing = 'ease',
  animation = 'slide',
  destroy = true,
  skipIntialRoute,
  enable = false,
}: RoutesTransitionProps) => {
  const initailDisableAnimationOnRoute = useRef(skipIntialRoute ? [skipIntialRoute] : []);
  const location = useLocation();
  const hasMount = useRef(false);
  const prevPath = useRef<string>();
  const direction = useRef('');
  const nextPath = location.pathname + location.search;
  const selfList = useRef<string[]>();
  const selfKey = '::slide::history::';

  const cssProps = useMemo(() => (destroy ? { timeout: duration } : { addEndListener() {} }), [destroy, duration]);

  if (!hasMount.current) {
    // mount
    hasMount.current = true;

    if (pathList.length > 0) {
      prevPath.current = nextPath;
    } else {
      const cacheList = sessionStorage.getItem(selfKey);
      if (!cacheList) {
        selfList.current = [nextPath];
        prevPath.current = nextPath;
        sessionStorage.setItem(selfKey, JSON.stringify(selfList.current));
      } else {
        selfList.current = JSON.parse(cacheList);
        prevPath.current =
          selfList.current && selfList.current.length > 0 ? selfList.current[selfList.current.length - 1] : location.pathname + location.search;
      }
    }
  }

  // update
  if (prevPath.current !== nextPath) {
    if (pathList.length > 0) {
      const prevIndex = pathList.indexOf(prevPath.current!!);
      const nextIndex = pathList.indexOf(nextPath);
      direction.current = prevIndex < nextIndex ? 'forward' : 'back';
    } else {
      const nextIndex = selfList.current?.lastIndexOf(nextPath) ?? 0;

      if (nextIndex === -1) {
        direction.current = 'forward';
        selfList.current?.push(nextPath);
      } else {
        direction.current = 'back';
        if (selfList.current) selfList.current.length = nextIndex + 1;
      }

      sessionStorage.setItem(selfKey, JSON.stringify(selfList.current));
    }

    prevPath.current = nextPath;
  }

  // ensure we are padding animatable routes to Transitioner
  // initially we were creating animatable routes here
  // now this function can be achieved by using useCreateAnimatableRoutes
  /**
   *  Example Usage of useCreateAnimatableRoutes
   *  const {createAnimatableRoutes} = useCreateAnimatableRoutes();
   *  {createAnimatableRoutes(protectedRoutes.map(({ path, Component }) => (<Route path={path} Component={Component} /> )))
   */
  const routList = useMemo(() => children, [children]);

  const getinitailDisableAnimationOnRoute = useCallback(() => {
    const list = [...initailDisableAnimationOnRoute.current];
    // clear initial default route so animation start to work on goBack events.
    initailDisableAnimationOnRoute.current = [];
    return list;
  }, []);

  const appliedAnimation = useMemo(
    () =>
      location?.state?.disableAnimation || getinitailDisableAnimationOnRoute().includes(nextPath) ? '' : location?.state?.animation ?? animation,
    [animation, getinitailDisableAnimationOnRoute, location?.state?.disableAnimation, nextPath]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, [nextPath]);

  const RoutesSwitch = useMemo(() => <Routes location={location}>{routList}</Routes>, [location, routList]);

  const createChildFactory = useCallback((child: React.ReactElement) => cloneElement(child, { classNames: direction.current }), []);

  return enable ? (
    <TransitionGroup className={`${appliedAnimation}`} childFactory={createChildFactory} css={getCss(duration, timing, direction.current)}>
      <CSSTransition key={appliedAnimation === '' ? prevPath.current : nextPath} {...cssProps}>
        {RoutesSwitch}
      </CSSTransition>
    </TransitionGroup>
  ) : (
    <TransitionGroup css={getCssWithoutAnimation()}>{RoutesSwitch}</TransitionGroup>
  );
};

export default RoutesTransitioner;
