import type { PartnerComponents } from 'config/types';
import type { NewOrderModule } from 'packages/shared/newOrder/types';
import { lazyWithPreload } from 'utils/bundle';
import { getPartnerSpecificComponent } from 'utils/helpers';

const InstaTimeslotSelector = lazyWithPreload(() => import('packages/instashop/newOrder/components/TimeSlotSelector'));
const DefaultComponent = lazyWithPreload(() => import('packages/shared/newOrder/components/TimeSlotSelector'));

export const timeslotSelectorComponents: PartnerComponents<NewOrderModule.ITimeSlotSelector> = {
  INSTASHOP: InstaTimeslotSelector,
  Default: DefaultComponent,
};

export const TimeSlotSelector = getPartnerSpecificComponent<NewOrderModule.ITimeSlotSelector>(timeslotSelectorComponents);
