import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import Interceptor from 'api/api.interceptor';

import { authMiddleware, reportingMiddleware, uiMiddleware } from './middleware';
import type { RootState } from './reducers/rootReducer';
import rootReducer from './reducers/rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware, uiMiddleware, reportingMiddleware),
});

// add axios interceptors
Interceptor(store);

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;

export default store;
