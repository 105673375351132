import type { Order } from 'api/types/order.types';
import { OrderStatus } from 'api/types/order.types';
import { CancelledIcon, CompletedIcon, RightArrowIcon } from 'assets/icons';
import { WashmenOrderCheckIcon } from 'assets/icons/app';
import cx from 'classnames';
import Saperator from 'components/molecules/StyledSaperator';
import type { PartnerIcon } from 'config/types';
import { motion } from 'framer-motion';
import i18n from 'i18n/config';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { getPartnerSpecificComponent } from 'utils/helpers';

interface OrderCardProps {
  order: Order.Order;
  onPressItem?: (order: Order.Order) => void;
  isLastItem?: boolean;
}

const item = {
  show: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const StyledCompletedIcon = styled(CompletedIcon)`
  > circle {
    fill: ${({ theme }) => theme.colors.darkGreen};
  }

  > path {
    fill: ${({ theme }) => theme.colors.white};
  }
  margin-top: -2px;
`;

const completedIcons: PartnerIcon<any> = {
  APP: WashmenOrderCheckIcon,
  Default: StyledCompletedIcon,
};

const PartnerSpecificCompletedIcon = getPartnerSpecificComponent(completedIcons);

const StyledCancelledIcon = styled(CancelledIcon)`
  & > g {
    & > g {
      fill: #b6bcd1;
    }
  }
`;

const StyledTitle = styled.h4`
  color: ${({ theme }) => theme.components.pages.orderList?.label.color};
`;

const StyledStatus = styled.h4`
  color: ${({ theme }) => theme.colors.darkGreen};

  &.pickupCompleted,
  &.pickUpAssigned,
  &.received {
    color: ${({ theme }) => theme.colors.darkGreen};
  }

  &.partiallyDelivered {
    color: ${({ theme }) => theme.colors.palePeachDark};
  }

  &.completed {
    color: ${({ theme }) => theme.colors.black};
  }

  &.paymentFailed,
  &.dropoffFailed,
  &.pickupFailed {
    color: ${({ theme }) => theme.colors.crimson};
  }

  &.pendingApproval {
    color: ${({ theme }) => theme.colors.warning};
  }
  &.expire,
  &.cancelled {
    color: ${({ theme }) => theme.colors.cadetBlue};
  }
`;

const OrderCard = ({ order, onPressItem, isLastItem }: OrderCardProps) => {
  const { t } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const getDateAndStatus: () => string = () =>
    (
      ({
        [OrderStatus.CANCELLED]: `${order.cancellationDate ?? order.statusText}`,
        [OrderStatus.ORDER_EXPIRED]: `${order.cancellationDate ?? order.statusText}`,
        [OrderStatus.COMPLETED]: `${t('Completed')} ${order.completionFormattedDate}`,
      }) as { [key: string]: string }
    )[order.statusLabel] ?? order.statusText;

  const renderOrderStatusIcon = useCallback((orderStatus: OrderStatus) => {
    switch (orderStatus) {
      case OrderStatus.COMPLETED: {
        return <PartnerSpecificCompletedIcon />;
      }
      case OrderStatus.CANCELLED: {
        return <StyledCancelledIcon />;
      }
      case OrderStatus.PICKUP_FAILED: {
        return <StyledCancelledIcon />;
      }
      case OrderStatus.ORDER_EXPIRED: {
        return <StyledCancelledIcon />;
      }
      default:
        return null;
    }
  }, []);

  const onClick = useCallback(() => onPressItem?.(order), []);

  return (
    <motion.div className="my-1" variants={item} whileTap={{ scale: 0.95 }}>
      <div onClick={onClick} className="d-flex justify-content-center align-items-center" aria-hidden="true">
        <div>
          <StyledTitle className="font-weight-normal text-capitalize">
            {t(`ordersPage.${order.serviceLine}`)}
            <span className="font-weight-light"> {order.alphaId || 'CXHTY'}</span>
            <div className="margin-start-2 d-inline-block">{renderOrderStatusIcon(order.statusLabel)}</div>
          </StyledTitle>
          <StyledStatus
            className={cx('d-flex font-weight-normal mb-0', {
              received: order.statusLabel === OrderStatus.RECEIVED,
              pickUpAssigned: order.statusLabel === OrderStatus.PICKUP_ASSIGNED,
              partiallyDelivered: order.statusLabel === OrderStatus.ORDER_PARTIALLY_DELIVERED,
              pickupCompleted: order.statusLabel === OrderStatus.PICKUP_COMPLETE,
              completed: order.statusLabel === OrderStatus.COMPLETED,
              paymentFailed: order.statusLabel === OrderStatus.PAYMENT_FAILED,
              pickupFailed: order.statusLabel === OrderStatus.PICKUP_FAILED,
              dropoffFailed: order.statusLabel === OrderStatus.DROPOFF_FAILED,
              pendingApproval: order.statusLabel === OrderStatus.ITEMS_PENDING_APPROVAL,
              cancelled: order.statusLabel === OrderStatus.CANCELLED,
              expire: order.statusLabel === OrderStatus.ORDER_EXPIRED,
            })}
          >
            {getDateAndStatus()}
          </StyledStatus>
        </div>
        <div className="margin-start-auto align-self-center">
          <RightArrowIcon className={isRTL ? 'transform-180' : ''} />
        </div>
      </div>
      {!isLastItem && <Saperator />}
    </motion.div>
  );
};

export default memo(OrderCard);
