import { ServiceLine } from 'api/types/service.types';
import { defaultCheckoutConfig, defaultNewOrderConfig, defaultOrderDetailsConfig } from './defaultConfigs';

import { Config } from './types';
import i18next from 'i18next';

const config: Config = {
  disablePayment: false,
  disablePromocode: false,
  showHomeDownloadAppBanner: true,
  shouldEnableRouteTransition: true,
  showEstimatorTotalOnFooter: true,
  showFooterBackgroundColor: true,
  showPricingButtonOnInstructionsFooter: true,
  showContinueButtonOnEstimator: true,
  showDriverInstructionsOnPickupDropoff: true,
  showGetBagsMessageOnPickupDropoff: true,
  hidePricingButtonIcon: true,
  showBackButton: true,
  services: [
    {
      name: i18next.t('homePage.dryCleanLaundry', 'Dry Clean & Laundry'),
      service: ServiceLine.LAUNDRY_SERVICE,
    },
    {
      name: i18next.t('homePage.shoeBagCare', 'Shoe & Bag Care'),
      service: ServiceLine.SHOE_SERVICE,
    },
  ],
  modules: {
    checkout: defaultCheckoutConfig,
    newOrder: {
      ...defaultNewOrderConfig,
      dropoffs: {
        hasSupportForMultipleDropoffs: true,
        hasSupportForPricingPageBottomSheet: true,
      },
      instructions: {
        supportBadgesLayoutForFolding: true,
        allowClearAllForFolding: true,
      },
      steps: {
        newUserStepsCount: 2,
        returningUserStepsCount: 3,
        pickAddressOnAddStep: false,
      },
    },
    orderDetails: defaultOrderDetailsConfig,
  },
};

export default config;
