import type { PricingList } from 'api/types/pricing.types';
import type { ServiceLine } from 'api/types/service.types';
import type { PreloadableComponent } from 'utils/bundle/bundle.types';

type API = 'authenticate' | 'addAddress' | 'initiatePayment' | 'fixPayment';

export interface NewOrderConfig {
  address: {
    showStep: boolean;
  };
  steps: {
    newUserStepsCount: number;
    returningUserStepsCount: number;
    pickAddressOnAddStep?: boolean;
  };
  footer: {
    showBackground: boolean;
    footerButtons: {
      showArrow: boolean;
    };
  };
  dropoffs?: {
    hasSupportForMultipleDropoffs?: boolean;
    hasSupportForPricingPageBottomSheet?: boolean;
  };
  instructions?: {
    supportBadgesLayoutForFolding?: boolean;
    allowClearAllForFolding?: boolean;
  };
}

export interface CheckoutConfig {
  promptType?: 'toast' | 'status';
  prompts: {
    success: string;
  };
  paymentMethod?: {
    listGroupVariants?: 'flush';
    paymentMethodQuestion?: string;
    paymentMethodHeading?: string;
  };
}

export interface OrderDetailsConfig {
  hideBottomSheets?: boolean;
}
export interface CleanAndPressDiscount {
  text?: string;
  boldText?: string;
}

interface Services {
  name: string;
  service: ServiceLine;
}

export interface Config {
  excludeBags?: PricingList.ServiceTypes[];
  showSelectServicesOnHomePage?: boolean;
  pricingSubtitle?: string;
  isAuthDisabled?: boolean;
  hideHeader?: boolean;
  showHomeDownloadAppBanner?: boolean;
  headerMarginRight?: number;
  deniesInlineMediaPlayback?: boolean;
  api?: Partial<Record<API, Function>>;
  disablePayment?: boolean;
  shouldShowPaymentSheetIfMissing?: boolean;
  disablePromocode?: boolean;
  hideBackButton?: boolean;
  paymentIconColored?: JSX.Element;
  paymentIconWhite?: JSX.Element;
  paymentIconBlack?: JSX.Element;
  paymentButtonText?: string;
  donotShowAddPaymentSheetOnFixPayment?: boolean;
  plusIcon?: JSX.Element;
  hideCarousel?: boolean;
  addressFlow?: 'default' | INTEGRATION_PARTNERS;
  showAddressSheetBackButton?: boolean;
  showBackButton?: boolean;
  showNewHeaderbackButton?: boolean;
  showPricingRoundedBackButton?: boolean;
  hidePricingTopBarButton?: boolean;
  shouldEnableRouteTransition?: boolean;
  twoDecimalPricingValues?: boolean;
  showContinueButtonOnEstimator?: boolean;
  showFooterBackgroundColor?: boolean;
  showLastUsedAddressOnly?: boolean;
  showPricingButtonOnInstructionsFooter?: boolean;
  showDriverInstructionsOnPickupDropoff?: boolean;
  showGetBagsMessageOnPickupDropoff?: boolean;
  showNewPaymentProcessInfo?: boolean;
  hidePricingButtonIcon?: boolean;
  hasExcludedVat?: boolean;
  showEstimatorTotalOnFooter?: boolean;
  shoesAndBagPricingUrl?: string;
  modules: {
    newOrder: NewOrderConfig;
    checkout: CheckoutConfig;
    orderDetails: OrderDetailsConfig;
  };
  services?: Services[];
}

export type PartnerComponents<T> = {
  [x in INTEGRATION_PARTNERS]?: PreloadableComponent<T>;
} & {
  Default: PreloadableComponent<T>;
};

export type PartnerIcon<T> = {
  [x in INTEGRATION_PARTNERS]?: React.FC<T>;
} & {
  Default: React.FC<T>;
};

export type PickKey<T, K extends keyof T> = Extract<keyof T, K>;

// TODO: add more integration partners
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum INTEGRATION_PARTNERS {
  CAREEM = 'CAREEM',
  RIZEK = 'RIZEK',
  KAIZEN = 'KAIZEN',
  SONDER = 'SONDER',
  APP = 'APP',
  INSTASHOP = 'INSTASHOP',
  ALLDAY = 'ALLDAY',
}
