import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { careemScreenEvent } from 'utils/careem/create-careem-screen';

export default function useCareemEvents() {
  const location = useLocation();

  useEffect(() => {
    // Trigger the analytics function with the current route path
    careemScreenEvent(`${location.pathname} Page`);
  }, [location.pathname]);
}
