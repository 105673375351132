import React, { useEffect } from 'react';

import type { PreloadableComponent } from './bundle.types';

interface PreloadComponentsProps<T> {
  components: Array<PreloadableComponent<T & any>>;
  delay?: number;
}

const withComponentPreloading =
  <P extends object>({ components, delay = 0 }: PreloadComponentsProps<P>) =>
  (WrappedComponent: React.ComponentType<P>) => {
    const WithComponentPreloading: React.FC<P> = (props) => {
      useEffect(() => {
        setTimeout(() => {
          // Preload components when the HOC is mounted
          components.forEach((Component) => {
            if (Component.preload) {
              Component.preload();
            }
          });
        }, delay);
      }, []);

      return <WrappedComponent {...props} />;
    };

    return WithComponentPreloading;
  };

export { withComponentPreloading };
