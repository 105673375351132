import type { Middleware } from '@reduxjs/toolkit';
import { WASHMEN_CUSTOMER_API } from 'api/config';
import type { Customer } from 'api/types/user.types';
import { identifyUser } from 'lib/hotjar';
import MessagingService from 'wrappers/messaging';
import { clearUserProfile, notifyErrorObject, setUserProfile } from 'wrappers/reporting';

import { login, logout, refreshTokenAction, registerUser, setAuth, setPartnerId, verifyToken } from '../slices/authSlice';

export const authMiddleware: Middleware = () => (next) => (action) => {
  switch ((action as any).type) {
    case verifyToken.fulfilled.type:
    case registerUser.fulfilled.type:
    case refreshTokenAction.fulfilled.type:
    case setAuth.type:
    case login.fulfilled.type: {
      const { authToken, refreshToken, user } = (action as any).payload as Customer.LoginRootResponse;
      // update API headers
      WASHMEN_CUSTOMER_API.defaults.headers.common.Authorization = `Bearer ${authToken}`;

      // update local storage and store auth data
      localStorage.setItem('token', authToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(user));

      const userData = {
        id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      };

      // Error Reporting
      setUserProfile(userData);
      identifyUser(userData);

      // update messaging service details
      // ${user.firstName} ${user.lastName}
      if (user.isIntercomIdentified) {
        MessagingService.getInstance().update({
          email: user.email,
          user_id: user.id,
          name: '  ',
          phone: user.phone,
        });
      }

      next(action);
      break;
    }
    case refreshTokenAction.rejected.type:
    case logout.fulfilled.type: {
      delete WASHMEN_CUSTOMER_API.defaults.headers.common.Authorization;

      const partnerId = sessionStorage.getItem('partner_id');

      notifyErrorObject({
        name: `removing auth token for user partner:${partnerId}`,
        message: 'removing auth token for user',
      });

      // clear local storage
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');

      // destroy intercom session if it exists
      MessagingService.destroy();

      // clear reporting
      clearUserProfile();

      next(action);
      break;
    }
    case setPartnerId.toString(): {
      sessionStorage.setItem('partner_id', (action as any).payload);
      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default authMiddleware;
