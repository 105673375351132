import type { TimeSlots } from 'api/types/timeSlot.types';
import { BackArrow } from 'assets/icons';
import Button from 'components/atoms/Button';
import Footer from 'components/molecules/Footer';
import HeaderBackButton from 'components/molecules/HeaderBackButton';
import SpinnerLoader from 'components/molecules/Loaders/spinnerLoader';
import PageHeading from 'components/molecules/PageHeading';
import Saperator from 'components/molecules/StyledSaperator';
import { useConfig } from 'context/app-config';
import { CollectionMethodSelector } from 'modules/newOrder/components/CollectionMethodSelector';
import { TimeSlotSelector } from 'modules/newOrder/components/TimeSlotSelector';
import { CollectionMethods } from 'packages/shared/newOrder/types';
import { useOrderDetailsPickupAndDropoff } from 'pages/orderDetails/hooks/useOrderDetailsPickupAndDropoff';
import { useOrderDetails } from 'pages/queries/order.queries';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

const StyledContainer = styled(Container)`
  padding-bottom: 80px;
`;

const StyledBackButton = styled.button`
  style: none;
  background: ${({ theme }) => theme.components.onboardingBottomSheet.button.backgroundColor};
  border: ${({ theme }) =>
    `${theme.components.onboardingBottomSheet.button.border.width}px solid ${theme.components.onboardingBottomSheet.button.border.color}`};
  border-radius: 40px;
  height: 48px;
  padding: 0 25px;
  margin-right: 10px;
`;

const FooterContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

export interface SelectPickupOrDropoffProps {
  description?: string;
  hasRightArrowOnly?: boolean;
}

interface RouteParams {
  action: 'PICKUP' | 'DROPOFF';
  orderTaskId?: string;
  orderId: string;
}

const UpdatePickupOrDropoff: React.FC<SelectPickupOrDropoffProps> = () => {
  const [timeslot, setTimeSlot] = useState<TimeSlots.Timeslot | undefined>();
  const location = useLocation();
  const { action, orderTaskId, orderId } = location.state as RouteParams;

  const { data: order, isLoading: isOrderLoading } = useOrderDetails(orderId);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const config = useConfig();
  const theme = useTheme();

  const {
    timeslotsData,
    isCollectionMethodAtDoor,
    preSelectedSlots,
    handleCollectionMethodSelected,
    handleTimeslotSelected,
    isLoading: isOrderUpdating,
  } = useOrderDetailsPickupAndDropoff(order!!, action, orderTaskId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(isOrderLoading || isOrderUpdating);
  }, [isOrderLoading, isOrderUpdating]);

  const [collectionMethod, setCollectionMethod] = useState(isCollectionMethodAtDoor ? CollectionMethods.AT_DOOR : CollectionMethods.IN_PERSON);

  const isContinueDisabled = useMemo(() => !timeslotsData, [timeslotsData]);

  const optionItemDescription = useMemo(
    () => t(`newOrderPage.${action}.${isCollectionMethodAtDoor ? 'AT_DOOR' : 'IN_PERSON'}.description`),
    [action, isCollectionMethodAtDoor, t]
  );

  const onMethodSelectionChanged = useCallback((selectedCollectionMethod: CollectionMethods) => {
    setCollectionMethod(selectedCollectionMethod);
  }, []);

  const Tabs = useMemo(
    () => (
      <CollectionMethodSelector
        collectionMethod={collectionMethod}
        action={action}
        allowChange
        description={optionItemDescription}
        onMethodSelectionChanged={onMethodSelectionChanged}
      />
    ),
    [action, collectionMethod, onMethodSelectionChanged, optionItemDescription]
  );

  const handleTimeslotSelectedSubmit = useCallback((selectedTimeslot: TimeSlots.Timeslot) => {
    setTimeSlot(selectedTimeslot);
  }, []);

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleContinue = useCallback(async () => {
    if (timeslot && collectionMethod) {
      await handleCollectionMethodSelected(action, collectionMethod);
      await handleTimeslotSelected(action, timeslot);

      setIsLoading(true);
      /* Task creations on driver domain tooks some time thats why we added  delay */
      setTimeout(() => {
        navigate(-1);
        setIsLoading(false);
      }, 5500);
    }
  }, [action, collectionMethod, handleCollectionMethodSelected, handleTimeslotSelected, navigate, timeslot]);

  return (
    <>
      {config?.showBackButton && (
        <div className="ml-5">
          <HeaderBackButton />
        </div>
      )}
      <StyledContainer>
        <PageHeading title={t(`common.${action}.pickUpDropOff`)} removeDefaultMarginTop={config?.showBackButton} />
        <Row>
          <Col className="p-0">
            {Tabs}
            <Saperator />
            {/* rest UI */}
            {timeslotsData ? (
              <TimeSlotSelector
                onSubmit={handleTimeslotSelectedSubmit}
                action={action}
                timeslotsData={timeslotsData}
                isAtDoor={isCollectionMethodAtDoor}
                seletedSlots={preSelectedSlots}
              />
            ) : (
              <SpinnerLoader />
            )}
          </Col>
        </Row>
        <Footer showBackgroundColor={config?.modules.newOrder.footer.showBackground}>
          <FooterContainer>
            <StyledBackButton onClick={onBack}>
              <BackArrow />
            </StyledBackButton>
            <Button
              textColor={theme.components.pages.pickupDropoff?.button?.textColor}
              backgroundColor={theme.components.pages.pickupDropoff?.button?.background}
              disabled={isContinueDisabled}
              block
              size="lg"
              onClick={handleContinue}
              isLoading={isLoading}
            >
              {t('common.continueOrConfirm')}
            </Button>
          </FooterContainer>
        </Footer>
      </StyledContainer>
    </>
  );
};

export default UpdatePickupOrDropoff;
