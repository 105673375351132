import i18n from 'i18n/config';
import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

const StyledContentLoader = styled(ContentLoader).attrs(({ theme }) => ({
  backgroundColor: theme.loaders.contentLoaders.backgroundColor,
  foregroundColor: theme.loaders.contentLoaders.foregroundColor,
}))``;

const ServiceLoader: React.FC = (props) => {
  const isRTL = i18n.dir() === 'rtl';
  return (
    <StyledContentLoader speed={0.8} width="100%" height={355} rtl={isRTL} {...props}>
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="63.01" />
      <rect x="0" y="74" rx="5" ry="5" width="100%" height="63.01" />
      <rect x="0" y="148" rx="5" ry="5" width="100%" height="63.01" />
      <rect x="0" y="222" rx="5" ry="5" width="100%" height="63.01" />
      <rect x="0" y="296" rx="5" ry="5" width="100%" height="63.01" />
      <rect x="0" y="370" rx="5" ry="5" width="100%" height="63.01" />
    </StyledContentLoader>
  );
};
export default ServiceLoader;
