import type { Order } from 'api/types/order.types';
import { NoOrdersIcon } from 'assets/icons';
import Button from 'components/atoms/Button';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';

import Footer from '../Footer';
import OrdersListLoader from '../Loaders/ordersListLoader';

interface Props {
  orders?: Order.Order[];
  children: (order: Order.Order, index: number) => JSX.Element;
  isInitialLoading?: boolean;
  loadMore?: (pageIndex: number) => any;
  hasMore?: boolean;
  emptyMessage: string;
}

const item = {
  show: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
};

// @ts-ignore
const OrdersList: React.FC<Props> = ({ orders = [], isInitialLoading = false, children, loadMore = () => {}, hasMore = false, emptyMessage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goToNewOrder = () => {
    navigate('/newOrder');
  };

  const List = useMemo(() => {
    if (isInitialLoading) {
      return [...Array(5)].map((e) => <OrdersListLoader key={e} />);
    }
    if (orders) {
      if (orders.length === 0) {
        return (
          <motion.div variants={container} initial="hidden" animate="show">
            <motion.div variants={item} className="d-flex flex-column align-items-center justify-content-center">
              <NoOrdersIcon className="my-10" />
              <p className="text-dark-blue">{emptyMessage}</p>
            </motion.div>
            <Footer>
              <Button block size="lg" onClick={goToNewOrder}>
                {t('common.placeNewOrder')}
              </Button>
            </Footer>
          </motion.div>
        );
      }
      return (
        <div>
          <InfiniteScroll
            threshold={415}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={
              <div key={0}>
                {[...Array(5)].map((e) => (
                  <OrdersListLoader key={`laoder-${e}-${e}`} />
                ))}
              </div>
            }
            useWindow={false}
          >
            <ListGroup variant="flush" as={motion.ul} variants={container} initial="hidden" animate="show" className="padding-start-0">
              {orders.map((order, index) => children(order, index))}
            </ListGroup>
          </InfiniteScroll>
        </div>
      );
    }

    return null;
  }, [orders, isInitialLoading, hasMore]);

  return List;
};

export default OrdersList;
