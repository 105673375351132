import { INTEGRATION_PARTNERS } from 'config/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPartner, setPartnerId } from 'redux-stores/slices/authSlice';
import { getClientMedia, setDefaultMedia } from 'redux-stores/slices/uiSlice';
import { SUBDOMAIN_DEFAULTS } from 'utils/isPartner';

import { useQuery } from './useQuery';

export const EXCLUDED_PARTNERS_FROM_MEDIA_REQUEST = [INTEGRATION_PARTNERS.CAREEM, INTEGRATION_PARTNERS.INSTASHOP];

/**
 *
 * @returns ok
 */
export function usePartner() {
  const query = useQuery();
  const partnerId = query.get('partner') as INTEGRATION_PARTNERS | null;
  const partner = process.env.REACT_APP_PARTNER_ID ?? useSelector(selectPartner); // get partner from store;
  const subdomain = window.location.host.split('.')[0];
  const dispatch = useDispatch();

  // for default app & subdomain, we show our header
  useEffect(() => {
    if (partner) {
      return;
    }
    if (!partnerId) {
      dispatch(setDefaultMedia());
      return;
    }
    dispatch(setPartnerId(partnerId));
  }, [dispatch, partner, partnerId]);

  useEffect(() => {
    if (SUBDOMAIN_DEFAULTS.includes(subdomain)) {
      dispatch(setPartnerId(INTEGRATION_PARTNERS.APP));
      return;
    }
    dispatch(setPartnerId(subdomain.toUpperCase().replace(/DEV-/g, '') as INTEGRATION_PARTNERS));
  }, [dispatch, subdomain]);

  useEffect(() => {
    // we are not fetching media for CAREEM or INSTASHOP, let's exclude them
    // as request send a 401 and call a refresh token call.
    if (!partner || EXCLUDED_PARTNERS_FROM_MEDIA_REQUEST.includes(partner as INTEGRATION_PARTNERS)) {
      return;
    }

    // @ts-ignore
    dispatch(getClientMedia(partner));
  }, [partner, dispatch]);
}
