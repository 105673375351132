import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

interface UserData {
  id: string;
  name: string;
  email: string;
}
interface Window {
  analytics: any;
}

declare let window: Window;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY || '',
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const { ErrorBoundary } = Sentry;

export const notifyError = (error: string) => Sentry.captureException(new Error(error));
export const notifyErrorObject = (error: any) => Sentry.captureException(error);
export const notifyWithScopeObject = (error: Error, level: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug') =>
  Sentry.withScope((scope) => {
    scope.setLevel(level);
    Sentry.captureException(error);
  });

export const trackEvent = (name: string, properties?: object) => window.analytics.track(name, properties);

export const setUserProfile: (data: UserData) => void = (data) => {
  window.analytics.identify(data.id, {
    name: data.name,
    email: data.email,
  });
  Sentry.setUser({
    id: data.id,
    email: data.email,
    username: data.name,
  });
};

export const clearUserProfile = () => {
  window.analytics.reset();
  Sentry.setUser(null);
};
