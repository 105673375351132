// TODO:

export const SUBDOMAIN_DEFAULTS = ['localhost:3000', 'app', 'dev-app'];

/**
 * @description utility function that returns if the current url is a partner url.
 * @returns false in case if app.washmen.com or dev environment
 */
export function isPartner() {
  const subdomain = window.location.host.split('.')[0];
  if (SUBDOMAIN_DEFAULTS.includes(subdomain)) {
    return false;
  }
  return true;
}
