import { CloseIcon } from 'assets/icons/';
import BackButton from 'components/molecules/BackButton';
import Header from 'components/molecules/Header';
import { OrderStepper } from 'components/molecules/Stepper';
import { useConfig } from 'context/app-config';
import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';

import { StyledBackIcon, StyledCol, StyledH2, StyledLink, StyledSubHeading } from './page.styles';

interface Props {
  title?: string;
  description?: string;
  hasBack?: boolean;
  route?: string | number;
  hideBack?: boolean;
  headerIcon?: JSX.Element;
  className?: string;
  showBackToHome?: boolean;
  showStepper?: boolean;
  activeStep?: number;
  children?: React.ReactNode;
  replaceOnBack?: boolean;
  headerProps?: {
    isShowPricingOption?: boolean;
    isShowSupportOption?: boolean;
  };
}

const initialHeaderProps = {
  isShowPricingOption: true,
  isShowSupportOption: true,
};

const Page: React.FC<Props> = ({
  title,
  description,
  hasBack,
  route,
  children,
  hideBack = false,
  headerIcon = null,
  showBackToHome = false,
  showStepper = false,
  activeStep = 1,
  className = '',
  replaceOnBack,
  headerProps = initialHeaderProps,
}) => {
  const navigate = useNavigate();
  const config = useConfig();

  const handleClose = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  const handleBack = useCallback(() => {
    if (route !== undefined) {
      navigate(route as any, { replace: Boolean(replaceOnBack) });
    } else navigate(-1);
  }, [navigate, route]);

  return (
    <Container className={className}>
      <Row>
        {headerIcon && <Col>{headerIcon}</Col>}
        {!hideBack &&
          (!config?.hideHeader || config.showNewHeaderbackButton ? (
            <Header
              onGoBackClicked={handleBack}
              isShowPricingOption={headerProps.isShowPricingOption}
              isShowSupportOption={headerProps.isShowSupportOption}
            />
          ) : (
            !config?.hideBackButton &&
            (hasBack ? (
              <BackButton className="px-4 hitSlop d-flex" onClick={handleBack} />
            ) : (
              <Button variant="link" onClick={() => handleClose()} className="px-4 hitSlop">
                <CloseIcon />
              </Button>
            ))
          ))}
        {showBackToHome && !hideBack && config?.hideBackButton && (
          <Col>
            <StyledLink to="/home">
              <div className="pt-3 d-flex align-items-center">
                <StyledBackIcon />
                Home
              </div>
            </StyledLink>
          </Col>
        )}
      </Row>

      {showStepper && (
        <StyledCol xs={-12} l>
          <OrderStepper showBack={config?.showBackButton} activeStep={activeStep} />
        </StyledCol>
      )}

      {title && (
        <Row>
          <Col xs={12}>
            <StyledH2 className="mb-3">{title}</StyledH2>
          </Col>
        </Row>
      )}

      {description && (
        <Row>
          <Col xs={12}>
            <StyledSubHeading className="font-weight-light text-gray-dark">{description}</StyledSubHeading>
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12}>{children}</Col>
      </Row>
    </Container>
  );
};

export default Page;
