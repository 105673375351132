import { useMutation } from '@tanstack/react-query';
import type { Customer } from 'api/types/user.types';
import { useConfig } from 'context/app-config';

export function useAuthentication({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: Customer.LoginRootResponse & { redirectRoute: string }) => void;
  onError?: (error: any) => void;
}) {
  const config = useConfig();

  return useMutation({
    mutationFn: async (payload: { code: string; language?: string; accessToken?: string; page?: string; orderId?: string }) =>
      config?.api?.authenticate?.(payload.code, payload.language, payload.accessToken, payload.page, payload.orderId),
    onSuccess,
    onError,
  });
}
