import { WASHMEN_CUSTOMER_API } from 'api/config';
import type { Order } from 'api/types/order.types';
import type { Preferences } from 'api/types/preferences.types';
import type { Customer } from 'api/types/user.types';
import type { LanguageCodes } from 'i18n/constants';

export async function fetchAllOrders(lastEvaluatedId = null, pageSize = 10) {
  let url = `/v2/list-orders?pageSize=${pageSize}&sort=DESC`;
  if (lastEvaluatedId) {
    url = `${url}&lastEvaluatedId=${lastEvaluatedId}`;
  }
  const { data } = await WASHMEN_CUSTOMER_API.get<{
    orders: Order.Order[];
    meta: Order.PaginationMeta;
  }>(url);

  return data;
}

export async function fetchActivePastOrdersAPI() {
  return (await WASHMEN_CUSTOMER_API.get<Order.ActivePastOrdersRootObject>('/list-active-past-orders')).data;
}

export async function fetchCustomerAPI() {
  const { data } = await WASHMEN_CUSTOMER_API.get<Customer.UserRootResponse>('/users');
  return data;
}

export async function fetchCustomerPreferencesAPI() {
  return (await WASHMEN_CUSTOMER_API.get<Preferences.RootObject>('/orders/preferences')).data;
}

export async function updateLanguageAPI(data: { language: LanguageCodes }) {
  return (await WASHMEN_CUSTOMER_API.put<{ user: Customer.Customer }>('/customers/language', data)).data;
}

export async function updateUserAPI(data: Customer.UpdateUserInputs) {
  return (await WASHMEN_CUSTOMER_API.put<{ user: Customer.Customer }>('/users', data)).data;
}
