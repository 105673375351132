import { BackIcon, MenuIcon } from 'assets/icons';
import { CircleDollarIcon, SupportIcon } from 'assets/icons/app';
import cx from 'classnames';
import PricingSheet from 'packages/shared/pricing/PricingSheet';
import SupportSheet from 'packages/shared/support/SupportSheet';
import type { FC } from 'react';
import React, { memo, useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { selectCustomer } from 'redux-stores/slices/authSlice';
import { selectLogoImage } from 'redux-stores/slices/uiSlice';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  background-color: ${({ theme }) => theme.components.header.backgroundColor};
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 15px;
`;

const StyledColRow = styled(Col)`
  gap: 12px;
`;

const StyledBackIcon = styled(BackIcon)`
  width: 20px;
  height: 20px;
  g > g > g > g > path {
    fill: ${({ theme }) => theme.components.headerBackButton.color};
  }
`;

const StyledImage = styled(Image)`
  width: 32px;
  height: 32px;
`;

const StyledHeaderText = styled.h1`
  color: ${({ theme }) => theme.colors.deepPurple};
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0.4px;
  text-wrap: nowrap;
`;

interface Props {
  isDisplayUserName?: boolean;
  isShowMenuOption?: boolean;
  onGoBackClicked?: () => void;
  isShowSupportOption?: boolean;
  isShowPricingOption?: boolean;
}

const Header: FC<Props> = ({ isDisplayUserName, isShowMenuOption, onGoBackClicked, isShowSupportOption, isShowPricingOption }) => {
  const logo = useSelector(selectLogoImage);
  const customer = useSelector(selectCustomer);
  const [showPricingSheet, setShowPricingSheet] = useState(false);
  const [showSupportSheet, setShowSupportSheet] = useState(false);

  const togglePricingSheet = useCallback(() => setShowPricingSheet((prev) => !prev), []);

  const handleClosePricingSheet = useCallback(() => {
    setShowPricingSheet(false);
  }, []);

  const toggleSupportSheet = useCallback(() => setShowSupportSheet((prev) => !prev), []);

  const handleCloseSupportSheet = useCallback(() => {
    setShowSupportSheet(false);
  }, []);

  return (
    <StyledContainer>
      <Row as="header" className={cx('justify-content-between')}>
        <StyledColRow className="d-flex" xs={7}>
          {logo && !onGoBackClicked && <StyledImage src={logo} />}
          {onGoBackClicked && (
            <Button className="p-0 m-0" variant="link" onClick={onGoBackClicked}>
              <StyledBackIcon />
            </Button>
          )}
          {isDisplayUserName && customer && <StyledHeaderText>Hey {`${customer.firstName},`}</StyledHeaderText>}
        </StyledColRow>
        <StyledColRow className="d-flex mr-0 pt-1" xs="auto">
          {isShowMenuOption && (
            <Button className="p-0" variant="link" as={NavLink} to="/profile">
              <MenuIcon />
            </Button>
          )}
          {isShowPricingOption && (
            <Button className="p-0" variant="button" onClick={togglePricingSheet}>
              <CircleDollarIcon />
            </Button>
          )}
          {isShowSupportOption && (
            <Button className="p-0" variant="button" onClick={toggleSupportSheet}>
              <SupportIcon />
            </Button>
          )}
        </StyledColRow>
      </Row>
      <PricingSheet show={showPricingSheet} onClose={handleClosePricingSheet} />
      <SupportSheet show={showSupportSheet} onClose={handleCloseSupportSheet} />
    </StyledContainer>
  );
};

export default memo(Header);
