/* eslint-disable react/no-danger */
import { ExpiredLinkIcon } from 'assets/icons';
import Page from 'layout/page';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {}

const ExpiredToken: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <div className="d-flex flex-column align-items-center mt-10">
        <ExpiredLinkIcon />
        <h2 className="mt-8">{t('verifyPage.linkExpired')}</h2>
        <p className="h3 font-weight-light mb-9 text-center" dangerouslySetInnerHTML={{ __html: t('verifyPage.callCustomerService') }} />
      </div>
    </Page>
  );
};

export default ExpiredToken;
