import type { ICollectionMethodSelectorProps } from 'components/organisms/CollectionMethodSelector';
import type { PartnerComponents } from 'config/types';
import { lazyWithPreload } from 'utils/bundle';
import type { PreloadableComponent } from 'utils/bundle/bundle.types';
import { getPartnerSpecificComponent } from 'utils/helpers';

const InstaCollectionMethodSelector = lazyWithPreload(() => import('packages/instashop/newOrder/components/CollectionMethodSelector'));
const DefaultCollectionMethodSelector = lazyWithPreload(() => import('components/organisms/CollectionMethodSelector'));

export const components: PartnerComponents<ICollectionMethodSelectorProps> = {
  INSTASHOP: InstaCollectionMethodSelector as unknown as PreloadableComponent<ICollectionMethodSelectorProps>,
  Default: DefaultCollectionMethodSelector as unknown as PreloadableComponent<ICollectionMethodSelectorProps>,
};

export const CollectionMethodSelector = getPartnerSpecificComponent<ICollectionMethodSelectorProps>(components);
