import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchDropoffTimeSlotsAPI, fetchPickupTimeSlotsAPI } from 'api/timeslot';
import type { ServiceLine } from 'api/types/service.types';
import type { TimeSlots } from 'api/types/timeSlot.types';
import { useConfig } from 'context/app-config';
import { setDropOffTime, setDropOffTimeForServiceLine, setPickupTime } from 'pages/newOrder/newOrder.slice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { RootState } from 'redux-stores/reducers/rootReducer';
import store from 'redux-stores/store';
import { notifyErrorObject } from 'wrappers/reporting';

import { REACT_QUERY_KEYS } from '.';

// we can use pickup mutation for preloading pickup timeslots for specific addressId
export function usePickupTimeSlotsMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ addressId }: { addressId: string }) =>
      fetchPickupTimeSlotsAPI({
        addressId: addressId!!,
      }),
    onSuccess: (data, { addressId }) => {
      queryClient.setQueryData([REACT_QUERY_KEYS.timeslotKeys.PICKUP_TIMESLOT, addressId], data);
    },
  });
}

export function usePickupTimeSlots({
  addressId,
  enabled = true,
  onSuccess,
  onError,
}: {
  addressId?: string;
  enabled?: boolean;
  onSuccess?: (response: TimeSlots.PickupRootObject) => void;
  onError?: (err: unknown) => void;
}) {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: [REACT_QUERY_KEYS.timeslotKeys.PICKUP_TIMESLOT, addressId],
    queryFn: async () =>
      fetchPickupTimeSlotsAPI({
        addressId: addressId!!,
      }),
    enabled: addressId !== undefined && enabled,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    onSuccess: useCallback(
      (data: TimeSlots.PickupRootObject) => {
        dispatch(setPickupTime(data));
        onSuccess?.(data);
      },
      [dispatch, onSuccess]
    ),
    onError: (error) => {
      notifyErrorObject({
        name: REACT_QUERY_KEYS.timeslotKeys.PICKUP_TIMESLOT,
        requestInfo: { url: `/v3/timeslots/pickup`, body: addressId },
        error,
        responseInfo: error,
      });
      onError?.(error);
    },
    select: useCallback((data: TimeSlots.PickupRootObject) => {
      const {
        auth: { customer },
      } = store.getState() as RootState;

      return { ...data, isFirstOrder: !customer?.hasCompletedOrders };
    }, []),
  });
}

export function useDropOffTimeSlots(payload: {
  addressId?: string;
  pickupDate?: string;
  orderId?: string;
  serviceLine?: ServiceLine;
  onSuccess?: (data: TimeSlots.DropoffRootObject) => void;
  onError?: (err: unknown) => void;
  enabled?: boolean;
}) {
  const dispatch = useDispatch();
  const config = useConfig();
  const { addressId, orderId, pickupDate, serviceLine, onSuccess, onError, enabled = true } = payload;

  return useQuery({
    queryKey: [REACT_QUERY_KEYS.timeslotKeys.DROPOFF_TIMESLOT, pickupDate, addressId, orderId, serviceLine],
    queryFn: () =>
      fetchDropoffTimeSlotsAPI({
        addressId: addressId!,
        pickupDate: pickupDate!,
        orderId,
        serviceLine,
      }),
    enabled: Boolean(pickupDate && addressId) && enabled,
    onSuccess: useCallback(
      (data: TimeSlots.DropoffRootObject) => {
        // if order id is there, no need to update newOrder timeslots
        // have orderId means it's a order details page
        if (!orderId) {
          // If the partner supports multiple dropoffs, we need to set the dropoff time for the specific service line
          if (config?.modules.newOrder.dropoffs?.hasSupportForMultipleDropoffs && serviceLine) {
            dispatch(setDropOffTimeForServiceLine({ ...data, serviceLine }));
          } else {
            dispatch(setDropOffTime(data));
          }
        }
        onSuccess?.(data);
      },
      [config?.modules.newOrder.dropoffs?.hasSupportForMultipleDropoffs, dispatch, onSuccess, orderId, serviceLine]
    ),
    retryOnMount: false,
    onError: (error) => {
      notifyErrorObject({
        name: REACT_QUERY_KEYS.timeslotKeys.PICKUP_TIMESLOT,
        requestInfo: {
          url: `/v3/timeslots/dropoff`,
          body: { addressId, orderId, pickupDate, onSuccess, onError, enabled },
        },
        error,
        responseInfo: error,
      });
      onError?.(error);
    },
    select: useCallback((data: TimeSlots.DropoffRootObject) => {
      const {
        auth: { customer },
      } = store.getState() as RootState;

      return {
        ...data,
        isFirstOrder: !customer?.hasCompletedOrders,
      } as TimeSlots.DropoffRootObject & { isFirstOrder: boolean };
    }, []),
  });
}
