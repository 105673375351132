import * as React from 'react';
import styled from 'styled-components';

const StyledSaperator = styled.div`
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  align-self: stretch;
  background: rgba(217, 217, 217, 0.3);
  margin-top: 5px;
`;

interface SaperatorProps {
  style?: React.CSSProperties;
}

const Saperator = ({ style }: SaperatorProps) => <StyledSaperator style={style} />;

export default Saperator;
