import { BackIcon } from 'assets/icons';
import { useConfig } from 'context/app-config';
import { motion } from 'framer-motion';
import useFlags from 'hooks/useFlags';
import React, { memo, useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledStepper = styled(motion.div)`
  height: 4px;
  background-color: ${({ theme }) => theme.stepper?.borderColor};
  border-radius: 2px;
`;

const StyledMotionDiv = styled(motion.div)`
  background-color: ${({ theme }) => theme.stepper?.backgroundColor};
  height: 4px;
  width: 20px;
  border-radius: 2px;
`;

const StyledBackButtonCol = styled(Col)`
  margin-top: -0.75rem;
`;

const StyledBackButton = styled.button`
  background: transparent;
  border: unset;
  padding: 0 5px;
  margin-right: 10px;
`;

const StyledColumn = styled(Col)`
  flex: 1;
`;

const StyledBackIcon = styled(BackIcon)`
  g > g > g > g > path {
    fill: ${({ theme }) => theme.colors.pink};
  }
`;

interface OrderStepperProps {
  activeStep?: number;
  showBack?: boolean;
}

export const OrderStepper = memo(({ activeStep = 1, showBack = false }: OrderStepperProps) => {
  const { enableSelectServicesFlow } = useFlags();
  const config = useConfig();
  const navigate = useNavigate();

  const totalSteps = useMemo(
    () => (enableSelectServicesFlow ? config?.modules.newOrder.steps.newUserStepsCount!! : config?.modules.newOrder.steps.returningUserStepsCount!!),
    [config?.modules.newOrder.steps.newUserStepsCount, config?.modules.newOrder.steps.returningUserStepsCount, enableSelectServicesFlow]
  );

  const onBack = useCallback(() => {
    if (activeStep === 0) {
      navigate('/home', { replace: true });
    } else {
      navigate(-1);
    }
  }, [activeStep, navigate]);

  return (
    <Row>
      {showBack && (
        <StyledBackButtonCol xs={1}>
          <StyledBackButton onClick={onBack}>
            <StyledBackIcon />
          </StyledBackButton>
        </StyledBackButtonCol>
      )}
      {totalSteps > 0 && (
        <StyledColumn xs={showBack ? 11 : 12} className="align-items-center">
          <motion.div className="d-flex align-items-center position-relative">
            <StyledMotionDiv className="w-100" />
            <StyledStepper
              className="position-absolute"
              animate={{ width: `${((activeStep + 1) / totalSteps) * 100}%` }}
              transition={{ duration: 0.4, ease: [0.43, 0.13, 0.23, 0.96] }}
            />
          </motion.div>
        </StyledColumn>
      )}
    </Row>
  );
});
