import { BackIcon } from 'assets/icons/';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledH2 = styled.h2`
  font-size: ${({ theme }) => theme.layout?.page?.heading?.fontSize}px;
  font-weight: ${({ theme }) => theme.layout?.page?.heading?.fontWeight};
  color: ${({ theme }) => theme.layout?.page?.heading?.textColor};
`;

export const StyledCol = styled(Col)`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

export const StyledSubHeading = styled.p`
  font-size: ${({ theme }) => theme.layout?.page?.subHeading?.fontSize}px;
  font-weight: ${({ theme }) => theme.layout?.page?.subHeading?.fontWeight};
  color: ${({ theme }) => theme.layout?.page?.subHeading?.textColor};
`;

export const StyledBackIcon = styled(BackIcon)`
  & > g {
    & > g {
      & > g {
        & > g {
          & path {
            fill: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  &:hover,
  &:focus,
  &:active,
  &.btn-primary:not(:disabled):not(.disabled):active {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
`;
