import type { CheckoutConfig, NewOrderConfig, OrderDetailsConfig } from './types';

const defaultNewOrderConfig: NewOrderConfig = {
  address: {
    showStep: false,
  },
  steps: {
    newUserStepsCount: 3,
    returningUserStepsCount: 3,
    pickAddressOnAddStep: false,
  },
  footer: {
    showBackground: false,
    footerButtons: {
      showArrow: true,
    },
  },
};

const defaultCheckoutConfig: CheckoutConfig = {
  promptType: 'toast',
  prompts: {
    success: 'newOrderPage.promocodeAdded',
  },
  paymentMethod: {
    listGroupVariants: 'flush',
  },
};

const defaultOrderDetailsConfig: OrderDetailsConfig = {
  hideBottomSheets: false,
};

export { defaultCheckoutConfig, defaultNewOrderConfig, defaultOrderDetailsConfig };
