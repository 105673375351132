import cx from 'classnames';
import React from 'react';
import type { ButtonProps } from 'react-bootstrap/Button';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

import styles from './styles.module.scss';

interface Props extends ButtonProps {
  selected?: boolean;
  activeClassName?: string;
  inactiveClassName?: string;
}

const serviceTextColors = {
  WASH_FOLD: '#fff',
  HOME_CARE: '#fff',
  CLEAN_PRESS: '#291482',
  PRESS: '#fff',
  SHOE_CLEANING: '#fff',
};

const serviceBackgroundColors = {
  WASH_FOLD: '#291482',
  HOME_CARE: '#FF81FF',
  CLEAN_PRESS: '#9BEC09',
  PRESS: '#2D2E2E',
  SHOE_CLEANING: '#FF7400',
};

const StyledButton = styled(Button)<{
  serviceName?: 'WASH_FOLD' | 'HOME_CARE' | 'CLEAN_PRESS' | 'PRESS' | 'SHOE_CLEANING';
}>`
  &:active,
  &:hover,
  &.selected {
    box-shadow: none;
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme, serviceName }) => (serviceName ? serviceTextColors[serviceName] : theme.text.success)} !important;
    background-color: ${({ serviceName, theme }) =>
      serviceName ? serviceBackgroundColors[serviceName] : theme.components.pages.orderList?.activeButton?.backgroundColor} !important;
    border-color: ${({ theme, serviceName }) => (serviceName ? serviceBackgroundColors[serviceName] : theme.colors.primary)} !important;
  }
`;

const RoundedButton: React.FC<Props> = ({ selected, className, activeClassName = styles.selected, ...props }) => (
  <StyledButton
    variant="outline-primary"
    {...props}
    serviceName={activeClassName}
    className={cx(styles.roundedBtn, styles.customBtn, className, 'py-0 margin-end-2', {
      selected,
    })}
  />
);

export default RoundedButton;
