import type { ServiceLine } from 'api/types/service.types';
import type { TimeSlots } from 'api/types/timeSlot.types';
import type { SelectedSlots } from 'packages/shared/newOrder/hooks/useTimeSlotSelector';

export enum CollectionMethods {
  AT_DOOR = 'AT_DOOR',
  IN_PERSON = 'IN_PERSON',
}

export declare namespace NewOrderModule {
  export interface SelectPickupOrDropoffProps {
    action?: 'PICKUP' | 'DROPOFF';
    description?: string;
    hasRightArrowOnly?: boolean;
    serviceLine?: ServiceLine;
    serviceLineDependancy?: ServiceLine;
    isStandAlone?: boolean;
    onClick?: (type: TimeSlots.Timeslot) => void;
    timeSlotsData?: TimeSlots.TimeslotsData;
    isLoading?: boolean;
  }

  export interface ITimeSlotSelector {
    show?: boolean;
    onClose?: () => any;
    onSubmit?: (type: TimeSlots.Timeslot) => any;
    action?: 'PICKUP' | 'DROPOFF';
    timeslotsData?: TimeSlots.TimeslotsData;
    selectedDateTimeSlot?: TimeSlots.Timeslot;
    isAtDoor?: boolean;
    seletedSlots?: SelectedSlots;
    isShowServiceTitle?: boolean;
    serviceLine?: ServiceLine;
    isLoading?: boolean;
  }

  export interface IPickupDropoff {
    action?: 'PICKUP' | 'DROPOFF';
    isStandAlone?: boolean;
    onPress?: (type: TimeSlots.Timeslot) => any;
  }
}
