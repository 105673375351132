import { useUpdateIntercomID } from 'pages/queries/intercom.queries';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCustomer } from 'redux-stores/slices/authSlice';
import MessagingService from 'wrappers/messaging';

function useMessaging() {
  const { mutate } = useUpdateIntercomID();
  const user = useSelector(selectCustomer);

  const show = React.useCallback(() => {
    MessagingService.getInstance().show();
    if (!user?.isIntercomIdentified) {
      setTimeout(() => {
        mutate();
      }, 3000);
    }
  }, [user?.isIntercomIdentified, mutate]);

  return {
    show,
    update: (settings: Intercom_.IntercomSettings) => MessagingService.getInstance().update(settings),
  };
}

export default useMessaging;
