import defaultCOnfig from 'config/app.config';
import type { Config } from 'config/types';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPartner } from 'redux-stores/slices/authSlice';

const ConfigContext = createContext<{
  config?: Config;
  updateConfig?: (newValue: Config) => void;
}>({});

interface ConfigProviderProps {
  children: React.ReactNode;
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const partnerId = process.env.REACT_APP_PARTNER_ID ?? useSelector(selectPartner);
  const [config, setConfig] = useState<Config>(defaultCOnfig);

  // Define a function to update the value
  const updateConfig = useCallback((newValue: Config) => {
    setConfig(newValue);
  }, []);

  useEffect(() => {
    if (!partnerId) return;
    const fetchConfigFile = async () => {
      try {
        const file = (await import(`config/${partnerId.toLowerCase()}.config`)).default;
        setConfig(file);
      } catch (e) {
        setConfig(defaultCOnfig);
      }
    };

    fetchConfigFile();
  }, [partnerId]);

  return useMemo(
    () => (
      <ConfigContext.Provider
        value={{
          config,
          updateConfig,
        }}
      >
        {children}
      </ConfigContext.Provider>
    ),
    [config, updateConfig]
  );
};

export const useConfig = () => {
  const config = React.useContext(ConfigContext);
  return config.config;
};

export const useUpdateConfig = () => {
  const config = React.useContext(ConfigContext);
  return config.updateConfig;
};

export default ConfigProvider;
