import { WASHMEN_CUSTOMER_API } from './config';

/**
 * @param clientId client name should exist in strapi
 * @returns logo, header, & video to be used
 */
export async function getClientMediaAPI(clientId: string) {
  const { data } = await WASHMEN_CUSTOMER_API.get<{
    clientMedia: { header?: string; video?: string; logo?: string };
  }>(`/clients/${clientId}/media`);
  return data;
}
