import { WASHMEN_CUSTOMER_API } from 'api/config';
import type { Communications } from 'api/types/communication.types';
import type { Customer } from 'api/types/user.types';

export async function sendVerificationCodeAPI(phone: string, recaptchaToken: string, communicationMethod: Communications.Methods) {
  return (await WASHMEN_CUSTOMER_API.post('/auth/send-verification-code', { phone, recaptchaToken, communicationMethod })).data;
}

export async function verifyCodeAPI(tempCode: string, phone: string) {
  interface Response {
    deformedEmail?: string;
    infoCode?: {
      message: string;
      code: number;
    };
  }
  return WASHMEN_CUSTOMER_API.post<Response>('/auth/verify-temp-code', { phone, tempCode });
}

export async function editPhoneNumberAPI(oldPhone: string, phone: string, tempCode: string) {
  return (
    await WASHMEN_CUSTOMER_API.put<{ user: Customer.Customer }>('/users/phone', {
      oldPhone,
      tempCode,
      phone,
    })
  ).data.user;
}
