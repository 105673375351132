import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { loginAPI, logoutAPI, refreshTokenAPI, registerUserAPI, verifyAuthTokenAPI } from 'api/auth';
import { isAxiosError, WASHMEN_CUSTOMER_API } from 'api/config';
import type { Auth } from 'api/types/auth.types';
import type { Customer } from 'api/types/user.types';
import { INTEGRATION_PARTNERS } from 'config/types';
import type { LanguageCodes } from 'i18n/constants';
import { identifyUser } from 'lib/hotjar';
import { setSelectedPaymentCard } from 'pages/newOrder/newOrder.slice';
import { confirmApplePayMethod } from 'pages/newOrder/newOrder.thunks';
import type { RootState } from 'redux-stores/reducers/rootReducer';
import { checkApplyPayCompatibility } from 'wrappers/apple_pay';
import { setUserProfile } from 'wrappers/reporting';

export const login = createAsyncThunk(
  'auth/login',
  async ({
    phone,
    code,
    email,
    thirdPartyId,
    authTrackId,
    customOriginApp,
    language,
  }: {
    code: string;
    phone: string;
    email: string;
    thirdPartyId?: string;
    authTrackId?: string;
    customOriginApp?: string;
    language?: LanguageCodes;
  }) => {
    const loginAPIA = await loginAPI(code, phone, email, thirdPartyId, authTrackId, customOriginApp, language);
    return loginAPIA;
  }
);

export const logout = createAsyncThunk('auth/logout', async (_, ThunkAPI) => {
  const store = ThunkAPI.getState() as RootState;
  const { authToken, refreshToken } = store.auth;
  if (!authToken || !refreshToken) {
    ThunkAPI.rejectWithValue('failed to logout');
  } else {
    return logoutAPI(authToken, refreshToken);
  }

  return null;
});

export const refreshTokenAction = createAsyncThunk('user/refreshToken', async (_, ThunkApi) => {
  try {
    return await refreshTokenAPI();
  } catch (e) {
    await ThunkApi.dispatch(logout());
    return ThunkApi.rejectWithValue('invalid session');
  }
});

export const registerUser = createAsyncThunk<Customer.LoginRootResponse, Auth.RegisterUserInputs, { rejectValue: string | undefined }>(
  'user/register',
  async (data: Auth.RegisterUserInputs, ThunkApi) => {
    try {
      return await registerUserAPI(data);
    } catch (e) {
      if (isAxiosError(e)) {
        return ThunkApi.rejectWithValue(e.response?.data?.infoCode?.message || e.response?.data?.message);
      }
      return ThunkApi.rejectWithValue('server error' as string);
    }
  }
);

export const verifyToken = createAsyncThunk('client/verify', async ({ token }: { token: string }) => verifyAuthTokenAPI(token));

interface State {
  customer?: Customer.Customer;
  refreshToken?: string;
  authToken?: string;
  partnerId: INTEGRATION_PARTNERS | null;
}

/**
 * @description fetches initial state from local storage if found
 */
const getInitialState: () => State = () => {
  const user = localStorage.getItem('user') as string | null;
  const refreshToken = localStorage.getItem('refreshToken') || '';
  const token = localStorage.getItem('token') || '';
  const partnerId = (sessionStorage.getItem('partner_id') as INTEGRATION_PARTNERS) || null;

  if (token) {
    WASHMEN_CUSTOMER_API.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  if (user) {
    const customer: Customer.Customer = JSON.parse(user);
    // Error Reporting

    const userData = {
      id: customer.id,
      email: customer.email,
      name: `${customer.firstName} ${customer.lastName}`,
    };

    setUserProfile(userData);
    identifyUser(userData);
  }

  return {
    customer: user ? JSON.parse(user) : undefined,
    refreshToken,
    authToken: token,
    ...(partnerId && { partnerId }),
  };
};

const initialState: State = getInitialState();

// store user session to local storage
// in case of partner login as well
// to prevent data loss upon redirection
const storeUserSessionToLocalStorage = (authToken: string, refreshToken: string, user: Customer.Customer) => {
  localStorage.setItem('token', authToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('user', JSON.stringify(user));
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPartnerId: (state, action: PayloadAction<INTEGRATION_PARTNERS>) => {
      state.partnerId = action.payload;
    },
    setAuth: (state, action: PayloadAction<Customer.LoginRootResponse>) => {
      const { authToken, refreshToken, user } = action.payload;
      state.authToken = authToken;
      state.refreshToken = refreshToken;
      state.customer = user;
      storeUserSessionToLocalStorage(authToken, refreshToken, user);
    },
    setPromoCode: (state, action: PayloadAction<string>) => {
      if (state.customer) {
        if (!state.customer.appliedPromocodes) {
          state.customer.appliedPromocodes = [];
        }
        state.customer.appliedPromocodes.push(action.payload);
      }
    },
    setUserDetails: (state, action: PayloadAction<Customer.Customer>) => {
      state.customer = action.payload;
    },
    setActiveCampaigns(state, action: PayloadAction<Customer.ActivePromoCampaigns[]>) {
      if (state.customer) {
        state.customer.activeCampaigns = action.payload;
      }
    },
    setUpdateIntercomIdentified: (state) => {
      if (state.customer) {
        state.customer.isIntercomIdentified = true;
      }
    },
    setActiveOrderCount: (state, action: PayloadAction<number>) => {
      if (state.customer) {
        state.customer.activeOrdersCount = action.payload;
      }
    },
    setCompletedOrdersCount: (state, action: PayloadAction<number>) => {
      if (state.customer) {
        state.customer.completedOrdersCount = action.payload;
      }
    },
    setOrderToRate: (state, action: PayloadAction<string | undefined>) => {
      if (state.customer) {
        state.customer.orderToRate = action.payload;
      }
    },
    setActiveOrderIds: (state, action: PayloadAction<string[]>) => {
      if (state.customer) {
        state.customer.activeOrderIds = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    function sharedAuthUserReducer(state: State, action: PayloadAction<Customer.LoginRootResponse>) {
      const { authToken, refreshToken, user } = action.payload;
      state.authToken = authToken;
      state.refreshToken = refreshToken;
      state.customer = user;
      storeUserSessionToLocalStorage(authToken, refreshToken, user);
    }

    function sharedClearUserReducer(state: State) {
      state.authToken = '';
      state.refreshToken = '';
      state.customer = undefined;
    }

    builder
      .addCase(login.fulfilled, sharedAuthUserReducer)
      .addCase(registerUser.fulfilled, sharedAuthUserReducer)
      .addCase(refreshTokenAction.fulfilled, sharedAuthUserReducer)
      .addCase(verifyToken.fulfilled, sharedAuthUserReducer)
      .addCase(logout.fulfilled, sharedClearUserReducer)
      .addCase(refreshTokenAction.rejected, sharedClearUserReducer)
      .addCase(confirmApplePayMethod, (state) => {
        if (state.customer) {
          state.customer.lastSelectedPaymentMethod = 'APPLE_PAY';
        }
      })
      .addCase(setSelectedPaymentCard, (state, action) => {
        if (state.customer) {
          state.customer.lastSelectedPaymentMethod = 'CARD';
          state.customer.lastSelectedCardId = action.payload;
        }
      });
  },
});

export const {
  setPartnerId,
  setAuth,
  setPromoCode,
  setOrderToRate,
  setActiveOrderCount,
  setCompletedOrdersCount,
  setUserDetails,
  setUpdateIntercomIdentified,
  setActiveCampaigns,
  setActiveOrderIds,
} = slice.actions;

export const selectCustomer = (state: RootState) => state.auth.customer;

export const selectAuthToken = (state: RootState) => state.auth.authToken;

export const selectLoginState = (state: RootState) => Boolean(state.auth.customer);

export const selectActiveOrdersCount = createSelector(selectCustomer, (state) => {
  if (!state) return 0;
  return state.activeOrdersCount;
});

export const selectCustomerHasActiveOrders = createSelector(selectCustomer, (state) => {
  if (!state) return false;
  return Boolean(state.activeOrderIds?.length > 0);
});

export const selectCompletedOrdersCount = createSelector(selectCustomer, (state) => {
  if (!state) return 0;
  return state.completedOrdersCount;
});

export const selectTotalOrdersCount = createSelector(selectCustomer, (state) => {
  if (!state) return 0;
  return state.completedOrdersCount + state.activeOrdersCount;
});

export const selectOrderToRate = createSelector(selectCustomer, (state) => {
  if (!state || !state.orderToRate) return '';
  return state.orderToRate;
});

export const selectHasPendingOrders = createSelector(selectCustomer, (state) => {
  if (!state || !state.pendingOrders) return false;
  return state.pendingOrders.length > 0;
});

export const hasApplePayPayment = createSelector(selectCustomer, (customer) => {
  if (!customer) return false;
  if (customer.lastSelectedPaymentMethod === 'APPLE_PAY' && checkApplyPayCompatibility()) {
    return true;
  }
  return false;
});

export const selectIsFirstTimeCustomer = createSelector(selectCompletedOrdersCount, (state) => state === 0);

const CHECK_DEV = false;

export const selectPartner = (state: RootState): INTEGRATION_PARTNERS => {
  // TODO: once we start deloying we should remove this logic

  if (process.env.NODE_ENV === 'development' && CHECK_DEV) {
    return INTEGRATION_PARTNERS.APP;
  }

  return state.auth.partnerId as INTEGRATION_PARTNERS;
};

export default slice.reducer;
