import Avo from 'analytics/Avo';
import type { Order } from 'api/types/order.types';
import cx from 'classnames';
import RoundedButton from 'components/molecules/RoundedButton';
import Page from 'layout/page';
import { usePastActiveOrders } from 'pages/queries/order.queries';
import React, { type ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { selectCustomer } from 'redux-stores/slices/authSlice';

import Orders from './components/orders';
import styles from './styles.module.scss';

type OrderListT = 'active' | 'past';

interface NavLinkProps {
  type: OrderListT;
  text: string;
}

function NavLink({ type, text }: NavLinkProps): JSX.Element | null {
  const navigate = useNavigate();
  const location = useLocation();
  const match = matchPath('/orders/:orderType', location.pathname);

  const handleOrdersListSelected = () => {
    Avo.visitOrders({ screen: 'Home Page' });
    navigate(`/orders/${type}`, {
      state: {
        disableAnimation: true,
      },
    });
  };

  if (!match || !text) {
    return null;
  }

  return (
    <RoundedButton onClick={handleOrdersListSelected} className={cx(styles.navTab)} selected={match?.params.orderType === type}>
      {text}
    </RoundedButton>
  );
}

const ActivePastOrders: React.FC = () => {
  const navigate = useNavigate();
  const customer = useSelector(selectCustomer);
  const { t } = useTranslation();

  const { data, isLoading } = usePastActiveOrders();

  const activeOrders = useMemo(() => data?.activeOrders ?? [], [data?.activeOrders]);

  const pastOrders = useMemo(() => data?.pastOrders ?? [], [data?.pastOrders]);

  const handleOrderSelection = useCallback(
    (order: Order.Order) => {
      navigate(`/order/details/${order.id}`);
    },
    [navigate]
  );

  // Make sure user is LoggedIn
  useEffect(() => {
    if (!customer) navigate('/profile', { replace: true });
  });

  const Nav = useMemo(
    () => (
      <Row className={cx(styles.nav)}>
        <Col>
          <NavLink type="active" text={`${t('ordersPage.active')} (${customer?.activeOrdersCount})`} />
          <NavLink type="past" text={t('ordersPage.past')} />
        </Col>
      </Row>
    ),
    [isLoading]
  );

  const wrapPage = useCallback(
    (children?: ReactNode) => (
      <Page
        headerProps={{
          isShowPricingOption: false,
          isShowSupportOption: true,
        }}
        showBackToHome
        className="px-16 flex"
        title={t('ordersPage.orders')}
        route="/home"
        hasBack
      >
        <div className="mb-5">{Nav}</div>
        {children}
      </Page>
    ),
    [isLoading]
  );

  return (
    <Routes>
      <Route path="active" element={wrapPage(<Orders orders={activeOrders} isLoading={isLoading} onOrderSelected={handleOrderSelection} />)} />
      <Route path="past" element={wrapPage(<Orders orders={pastOrders} isLoading={isLoading} onOrderSelected={handleOrderSelection} />)} />
      <Route index element={<Navigate to="active" />} />
    </Routes>
  );
};

export default ActivePastOrders;
