import { EventSerializer, LndrTapButtonEventBuilder } from '@careem-public/events-tenants_laundry_by_washmen';

import { trackCareemEvent } from './trackCareemEvent';

export const careemButtonEvent = (buttonName: string, buttonSize: string, buttonType: string) => {
  const tapButtonEventBuilder = new LndrTapButtonEventBuilder().buttonName(buttonName).buttonSize(buttonSize).buttonType(buttonType);

  const event = tapButtonEventBuilder.build();
  const eventSerializer = new EventSerializer();
  const serializedEvent = eventSerializer.serialize(event);
  trackCareemEvent(serializedEvent, 'button event');
};
