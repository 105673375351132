import Avo from 'analytics/Avo';
import type { PricingList } from 'api/types/pricing.types';
import { CPBag, FineryLogo, HCBag, PBag, RightArrowIcon, SCBag, WFBag } from 'assets/icons/';
import cx from 'classnames';
import i18n from 'i18n/config';
import { usePricing } from 'pages/queries/pricing.queries';
import React, { memo, useCallback, useMemo } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import styles from './styles.module.scss';

interface Props {
  type: PricingList.ServiceTypes;
  showArrow?: boolean;
  isActive?: boolean;
  onClose?: () => any;
  serviceDetails: PricingList.CombinedService;
  onSelectService?: (service: PricingList.ServiceTypes) => any;
}

const StyledH4 = styled.h4<{ fontColor?: string; font?: string }>`
  color: ${({ theme, fontColor }) => fontColor || theme.text.color};
  font-family: ${({ font }) => font || 'inherit'};
`;

const StyledBadge = styled.div`
  display: flex;
  color: ${({ theme }) => theme.components.pricingSheet.serviceBox.badge.textColor};
  background: ${({ theme }) => theme.components.pricingSheet.serviceBox.badge.backgroundColor};
  padding-left: 8.78px;
  padding-right: 8.78px;
  align-items: center;
  height: 20px;
  border-radius: 100px;
`;

const StyledBadgeBoldText = styled.h5<{ hasDiscountedPrice?: boolean }>`
  color: ${({ theme }) => theme.components.pricingSheet.serviceBox.badge.textColor};
  font-weight: ${({ hasDiscountedPrice = false }) => (hasDiscountedPrice ? 400 : 600)};
  margin-bottom: 0px;
  text-decoration: ${({ hasDiscountedPrice = false }) => (hasDiscountedPrice ? 'line-through' : undefined)};
`;

const StyledBadgeText = styled.h5<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 200;
  margin-bottom: 0px;
  padding-right: 4px;
`;

const StyledCard = styled(Card)<{ isActive?: boolean }>`
  border-width: ${({ isActive = false }) => (isActive ? '1px' : '0px')};
  border-color: ${({ isActive = false, theme }) => (isActive ? theme.colors.primary : undefined)};
  background-color: ${({ isActive = false, theme }) => (isActive ? theme.colors.paleSpringGreen : undefined)};

  ${({ theme }) =>
    theme.components.pricingSheet.serviceBox.card &&
    `
    box-shadow: ${theme.components.pricingSheet.serviceBox.card?.shadow};
    border-bottom: ${theme.components.pricingSheet.serviceBox.card?.borderBottom};
  `}
`;

const ServiceBox: React.FC<Props> = ({ type, showArrow = false, isActive = false, onClose, serviceDetails, onSelectService }) => {
  const navigate = useNavigate();
  const isRTL = i18n.dir() === 'rtl';
  const { data: pricing } = usePricing();
  const { t } = useTranslation();
  const theme = useTheme();

  const discountedPercentage = useMemo(
    () => (serviceDetails.discountPercentage ? `${serviceDetails.discountPercentage}% off` : undefined),
    [serviceDetails]
  );

  const BagServiceMapper = new Map<
    PricingList.ServiceTypes,
    {
      icon: React.ReactElement;
      variant: 'primary' | 'pink' | 'green' | 'purple';
      price?: number;
      discountedPrice?: number;
      text?: string;
      boldText?: string;
      font?: string;
      fontColor?: string;
    }
  >([
    [
      'SHOE_CLEANING',
      {
        icon: <SCBag />,
        variant: 'green',
      },
    ],
    [
      'CLEAN_PRESS',
      {
        icon: <CPBag />,
        variant: 'green',
        text: t('common.upTo', 'Up to'),
        boldText: discountedPercentage,
      },
    ],
    [
      'PRESS',
      {
        icon: <PBag />,
        variant: 'primary',
        text: t('common.upTo', 'Up to'),
        boldText: discountedPercentage,
      },
    ],
    [
      'HOME_CARE',
      {
        icon: <HCBag direction="ltr" />,
        variant: 'pink',
        price: pricing?.services.HOME_CARE.price,
        discountedPrice: pricing?.services.HOME_CARE.discountedPrice,
      },
    ],
    [
      'WASH_FOLD',
      {
        icon: <WFBag direction="ltr" />,
        variant: 'primary',
        price: pricing?.services.WASH_FOLD.price,
        discountedPrice: pricing?.services.WASH_FOLD.discountedPrice,
      },
    ],
    [
      'THE_FINERY',
      {
        icon: <FineryLogo direction="ltr" />,
        variant: 'purple',
        fontColor: '#4B1574',
        font: 'Inria Serif',
      },
    ],
  ]);

  const service = BagServiceMapper.get(type);

  const handleClick = useCallback(() => {
    onClose?.();
    Avo.viewProduct({ screen: 'Home page Footer price list', productName: type });
    if (onSelectService) {
      onSelectService(type);
    } else {
      navigate(`/pricing/${type}`);
    }
  }, [onClose, onSelectService, type, navigate]);

  // TODO: return placeholder loader
  if (!serviceDetails) return null;

  return (
    <StyledCard isActive={isActive} className={cx(styles.card)} onClick={handleClick}>
      <Row noGutters className="justify-content-between align-items-center mx-2">
        <Row noGutters className="justify-content-center align-items-center">
          {service?.icon}
          <StyledH4 font={service?.font} fontColor={service?.fontColor} className="align-self-center text-center mx-3 my-0">
            {serviceDetails.name}
          </StyledH4>

          <Row noGutters>
            {service?.text && service?.boldText ? (
              <StyledBadge>
                {service?.text && (
                  <StyledBadgeText color={theme.components.pricingSheet.serviceBox.badge.upToTextColor} className="ml-1">
                    {service.text}
                  </StyledBadgeText>
                )}
                {service?.boldText && <StyledBadgeBoldText className="mr-1">{service.boldText}</StyledBadgeBoldText>}
              </StyledBadge>
            ) : (
              service?.price && (
                <StyledBadge>
                  {service?.price && (
                    <>
                      <StyledBadgeBoldText className="mr-1" hasDiscountedPrice={service.discountedPrice !== undefined}>
                        {'AED '}
                        {service.price}
                      </StyledBadgeBoldText>
                      {!service.discountedPrice && (
                        <StyledBadgeText color={theme.components.pricingSheet.serviceBox.badge.perItemTextColor} className="ml-1">
                          {t('common.perBag').toLowerCase()}
                        </StyledBadgeText>
                      )}
                    </>
                  )}

                  {service?.discountedPrice && (
                    <>
                      <StyledBadgeBoldText>
                        {'AED '}
                        {service.discountedPrice}
                      </StyledBadgeBoldText>
                      <StyledBadgeText color={theme.components.pricingSheet.serviceBox.badge.perItemTextColor} className="ml-1">
                        {t('common.perBag').toLowerCase()}
                      </StyledBadgeText>
                    </>
                  )}
                </StyledBadge>
              )
            )}
          </Row>
        </Row>
        {showArrow && <RightArrowIcon className={cx(isRTL ? 'transform-180' : '')} />}
      </Row>
    </StyledCard>
  );
};

export default memo(ServiceBox);
