import { useConfig } from 'context/app-config';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectAuthToken, selectCustomer, selectPartner } from 'redux-stores/slices/authSlice';
import { notifyErrorObject } from 'wrappers/reporting';

const ProtectedRoutes = () => {
  const customer = useSelector(selectCustomer);
  const partnerId = useSelector(selectPartner);
  const config = useConfig();
  const { pathname } = useLocation();
  const auth = useSelector(selectAuthToken);
  const token = localStorage.getItem('token') ?? null;

  if (!customer && config?.isAuthDisabled) {
    notifyErrorObject({
      name: `customer is not present in ${partnerId}: was navigating to ${pathname} - fallback to /login`,
      message: `customer is not present on newOrder authTokenAvailable:${Boolean(auth)} : authTokenInLocalStorage:${Boolean(token)}`,
    });
  }

  return customer ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoutes;
