import { WASHMEN_CUSTOMER_API } from 'api/config';
import type { Cards } from 'api/types/cards.types';
import type { Order } from 'api/types/order.types';
import type { Customer } from 'api/types/user.types';

export async function fetchCustomerPaymentCardsAPI() {
  return (await WASHMEN_CUSTOMER_API.get<Cards.RootObject>('/cards')).data;
}

export async function addPaymentCardAPI(card: Cards.CardForm) {
  return (await WASHMEN_CUSTOMER_API.post<Cards.PostAPIResponse>('/cards', card)).data;
}

export async function updateDefaultPaymentAPI(paymentMethod: Cards.PaymentMethods, cardId?: string) {
  return WASHMEN_CUSTOMER_API.put('/customers/payment', { paymentMethod, cardId });
}

export async function deletePaymentCardAPI(cardId: string) {
  return WASHMEN_CUSTOMER_API.delete(`/cards/${cardId}`);
}

export async function validateApplePaySessionAPI(url: string) {
  return (await WASHMEN_CUSTOMER_API.post('/apple-pay/validate-session', { url })).data.applePaySessionObject;
}

export async function addFailedOrderPaymentCardAPI(orderId: string, card: Cards.CardForm) {
  return (await WASHMEN_CUSTOMER_API.put<{ order: Order.Order; user: Customer.Customer }>(`/orders/${orderId}/card`, card)).data;
}
