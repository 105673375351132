import { WASHMEN_CUSTOMER_API } from 'api/config';
import type { Order } from 'api/types/order.types';
import type { Preferences } from 'api/types/preferences.types';
import type { Customer } from 'api/types/user.types';

export async function addPromoCodeAPI(promocode: string) {
  return WASHMEN_CUSTOMER_API.post<{
    messageNotification: string;
    updatedUser: Customer.Customer;
    promocodeAmount: number;
  }>(`/customers/promotions/apply`, {
    promocode,
  });
}

export async function createNewOrderAPI(newOrder: Order.NewOrder) {
  const { preferences } = newOrder;
  if (preferences && preferences.folding === '') {
    preferences.folding = undefined;
  }
  if (preferences && preferences.creases === '') {
    preferences.creases = undefined;
  }
  return (
    await WASHMEN_CUSTOMER_API.post<{
      customerPreferences: Preferences.CustomerPreferences;
      newOrder: Order.Order;
      updatedUser: Customer.Customer;
    }>('/v2/orders', newOrder)
  ).data;
}

export async function fetchOrderDetailsAPI(orderId: string) {
  return (await WASHMEN_CUSTOMER_API.get<{ order: Order.Order }>(`/orders/${orderId}`)).data.order;
}

export async function updateActiveOrderAPI(orderId: string, data: Order.UpdateOrder) {
  return (
    await WASHMEN_CUSTOMER_API.put<{ order: Order.Order }>(`/orders/${orderId}`, data, {
      params: { isAutoSelectNextAvailablePickupSlot: true, orderTaskId: data?.orderTaskId },
    })
  ).data;
}

export async function updateActiveOrderPickupAPI(orderId: string, data: Order.UpdatePickup) {
  return (await WASHMEN_CUSTOMER_API.put<{ order: Order.Order }>(`/orders/${orderId}/pickup`, data)).data;
}

export async function updateActiveOrderDropoffAPI(orderId: string, data: Order.UpdateDropoff) {
  return (await WASHMEN_CUSTOMER_API.put<{ order: Order.Order }>(`/orders/${orderId}/dropoff`, data)).data;
}

export async function cancelActiveOrderAPI(orderId: string, reason = 'No reason') {
  return (
    await WASHMEN_CUSTOMER_API.delete<{ updatedUser: Customer.Customer }>(`/orders/${orderId}`, {
      data: {
        reason,
      },
    })
  ).data;
}

export async function rateOrderAPI(orderId: string, data = {}) {
  return WASHMEN_CUSTOMER_API.put(`/orders/${orderId}/rate`, data);
}
