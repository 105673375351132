import colors from '../colors';
import type { AppTheme } from '../types';

export const appColors: AppTheme.Colors & AppTheme.ThemeColors = {
  ...colors,
  primary: colors.royalBlue,
  lightPrimary: colors.periwinkle,
  secondary: colors.turquoise,
  secondaryDark: colors.turquoise,
  success: colors.turquoise,
  danger: colors.crimson,
  warning: colors.goldenRod,
  light: colors.whiteSmoke,
  dark: colors.darkMidnightBlue,
  subtitleDark: colors.darkStaleGray,
  separatorGray: colors.ghostWhite,
  darkMidnightBlue: colors.darkMidnightBlue,
  tertiary: colors.paleLightGray,
  info: colors.paleJade,
  textSuccess: colors.deepPurple,
  textTertiary: colors.paleLightGray,
  textDanger: colors.crimson,
  textWarning: colors.vermilion,
  textColor: colors.royalBlue,
  slateGray: colors.slateGray,
  slateGray500: colors.slateGray500,
  extraLightShadeGray: colors.extraLightShadeGray,
};
