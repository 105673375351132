import Title from 'components/atoms/Title';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const StyledH4 = styled.h4`
  color: ${({ theme }) => theme.components?.pageHeading?.subHeading?.color};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: ${({ theme }) => theme.components.pageHeading?.subHeading?.lineHeight ?? '20'}px;
`;

const StyledRow = styled(Row)<PageHeadingProps>`
  margin-top: ${({ theme, removeDefaultMarginTop }) => (removeDefaultMarginTop ? '0px' : `${theme.components?.pageHeading?.marginTop}px` ?? '16px')};
`;

interface PageHeadingProps {
  title?: string;
  description?: string;
  removeDefaultMarginTop?: boolean;
}

//  className="mt-4"
const PageHeading = ({ title, description, removeDefaultMarginTop }: PageHeadingProps) => (
  <StyledRow removeDefaultMarginTop={removeDefaultMarginTop}>
    <Col>
      {title && <Title>{title}</Title>}
      {description && <StyledH4 className="font-weight-light mb-6">{description}</StyledH4>}
    </Col>
  </StyledRow>
);

export default PageHeading;
